import { useBlastPointsStatus } from "@/features/earn/hooks/useBlastPointsStatus.ts";
import { useUserProtocolMarkets } from "@/hooks/useUserProtocolMarkets.ts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BigNumber from "bignumber.js";
import { find, omit } from "lodash-es";
import { Address } from "viem";
import { useAccount } from "wagmi";

type UserBlastPointsStatusData = Record<
  Address,
  Record<
    string,
    {
      total: string;
      distributed: string;
    }
  >
>;

type UseUserBlastPointsStatusResult = {
  data:
    | {
        pending: number;
        distributed: number;
        earningRate: number;
      }
    | undefined;
  isSuccess: boolean;
};

export const useUserBlastPointsStatus = (): UseUserBlastPointsStatusResult => {
  const { address } = useAccount();

  const { data: userProtocolMarkets, isSuccess: isUserProtocolMarketsSuccess } =
    useUserProtocolMarkets();

  const { data: blastPointsStatus, isSuccess: isBlastPointsStatusSuccess } =
    useBlastPointsStatus();

  const {
    data: blastPointsUserStatus,
    isSuccess: isBlastPointsUserStatusSuccess,
  } = useQuery({
    queryKey: ["blast-points-user-status", address],
    queryFn: async () => {
      const { data } = await axios.get<UserBlastPointsStatusData>(
        "https://api.alien.finance/api/v1/blast-point/balances",
        {
          params: {
            account: address,
          },
        },
      );
      return omit(data, ["WETH", "USDB", "BLAST"]);
    },
    enabled: !!address && isBlastPointsStatusSuccess,
    select: (data) => {
      return omit(data, ["GOLD"]);
    },
  });

  if (
    !isBlastPointsUserStatusSuccess ||
    !isBlastPointsStatusSuccess ||
    !isUserProtocolMarketsSuccess
  ) {
    return {
      data: undefined,
      isSuccess: false,
    };
  }

  let total = BigNumber(0);
  let distributed = BigNumber(0);
  let rateSum = BigNumber(0);

  for (const poolAddress in blastPointsUserStatus) {
    const poolStatus = blastPointsUserStatus[poolAddress as Address];
    const userMarkets = userProtocolMarkets[poolAddress as Address];

    for (const symbol in poolStatus) {
      const tokenStatus = poolStatus[symbol];
      total = total.plus(tokenStatus.total);
      distributed = distributed.plus(tokenStatus.distributed);
    }

    const marketAddress: Record<string, Address> = {
      WETH: "0x4300000000000000000000000000000000000004",
      USDB: "0x4300000000000000000000000000000000000003",
      BLAST: "0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad",
    };

    for (const symbol in poolStatus) {
      if (!(symbol in marketAddress) || !userMarkets) {
        continue;
      }
      const address = marketAddress[symbol];

      const userMarket = find(userMarkets, (m) => m.market === address);
      if (!userMarket) {
        continue;
      }

      const { aTokenBalance } = userMarket;
      const marketRate =
        blastPointsStatus[poolAddress as Address]?.[symbol].rates;
      if (!marketRate) {
        continue;
      }

      const r = BigNumber(aTokenBalance.toString())
        .multipliedBy(marketRate)
        .multipliedBy(1800) // 1800 blocks per hour
        .dividedBy(1e18);
      rateSum = rateSum.plus(r);
    }
  }

  return {
    data: {
      earningRate: rateSum.dividedBy(1e12).toNumber(),
      distributed: distributed.dividedBy(1e12).toNumber(),
      pending: total.minus(distributed).dividedBy(1e12).toNumber(),
    },
    isSuccess: true,
  };
};
