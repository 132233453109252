import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { toDigits } from "@/utils/format.ts";
import {
  getMarketBorrowUsdValue,
  getMarketReserveUsdValue,
  getMarketSupplyUsdValue,
} from "@/utils/market.ts";
import {
  Box,
  HStack,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { filter, reduce } from "lodash-es";
import { RiQuestionLine } from "react-icons/ri";

const StatsBanner = () => {
  const { pool } = usePool();
  const { markets } = usePoolMarkets(pool);

  const totalSupply = reduce(
    markets,
    (result, market) => result + getMarketSupplyUsdValue(market),
    0,
  );

  const totalBorrow = reduce(
    markets,
    (result, market) => result + getMarketBorrowUsdValue(market),
    0,
  );

  const totalReserve = reduce(
    markets,
    (result, market) => result + getMarketReserveUsdValue(market),
    0,
  );

  // calculate total supply that has collateral potential
  const totalCollateralSupply = reduce(
    filter(markets, (m) => m.collateralFactor !== 0),
    (result, market) => result + getMarketSupplyUsdValue(market),
    0,
  );

  return (
    <SimpleGrid columns={{ base: 2, lg: 5 }} rowGap={6}>
      <Stat>
        <StatLabel>TVL</StatLabel>
        <StatNumber>
          <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
            $
          </Text>
          {toDigits(totalSupply - totalBorrow + totalReserve, 2)}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Protocol Health Factor</StatLabel>
        <StatNumber>
          <HStack alignItems="center">
            <Text>{toDigits(totalCollateralSupply / totalBorrow, 2)}</Text>
            <Tooltip
              placement="top"
              hasArrow={true}
              label={
                <Box p={2}>
                  <Text variant="body3" lineHeight="1rem">
                    Protocol Collateral / Protocol Borrow
                  </Text>
                </Box>
              }
            >
              <Box as="span">
                <Icon color="text.secondary" as={RiQuestionLine} />
              </Box>
            </Tooltip>
          </HStack>
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Total Supply</StatLabel>
        <StatNumber>
          <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
            $
          </Text>
          {toDigits(totalSupply, 2)}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Total Borrow</StatLabel>
        <StatNumber>
          <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
            $
          </Text>
          {toDigits(totalBorrow, 2)}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Reserve</StatLabel>
        <StatNumber>
          <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
            $
          </Text>
          {toDigits(totalReserve, 2)}
        </StatNumber>
      </Stat>
    </SimpleGrid>
  );
};

export default StatsBanner;
