import { Pool } from "@/configs/chains.ts";
import useProtocol from "@/hooks/useProtocol.ts";
import React, { ReactNode, useState } from "react";

type Context = {
  pool?: Pool;
  pools: Pool[];
  setPool: (newPool: Pool) => void;
};

const KEY_LAST_USED_POOL = "lastUsedPool";

export const PoolContext = React.createContext<Context>({
  pools: [],
  setPool: (): void => {
    throw new Error("Function not implemented.");
  },
});

const supportsLocalStorage =
  "localStorage" in window && window["localStorage"] !== null;

const getLastUsedPool = (pools: Pool[]) => {
  if (!supportsLocalStorage) {
    return pools[0];
  }
  const lastUsedPoolName = localStorage.getItem(KEY_LAST_USED_POOL);
  return pools.find((pool) => pool.name === lastUsedPoolName) || pools[0];
};

const storeLastUsedPool = (pool: Pool) => {
  if (!supportsLocalStorage) {
    return;
  }
  localStorage.setItem(KEY_LAST_USED_POOL, pool.name);
};

export const PoolProvider = ({ children }: { children: ReactNode }) => {
  const { protocol } = useProtocol();

  if (protocol.pools.length === 0) {
    throw new Error("No pools available in the protocol");
  }

  const [pool, setPoolState] = useState<Pool>(() =>
    getLastUsedPool(protocol.pools),
  );

  const setPool = (pool: Pool) => {
    setPoolState(pool);
    storeLastUsedPool(pool);
  };

  const poolValue: Context = {
    pool: pool,
    pools: protocol.pools,
    setPool: setPool,
  };

  return (
    <PoolContext.Provider value={poolValue}>{children}</PoolContext.Provider>
  );
};
