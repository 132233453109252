import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  variants: {
    subtitle1: {
      fontWeight: 600,
      fontSize: "xl",
      lineHeight: "1.625rem",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "lg",
      lineHeight: "1.5rem",
    },
    subtitle3: {
      fontWeight: 600,
      fontSize: "md",
      lineHeight: "1.5rem",
    },
    subtitle4: {
      fontWeight: 600,
      fontSize: "sm",
      lineHeight: "1.375rem",
    },
    subtitle5: {
      fontWeight: 600,
      fontSize: "xs",
      lineHeight: "1.125rem",
    },
    body1: {
      fontWeight: 500,
      fontSize: "md",
      lineHeight: "1.5rem",
    },
    body2: {
      fontWeight: 400,
      fontsize: "sm",
      lineHeight: "1.375rem",
    },
    body3: {
      fontWeight: 400,
      fontSize: "xs",
      lineHeight: "1.125rem",
    },
    body4: {
      fontWeight: 400,
      fontSize: "2xs",
      lineHeight: "1rem",
    },
    caption1: {
      fontWeight: 600,
      fontSize: "xs",
      lineHeight: "1.125rem",
    },
  },
});
