import { Text, TextProps } from "@chakra-ui/react";

export const EmptyTableText = ({ children, ...restProps }: TextProps) => {
  return (
    <Text
      textColor="#979797"
      textAlign="center"
      fontWeight={400}
      fontSize={12}
      lineHeight={18 / 12}
      {...restProps}
    >
      {children}
    </Text>
  );
};
