import useMediaQuery from "@/hooks/useMediaQuery";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { filter, findLast, map } from "lodash-es";

type ProgressReference = {
  label: string;
  value: number;
  color: string;
  bgColor: string;
};

interface ProgressBarProps {
  value: number;
  color?: string;
  references?: ProgressReference[];
}

const MaxValue = 100;

export const ProgressBar = ({
  value,
  color = "green.500",
  references,
}: ProgressBarProps) => {
  const { isDesktop } = useMediaQuery();
  const current = findLast(
    references,
    (references) => value > references.value,
  );

  return (
    <Box w="full" h="10px">
      <Box pos="relative" h="full" bgColor="#D8DCE1" borderRadius="2px">
        <Box
          w={`${value >= MaxValue ? MaxValue : value}%`}
          h="full"
          bgColor={current?.bgColor || color}
        />
        {map(
          filter(references, ({ value }) => value <= MaxValue),
          ({ label, value, color }) =>
            isDesktop ? (
              <Tooltip
                key={label}
                placement="top"
                label={
                  <Box p={2}>
                    <Text variant="body3" lineHeight="1rem">
                      {label}
                    </Text>
                  </Box>
                }
                hasArrow
              >
                <Box
                  key={label}
                  pos="absolute"
                  w="2px"
                  h="full"
                  top="0"
                  left={`${value}%`}
                  bgColor={color}
                />
              </Tooltip>
            ) : (
              <Box
                key={label}
                pos="absolute"
                w="2px"
                h="full"
                top="0"
                left={`${value}%`}
                bgColor={color}
              />
            ),
        )}
      </Box>
    </Box>
  );
};

export default ProgressBar;
