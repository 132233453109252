import Table from "@/components/Table";
import { formatUSDValue } from "@/features/leverage/utils/formatUSDValue";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePool } from "@/hooks/usePool";
import { usePoolMarkets } from "@/hooks/usePoolMarkets";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets";
import { UserMarket } from "@/types";
import { formatBigInt } from "@/utils/format";
import { getBalanceValue } from "@/utils/market";
import { Box, Flex, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { parseUnits } from "viem";
import { EmptyTableText } from "./EmptyTableText";
import { TokenView } from "./TokenView";

type RowData = {
  symbol: string;
  decimals: number;
  balance: bigint;
  balanceStr: string;
  value: bigint;
  valueStr: string;
};

const PositionTable = ({
  data,
  title,
  emptyText,
}: {
  title: string;
  emptyText: string;
  data: RowData[];
}) => {
  if (data.length === 0) {
    return (
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt={10}
      >
        <EmptyTableText>{emptyText}</EmptyTableText>
      </Flex>
    );
  }
  const columnHelper = createColumnHelper<RowData>();
  const columns = [
    columnHelper.accessor("symbol", {
      header: () => <Text>{title}</Text>,
      cell: ({ getValue }) => <TokenView symbol={getValue()} />,
    }),
    columnHelper.accessor("balanceStr", {
      header: () => <Text>Amount</Text>,
      cell: ({ getValue }) => (
        <Text fontWeight={600} fontSize={14}>
          {getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("valueStr", {
      header: () => <Text>Size</Text>,
      cell: ({ getValue }) => (
        <Text fontWeight={600} fontSize={14}>
          {getValue()}
        </Text>
      ),
    }),
  ];
  return (
    <Table
      flex={1}
      columns={columns}
      data={data}
      thProps={{
        textColor: "color.label",
        px: 2,
        py: 1,
      }}
      tdProps={{
        px: 2,
        py: 3,
      }}
    />
  );
};

const lowValueRowFilter = (row: RowData) => {
  return row.value >= parseUnits("0.005", 18);
};

export const PositionView = () => {
  const { isMobile } = useMediaQuery();
  const { pool } = usePool();
  const { marketsByAddress } = usePoolMarkets(pool);
  const { userSupplyMarkets, userBorrowMarkets } = useUserPoolMarkets(pool);

  const rowConverter =
    (isSupply: boolean) =>
    (userMarket: UserMarket): RowData => {
      const { supplyBalance, borrowBalance, market } = userMarket;
      const { marketSymbol, marketPrice, marketDecimals } =
        marketsByAddress[market];

      const balance = isSupply ? supplyBalance : borrowBalance;
      const value = getBalanceValue(balance, marketPrice);
      const balanceStr = formatBigInt(balance, marketDecimals, 2);
      const valueStr = formatUSDValue(value);

      return {
        symbol: marketSymbol,
        decimals: marketDecimals,
        balance,
        balanceStr,
        value,
        valueStr,
      };
    };

  const supplyRows = userSupplyMarkets
    .map(rowConverter(true))
    .filter(lowValueRowFilter);

  const borrowRows = userBorrowMarkets
    .map(rowConverter(false))
    .filter(lowValueRowFilter);

  if (supplyRows.length + borrowRows.length === 0) {
    return <EmptyTableText pt={4}>There are no positions</EmptyTableText>;
  }

  return (
    <Flex flexDirection={isMobile ? "column" : "row"} alignItems="stretch">
      <PositionTable title="Supply" emptyText="No Supplies" data={supplyRows} />
      {isMobile ? (
        <Box h={4} />
      ) : (
        <Box backgroundColor="#555555" w="1px" mt={6} mx={2} />
      )}
      <PositionTable title="Borrow" emptyText="No Borrows" data={borrowRows} />
    </Flex>
  );
};
