import { Pool } from "@/configs/chains.ts";
import {
  newBorrowAction,
  newBorrowNativeTokenAction,
  newDeferLiquidityCheckAction,
  newRepayAction,
  newRepayNativeTokenAction,
  newStEthAction,
  newSupplyAction,
  newSupplyNativeTokenAction,
  newWithdrawAction,
  newWithdrawNativeTokenAction,
  useSendExtensionActions,
} from "@/hooks/extension/useSendExtensionActions";
import { usePool } from "@/hooks/usePool.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Tx, TxBuilderContext } from "@/providers/TxBuilder";
import { ExtensionAction, UserMarket } from "@/types";
import { MaxUint256 } from "@/utils/constants";
import { findWrappedMarket } from "@/utils/wrappedMarket";
import { useContext } from "react";

export function useSendTxs() {
  const { txs } = useContext(TxBuilderContext);
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const actions: ExtensionAction[] = [];

  if (txs.length > 1) {
    actions.push(newDeferLiquidityCheckAction());
  }

  for (const tx of txs) {
    actions.push(
      txToExtensionAction(tx, userMarketsByAddress[tx.market.market], pool),
    );
  }

  return useSendExtensionActions(actions);
}

function txToExtensionAction(
  tx: Tx,
  userMarket: UserMarket,
  pool: Pool,
): ExtensionAction {
  if (tx.useUnwrappedMarket) {
    const wrappedMarket = findWrappedMarket(tx.market, pool);

    if (wrappedMarket.type === "wETH") {
      switch (tx.action) {
        case "supply":
          return newSupplyNativeTokenAction(tx.amount);
        case "borrow":
          return newBorrowNativeTokenAction(tx.amount);
        case "withdraw":
          if (tx.amount === userMarket.supplyBalance) {
            return newWithdrawNativeTokenAction(MaxUint256);
          }
          return newWithdrawNativeTokenAction(tx.amount);
        case "repay":
          if (tx.amount === userMarket.borrowBalance) {
            return newRepayNativeTokenAction(MaxUint256);
          }
          return newRepayNativeTokenAction(tx.amount);
        default:
          throw Error("invalid action");
      }
    } else if (wrappedMarket.type === "wstETH") {
      switch (tx.action) {
        case "supply":
          return newStEthAction(tx.amount, "SUPPLY");
        case "borrow":
          return newStEthAction(tx.amount, "BORROW");
        case "withdraw":
          if (tx.amount === userMarket.supplyBalance) {
            return newStEthAction(MaxUint256, "REDEEM");
          }
          return newStEthAction(tx.amount, "REDEEM");
        case "repay":
          if (tx.amount === userMarket.borrowBalance) {
            return newStEthAction(MaxUint256, "REPAY");
          }
          return newStEthAction(tx.amount, "REPAY");
        default:
          throw Error("invalid action");
      }
    } else {
      throw Error("invalid wrapped market type");
    }
  }

  switch (tx.action) {
    case "supply":
      return newSupplyAction(tx.market.market, tx.amount);
    case "borrow":
      return newBorrowAction(tx.market.market, tx.amount);
    case "withdraw":
      if (tx.amount === userMarket.supplyBalance) {
        return newWithdrawAction(tx.market.market, MaxUint256);
      }
      return newWithdrawAction(tx.market.market, tx.amount);
    case "repay":
      if (tx.amount === userMarket.borrowBalance) {
        return newRepayAction(tx.market.market, MaxUint256);
      }
      return newRepayAction(tx.market.market, tx.amount);
    default:
      throw Error("invalid action");
  }
}
