import logo from "@/assets/img/logo.svg";
import ConnectButton from "@/components/ConnectButton";
import { PoolSwitch } from "@/components/PoolSwitch.tsx";
import { navLinks } from "@/utils/constants";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";
import { map } from "lodash-es";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

export const NavigationBar = () => {
  return (
    <Box
      as="header"
      pos="sticky"
      w="full"
      top={0}
      bgColor="black"
      py={4}
      zIndex={10}
    >
      <Container maxW="container.xl">
        <Flex columnGap={10} alignItems="center" gridColumnGap={5}>
          <Image src={logo} />
          <HStack spacing={6}>
            {map(navLinks, ({ name, path, children, badge, isExternal }) =>
              children ? (
                <Menu key={name}>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        as={Button}
                        variant="nav"
                        key={name}
                        rightIcon={
                          isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />
                        }
                      >
                        {name}
                      </MenuButton>
                      <MenuList as={Stack}>
                        {map(children, (subLink) => (
                          <MenuItem
                            key={subLink.name}
                            _hover={{ bgColor: "unset" }}
                            p={0}
                          >
                            <Button
                              w="full"
                              as={Link}
                              variant="nav"
                              borderRadius={0}
                              _activeLink={{
                                bgColor: "secondary.100",
                                color: "text.primary",
                              }}
                              to={subLink.path}
                            >
                              {subLink.name}
                            </Button>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              ) : (
                <Button
                  as={Link}
                  variant="nav"
                  _activeLink={{
                    bgColor: "ui.tag",
                    color: "black",
                  }}
                  to={path}
                  key={name}
                  target={isExternal ? "_blank" : "_self"}
                >
                  {name}
                  {badge && (
                    <Tag ms={2} borderRadius="full" bgColor="primary.500">
                      <TagLabel>{badge}</TagLabel>
                    </Tag>
                  )}
                </Button>
              ),
            )}
          </HStack>
          <Spacer />
          <PoolSwitch />
          <ConnectButton />
        </Flex>
      </Container>
    </Box>
  );
};

export default NavigationBar;
