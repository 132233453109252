import AssetSelector, { WrappedOption } from "@/components/AssetSelector";
import { addingCollateralAtom } from "@/features/leverage/states/states";
import { formatUSDValue } from "@/features/leverage/utils/formatUSDValue";
import { tryParseUnits } from "@/features/leverage/utils/tryParseUnits";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePool } from "@/hooks/usePool";
import { usePoolMarkets } from "@/hooks/usePoolMarkets";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets";
import { useUserStats } from "@/hooks/useUserStats";
import { toDigits } from "@/utils/format";
import { getMarketBorrowLimit } from "@/utils/market";
import { getUserUnwrappedMarket, isWrappedMarket } from "@/utils/wrappedMarket";
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { formatUnits } from "viem";
import { canLeverage } from "../utils/canLeverage";
import { TxSummaryItem } from "./TxSummaryCard";

export const EditCollateralModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { isMobile } = useMediaQuery();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isMobile}>
      <ModalOverlay backgroundColor="background.modal" />
      <ModalContent pb={6} mx={6}>
        <ModalCloseButton top={6} right={6} h={6} />
        <EditCollateralModalContent onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};

const EditCollateralModalContent = ({ onClose }: { onClose: () => void }) => {
  const [addingCollateral, setAddingCollateral] = useAtom(addingCollateralAtom);
  const { borrowLimit: currentBorrowLimit } = useUserStats();
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);
  const { markets } = usePoolMarkets(pool);
  const [selection, setSelection] = useState<WrappedOption | undefined>(
    addingCollateral !== undefined
      ? {
          market: addingCollateral.market,
          isUnwrapped: addingCollateral.useUnwrappedMarket,
        }
      : undefined,
  );

  const [amountStr, setAmountStr] = useState(
    addingCollateral != undefined
      ? formatUnits(
          addingCollateral.amount,
          addingCollateral.market.marketDecimals,
        )
      : "",
  );

  const marketOptions = markets?.filter(canLeverage);

  // Defaults to first native token
  useEffect(() => {
    if (selection || !marketOptions) {
      return;
    }
    const firstWrappedMarket = marketOptions.find((market) =>
      isWrappedMarket(market, pool),
    );
    if (!firstWrappedMarket) {
      return;
    }
    setSelection({
      market: firstWrappedMarket,
      isUnwrapped: true,
    });
  }, [selection, marketOptions, pool]);

  const available = (() => {
    const userMarket =
      selection && userMarketsByAddress[selection.market.market];
    if (!userMarket) {
      return;
    }
    if (selection.isUnwrapped) {
      const unwrappedMarket = getUserUnwrappedMarket(userMarket);
      return {
        amount: unwrappedMarket.balance,
        decimals: unwrappedMarket.decimals,
      };
    } else {
      return {
        amount: userMarket.balance,
        decimals: selection.market.marketDecimals,
      };
    }
  })();
  const availableStr =
    available !== undefined
      ? formatUnits(available.amount, available.decimals)
      : undefined;

  const amount = selection
    ? tryParseUnits(amountStr, selection.market.marketDecimals)
    : 0n;

  const addingBorrowLimit =
    selection != undefined && amount
      ? getMarketBorrowLimit(selection.market, amount)
      : 0n;
  const newBorrowLimit = currentBorrowLimit + addingBorrowLimit;

  const currentBorrowLimitStr = formatUSDValue(currentBorrowLimit);
  const addingBorrowLimitStr = addingBorrowLimit
    ? `+${formatUSDValue(addingBorrowLimit)}`
    : null;
  const newBorrowLimitStr = formatUSDValue(newBorrowLimit);

  const removeCollateral = selection
    ? () => {
        setAddingCollateral(undefined);
        onClose();
      }
    : undefined;

  const setCollateral =
    selection && amount
      ? () => {
          setAddingCollateral({
            market: selection.market,
            amount,
            useUnwrappedMarket: selection.isUnwrapped,
          });
          onClose();
        }
      : undefined;

  const supplyError = (() => {
    if (amount === undefined || amount < 0n) {
      return "Invalid Amount";
    }
    if (available !== undefined) {
      if (amount > available.amount) {
        return "Insufficient Balance";
      }
    }
    return null;
  })();

  const canSupply = available && !supplyError;

  return (
    <>
      <ModalHeader py={6}>
        <Text fontWeight={600} fontSize={18} lineHeight={24 / 18}>
          {addingCollateral ? "Edit Collateral" : "Increase Collateral"}
        </Text>
      </ModalHeader>
      <ModalBody py={0}>
        <Stack gap={6}>
          <Stack gap={2}>
            <AssetSelector
              options={marketOptions || []}
              value={selection}
              modalTitle="Select Collateral Asset"
              showModalSupplyAPY
              onChange={(market, isUnwrapped) =>
                setSelection({
                  market,
                  isUnwrapped,
                })
              }
              amount={amountStr}
              onAmountChange={setAmountStr}
              isInValidAmount={false}
              label={null}
              showUnwrappedMarkets
            />
            {availableStr !== undefined ? (
              <HStack justifyContent="space-between">
                <Tooltip label={availableStr} placement="bottom-start">
                  <Text fontWeight={400} fontSize={14} lineHeight={22 / 14}>
                    Available: {`${toDigits(availableStr, 2)}`}
                  </Text>
                </Tooltip>
                {selection && !selection.isUnwrapped && (
                  <Button
                    variant="link"
                    color="primary.500"
                    onClick={() => setAmountStr(availableStr)}
                  >
                    Max
                  </Button>
                )}
              </HStack>
            ) : null}
          </Stack>
          <Stack gap={4} py={2}>
            <TxSummaryItem name="Current Borrow Limit">
              <Text>{currentBorrowLimitStr}</Text>
              {addingBorrowLimitStr && (
                <Text
                  color={addingBorrowLimit > 0n ? "color.long" : "color.short"}
                >
                  {addingBorrowLimitStr}
                </Text>
              )}
            </TxSummaryItem>
            <TxSummaryItem name="New Borrow Limit">
              {newBorrowLimitStr}
            </TxSummaryItem>
          </Stack>
          <Flex gap={3}>
            {addingCollateral && (
              <Button flex={1} variant="secondary" onClick={removeCollateral}>
                Remove Collateral
              </Button>
            )}

            <Button
              flex={1}
              variant="primary"
              onClick={setCollateral}
              isDisabled={!setCollateral || !canSupply}
              _disabled={
                supplyError
                  ? {
                      bgColor: "color.error",
                      textColor: "white",
                      cursor: "not-allowed",
                      _hover: {
                        bgColor: "color.error",
                        textColor: "white",
                      },
                    }
                  : undefined
              }
            >
              {supplyError || "Supply"}
            </Button>
          </Flex>
        </Stack>
      </ModalBody>
    </>
  );
};
