import BalanceInput from "@/components/BalanceInput.tsx";
import TokenIcon from "@/components/TokenIcon.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { NumberString, TxPreparation } from "@/types.ts";
import { getMarketUsdPrice } from "@/utils/market.ts";
import { isValidTxResult } from "@/utils/txBuilder.ts";
import { findWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Button, HStack, Stack, Text } from "@chakra-ui/react";
import { capitalize } from "lodash-es";
import { useContext, useState } from "react";
import { formatUnits, parseUnits } from "viem";

interface MobileBalanceEditorProps {
  txPreparation: TxPreparation;
  onClose: () => void;
}
const MobileBalanceEditor = ({
  txPreparation,
  onClose,
}: MobileBalanceEditorProps) => {
  const defaultAmount = txPreparation.amount
    ? formatUnits(txPreparation.amount, txPreparation.market.marketDecimals)
    : "";

  const { addTx, modifyTx, hasMarketTx } = useContext(TxBuilderContext);

  const isModifying = hasMarketTx(txPreparation.market);

  const [amount, setAmount] = useState(defaultAmount);

  const { marketSymbol, marketDecimals } = txPreparation.market;

  const { prepareTx } = useContext(TxBuilderContext);

  const txResult = prepareTx({
    market: txPreparation.market,
    action: txPreparation.marketAction,
    amount: parseUnits(amount as NumberString, marketDecimals),
    useUnwrappedMarket: !!txPreparation.useUnwrappedMarket,
  });

  const { pool } = usePool();

  const symbol = txPreparation.useUnwrappedMarket
    ? findWrappedMarket(txPreparation.market, pool).unwrappedSymbol
    : marketSymbol;

  return (
    <Stack px={6} spacing={6}>
      <HStack spacing={2}>
        <TokenIcon symbol={symbol} boxSize={8} />
        <Text variant="subtitle2" color="text.primary">
          {capitalize(txPreparation.marketAction)} {symbol}
        </Text>
      </HStack>
      <BalanceInput
        value={amount}
        maxValue={formatUnits(txResult.maxAmount, marketDecimals)}
        usdRate={getMarketUsdPrice(txPreparation.market)}
        onChange={setAmount}
        maxValuePrefixText="Available: "
      />
      <HStack justifyContent="center" spacing={3}>
        <Button
          variant="primary"
          w="full"
          isDisabled={!isValidTxResult(txResult)}
          onClick={() => {
            if (isModifying) {
              modifyTx(
                txPreparation.market,
                parseUnits(amount as NumberString, marketDecimals),
              );
            } else {
              addTx({
                market: txPreparation.market,
                action: txPreparation.marketAction,
                amount: parseUnits(amount as NumberString, marketDecimals),
                useUnwrappedMarket: !!txPreparation.useUnwrappedMarket,
              });
            }

            onClose();
          }}
        >
          {isModifying ? "Update Action" : "Add Action"}
        </Button>
        <Button
          variant="solid"
          bgColor="ui.disabled"
          _hover={{
            bgColor: "ui.disabled",
          }}
          color="text.primary"
          w="full"
          onClick={onClose}
        >
          Cancel
        </Button>
      </HStack>
    </Stack>
  );
};

export default MobileBalanceEditor;
