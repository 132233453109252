import { theme as baseTheme, extendTheme } from "@chakra-ui/react";
import Alert from "./components/Alert";
import Button from "./components/Button";
import Card from "./components/Card";
import Container from "./components/Container";
import DatePicker from "./components/DatePicker";
import Divider from "./components/Divider";
import Heading from "./components/Heading";
import Input from "./components/Input";
import Link from "./components/Link";
import Menu from "./components/Menu";
import Modal from "./components/Modal";
import Popover from "./components/Popover";
import Select from "./components/Select";
import Slider from "./components/Slider";
import Stat from "./components/Stat";
import Switch from "./components/Switch";
import Table from "./components/Table";
import Tabs from "./components/Tabs";
import Tag from "./components/Tag";
import Text from "./components/Text";
import Tooltip from "./components/ToolTip";

export const chakraTheme = extendTheme({
  styles: {
    global: {
      "html, body": {
        backgroundColor: "background.light",
        color: "white",
      },
    },
  },
  colors: {
    white: "#FFFFFF",
    primary: {
      100: "#96FFB1",
      300: "#68E98A",
      500: "#FCFC03",
      700: "#FCFC03",
      900: "#4E8A5E",
    },
    secondary: {
      100: "#EAECEF",
      300: "#FCD78B",
      500: "#848E9C",
      700: "#FAC748",
      900: "#EBA134",
    },
    background: {
      modal: "rgba(0,0,0,0.75)",
      input: "#1F1F1F",
      hover: "#1F1F1F",
      header: "#0E0E0F",
      body: "#111111",
      dark: "#31363F",
      light: "##F3F7F5",
      darkGreen: "#182420",
      darkGray: "#1A191C",
      popup: "#0C1D14",
      greenLinear: "linear-gradient(118.15deg, #39B54A 16.62%, #009444 75.4%);",
      darkLinear: "linear-gradient(180deg, #0E0E0F 0%, #03170D 100%);",
      redLinear:
        "linear-gradient(83.13deg, rgba(181, 57, 57, 0.1) 48.16%, rgba(255, 18, 18, 0.1) 58.17%);",
      error: "rgba(237, 67, 89, 0.2)",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#848E9C",
      third: "#B7BDC6",
      disabled: "#9FB2B5",
      divider: "#EAECEF",
      link: "#FCFC03",
    },
    disabled: {
      500: "#D8DCE1",
      700: "#B7BDC6",
    },
    gray: {
      100: "#F9FAFD",
      300: "#E9ECF1",
      500: "#949DB1",
      700: "#3B4250",
      900: "#151A26",
    },
    green: {
      ...baseTheme.colors.green,
      500: "#25AA48",
    },
    red: {
      ...baseTheme.colors.red,
      500: "#ED4359",
      700: "#B20D23",
    },
    orange: {
      ...baseTheme.colors.orange,
      500: "#F0B90B",
      700: "#C59500",
    },
    ui: {
      tag: "#FCFC03",
      circledBg: "#FCFC03",
      line: "#FCFC03",
      disabled: "#282929",
    },
    bg: {
      overall: "#111111",
      modal: "#000000",
    },
    color: {
      success: "#25AA48",
      error: "#ED4359",
      label: "#A6A6A6",
      long: "#25AA48",
      short: "#ED4359",
    },
  },
  sizes: {
    container: {
      base: "343px",
      lg: "1080px",
    },
  },
  breakpoints: {
    lg: "67.5em", // 1080px
  },
  fontSizes: {
    "2xs": "0.625rem", // 10px
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.75rem", // 28px
    "4xl": "2rem", // 32px
    "5xl": "3rem", // 48px
  },
  components: {
    Alert,
    Button,
    Card,
    Container,
    DatePicker,
    Divider,
    Link,
    Menu,
    Modal,
    Popover,
    Stat,
    Switch,
    Table,
    Tabs,
    Tooltip,
    Text,
    Tag,
    Input,
    Select,
    Heading,
    Slider,
  },
});

export default chakraTheme;
