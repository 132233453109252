import { Token } from "@/types/token.ts";
import BigNumber from "bignumber.js";
import { formatUnits, parseUnits } from "viem";

export class TokenAmount<T extends Token = Token> {
  public readonly token: T;
  public readonly amount: bigint;

  constructor(token: T, amount: bigint) {
    this.token = token;
    this.amount = amount;
  }

  // Returns a TokenAmount from human-readable string amount
  static parse<T extends Token>(token: T, amount: string): TokenAmount<T> {
    return new TokenAmount<T>(token, parseUnits(amount, token.decimals));
  }

  add(n: bigint) {
    return new TokenAmount(this.token, this.amount + n);
  }

  sub(n: bigint) {
    return new TokenAmount(this.token, this.amount - n);
  }

  mul(n: bigint) {
    return new TokenAmount(this.token, this.amount * n);
  }

  div(n: bigint) {
    return new TokenAmount(this.token, this.amount / n);
  }

  format(options?: { digits?: number; commify?: boolean }): string {
    const formatted = formatUnits(this.amount, this.token.decimals);

    if (!options) {
      return formatted;
    }

    const { digits, commify } = options;

    return BigNumber(formatted).toFormat(digits ?? this.token.decimals, {
      groupSize: 3,
      groupSeparator: commify ? "," : "",
      decimalSeparator: ".",
    });
  }
}
