import { useApproveExtension } from "@/hooks/extension/useApproveExtension";
import { Button, Stack, Text } from "@chakra-ui/react";

export const EnableExtensionButton = ({
  extension,
}: {
  extension: `0x${string}`;
}) => {
  const { isExtensionAllowed, allowExtension, isAllowingExtension } =
    useApproveExtension(extension);

  return (
    <Stack>
      <Button
        variant="primary"
        w="full"
        isDisabled={isExtensionAllowed || !allowExtension}
        isLoading={isAllowingExtension}
        onClick={allowExtension}
      >
        Enable
      </Button>
      <Text color="secondary.500" mt={2}>
        By enabling Alien Finance, you will be able to execute multiple actions
        in one transaction.
      </Text>
    </Stack>
  );
};
