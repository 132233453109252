import { getProtocols, Protocol } from "@/configs/chains";
import React, { createContext, useState } from "react";
import { useAccount, useChainId, useSwitchChain } from "wagmi";

const defaultProtocol = getProtocols()[0];

/**
 * When first visiting the site, `protocol` will be initialized to
 * `BlastProtocol`. Users can switch to any protocols available in
 * `protocols` by calling `changeProtocol()`.
 *
 * When a wallet is connected, the protocol will always have the same chain as
 * the wallet's current chain.
 */
interface Context {
  protocol: Protocol;
  protocols: Protocol[];
  changeProtocol(protocol: Protocol): void;
}

export const ProtocolContext = createContext<Context>({
  protocol: defaultProtocol,
  protocols: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeProtocol: () => {},
});

export default function ProtocolProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const protocols = getProtocols();

  // const cachedProtocol = useMemo(() => {
  //   let protocol = defaultProtocol;
  //   const previouslySelectedProtocol =
  //     window.localStorage.getItem("selectedProtocol");
  //   for (const p of protocols) {
  //     if (p.chain.name === previouslySelectedProtocol) {
  //       protocol = p;
  //     }
  //   }
  //   return protocol;
  // }, [protocols]);

  const { switchChainAsync } = useSwitchChain();
  const chainId = useChainId();
  const { isConnected } = useAccount();

  const [selectedProtocol, setSelectedProtocol] =
    useState<Protocol>(defaultProtocol);

  const changeProtocol = async (newProtocol: Protocol) => {
    if (newProtocol === selectedProtocol) {
      return;
    }

    if (isConnected && switchChainAsync) {
      await switchChainAsync({
        chainId: newProtocol.chain.id,
      });
      setSelectedProtocol(newProtocol);
      return;
    }

    setSelectedProtocol(newProtocol);
    window.localStorage.setItem("selectedProtocol", newProtocol.chain.name);
  };

  const context = {
    protocol: protocols.find((p) => p.chain.id === chainId) || defaultProtocol,
    protocols,
    changeProtocol,
  };

  return (
    <ProtocolContext.Provider value={context}>
      {children}
    </ProtocolContext.Provider>
  );
}
