import blastIcon from "@/assets/img/networks/blast.png";
import hardhatIcon from "@/assets/img/networks/hardhat.svg";
import { queryClient } from "@/lib/react-query.ts";
import { config, projectId } from "@/lib/wagmi.ts";
import { BlockNumberProvider } from "@/providers/BlockNumberProvider.tsx";
import { PoolProvider } from "@/providers/PoolProvider.tsx";
import ProtocolProvider from "@/providers/ProtocolProvider.tsx";
import chakraTheme from "@/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import { blast, hardhat } from "wagmi/chains";

import { routeTree } from "./routeTree.gen.ts";

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  chainImages: {
    [blast.id]: blastIcon,
    [hardhat.id]: hardhatIcon,
  },
  defaultChain: blast,
});

const router = createRouter({ routeTree });

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <ChakraProvider theme={chakraTheme}>
          <ProtocolProvider>
            <PoolProvider>
              <BlockNumberProvider>
                <RouterProvider router={router} />
              </BlockNumberProvider>
            </PoolProvider>
          </ProtocolProvider>
        </ChakraProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export default App;
