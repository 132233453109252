import useTokenIcon from "@/hooks/useTokenIcon";
import { Box, BoxProps, Image } from "@chakra-ui/react";

interface TokenIconProps extends BoxProps {
  symbol: string;
}

const TokenIcon = ({ symbol, ...restProps }: TokenIconProps) => {
  const token = useTokenIcon(symbol);
  return (
    <Box {...restProps}>
      <Image w="full" h="full" src={token} alt={symbol} />
    </Box>
  );
};

export default TokenIcon;
