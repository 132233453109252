import { toDigits } from "@/utils/format.ts";
import {
  Button,
  HStack,
  Input,
  InputProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Address, formatUnits } from "viem";
import { useAccount, useBalance } from "wagmi";

interface TokenInputProps
  extends Pick<InputProps, "onChange" | "onBlur" | "value" | "isReadOnly"> {
  symbol: string;
  token: Address;
  icon?: ReactNode;
  inputName: string; // input's name in the form
  showMaxButton?: boolean;
  onMax?: () => void;
  balanceValue?: string;
}

export const TokenInput = ({
  symbol,
  token,
  icon,
  inputName,
  onChange,
  onBlur,
  showMaxButton,
  onMax,
  value,
  balanceValue,
  isReadOnly,
}: TokenInputProps) => {
  const { address } = useAccount();
  const { data, isSuccess } = useBalance({ token, address });

  const balance = isSuccess ? formatUnits(data.value, data.decimals) : "0";

  return (
    <Stack
      border="1px solid"
      borderColor="ui.line"
      borderRadius="10"
      p={4}
      pb={3}
    >
      <HStack>
        <Input
          placeholder="0"
          variant="unstyled"
          type="number"
          value={value}
          name={inputName}
          onChange={onChange}
          onBlur={onBlur}
          isReadOnly={isReadOnly}
          borderRadius={0}
          sx={{
            _readOnly: {
              cursor: "not-allowed",
            },
          }}
        />
        <HStack>
          {icon}
          <Text fontWeight="bold" whiteSpace="nowrap">
            {symbol}
          </Text>
        </HStack>
      </HStack>
      <HStack justifyContent="flex-end" alignItems="baseline">
        {balanceValue !== undefined ? (
          <Text variant="subtitle4">Balance: {balanceValue}</Text>
        ) : (
          <Text variant="subtitle4">Balance: {toDigits(balance, 2)}</Text>
        )}
        {showMaxButton && (
          <Button variant="link" fontSize="sm" onClick={onMax}>
            Max
          </Button>
        )}
      </HStack>
    </Stack>
  );
};
