import {
  Table as ChakraTable,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  Row,
  RowData,
  Table,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    isNumeric?: boolean;
    maxWidth?: string;
    isIcon?: boolean;
    isSticky?: boolean;
  }
}

interface CustomTableProps<T> extends TableContainerProps {
  // See https://github.com/TanStack/table/issues/4382
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  data: T[];
  thProps?: TableColumnHeaderProps;
  tdProps?: TableCellProps;
  onRowClick?: (row: Row<T>, table: Table<T>) => void;
}

export const CustomTable = <T,>({
  columns,
  data,
  onRowClick,
  thProps,
  tdProps,
  ...props
}: CustomTableProps<T>): JSX.Element => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <TableContainer {...props}>
      <ChakraTable variant="unstyled">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  px={header.column.columnDef.meta?.isIcon ? 0 : 3}
                  isNumeric={header.column.columnDef.meta?.isNumeric}
                  maxW={header.column.columnDef.meta?.maxWidth}
                  borderBottom={"unset"}
                  sx={
                    header.column.columnDef.meta?.isSticky
                      ? {
                          width: header.column.columnDef.meta?.maxWidth,
                          position: "sticky",
                          left: 0,
                          bgColor: "black",
                        }
                      : undefined
                  }
                  {...thProps}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              id={row.id}
              key={row.id}
              cursor={onRowClick ? "pointer" : "unset"}
              _hover={{ background: onRowClick ? "bg.overall" : "unset" }}
              onMouseEnter={() => row.toggleSelected(true)}
              onMouseLeave={() => row.toggleSelected(false)}
              onClick={() => onRowClick && onRowClick(row, table)}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  id={cell.id}
                  key={cell.id}
                  px={cell.column.columnDef.meta?.isIcon ? 0 : 3}
                  maxW={cell.column.columnDef.meta?.maxWidth}
                  isNumeric={cell.column.columnDef.meta?.isNumeric}
                  borderBottom={"unset"}
                  textAlign={
                    cell.column.columnDef.meta?.isNumeric ? "end" : "start"
                  }
                  sx={
                    cell.column.columnDef.meta?.isSticky
                      ? {
                          w: cell.column.columnDef.meta?.maxWidth,
                          position: "sticky",
                          left: 0,
                          bgColor: "black",
                        }
                      : undefined
                  }
                  {...tdProps}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};

export default CustomTable;
