import { BlockNumberContext } from "@/providers/BlockNumberProvider.tsx";
import { useContext } from "react";

function useBlockNumberContext() {
  const context = useContext(BlockNumberContext);
  if (context === null) {
    throw Error("BlockNumberContext is not initialized");
  }
  return context;
}

export function useBlockNumber() {
  return useBlockNumberContext().blockNumber;
}

export function useFastForwardBlockNumber() {
  return useBlockNumberContext().fastForward;
}
