import useMediaQuery from "@/hooks/useMediaQuery";
import { shortAddress } from "@/utils/format";
import { Button, Icon, IconButton } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { RiWalletLine } from "react-icons/ri";
import { useAccount, useEnsName } from "wagmi";

const ConnectButton = () => {
  const { isConnected, isDisconnected, address } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { isDesktop } = useMediaQuery();
  const { open } = useWeb3Modal();

  if (isDesktop) {
    return isConnected ? (
      <Button variant="primary" onClick={() => open()}>
        {ensName || shortAddress(address as string)}
      </Button>
    ) : (
      <Button variant="primary" onClick={() => open()}>
        Connect
      </Button>
    );
  } else {
    return (
      <IconButton
        variant="link"
        color={isConnected ? "primary.500" : "text.primary"}
        aria-label="wallet"
        onClick={() => (isDisconnected ? open() : open({ view: "Account" }))}
      >
        <Icon as={RiWalletLine} boxSize={6} />
      </IconButton>
    );
  }
};

export default ConnectButton;
