import { Tab, TabList } from "@chakra-ui/react";

export const CustomTabList = ({ tabs }: { tabs: string[] }) => {
  return (
    <TabList paddingX={6}>
      {tabs.map((value, index) => (
        <Tab
          key={value}
          borderLeft={index !== 0 ? "1px solid" : undefined}
          borderColor="ui.tag"
          paddingLeft={index !== 0 ? 4 : 0}
          paddingRight={4}
          paddingY={0}
          fontWeight={600}
          fontSize={14}
          textAlign="left"
        >
          {value}
        </Tab>
      ))}
    </TabList>
  );
};
