import { useBreakpointValue } from "@chakra-ui/react";

export const useMediaQuery = () => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      xs: true,
      sm: true,
      md: true,
      lg: false,
    },
    { ssr: false },
  );
  return {
    isMobile,
    isDesktop: !isMobile,
  };
};

export default useMediaQuery;
