import Logo from "@/assets/img/alien.svg";
import ConnectButton from "@/components/ConnectButton";
import { PoolSwitch } from "@/components/PoolSwitch.tsx";
import { navLinks } from "@/utils/constants";
import {
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as TSLink } from "@tanstack/react-router";
import { map } from "lodash-es";
import { Fragment } from "react";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseLine,
  RiMenuLine,
} from "react-icons/ri";

const MobileNavigationBar = () => {
  // const { protocol: currentProtocol } = useContext(ProtocolContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenSubLinks, onToggle } = useDisclosure();

  return (
    <Box
      as="header"
      pos="sticky"
      w="full"
      top={0}
      bgColor="black"
      zIndex={10}
      py={4}
      px={6}
    >
      <Flex alignItems="center">
        <Link as={TSLink} to="/">
          <Image src={Logo} h="2.5rem" w="auto" />
        </Link>
        <Spacer />
        <HStack spacing={4}>
          {/*<IconButton variant="link" aria-label="network">*/}
          {/*  <Image src={currentProtocol.icon} boxSize={6} />*/}
          {/*</IconButton>*/}
          {/*<NetworkSwitch />*/}
          <PoolSwitch />
          <ConnectButton />
          <IconButton
            variant="link"
            color="text.primary"
            aria-label="menu"
            onClick={() => onOpen()}
          >
            <Icon as={RiMenuLine} boxSize={6} />
          </IconButton>
        </HStack>
      </Flex>
      <Drawer onClose={onClose} isOpen={isOpen} size="full">
        <DrawerOverlay />
        <DrawerContent bgColor="black">
          <DrawerHeader>
            <HStack justifyContent="space-between" alignItems="center">
              <Box>
                <Image
                  src={Logo}
                  h="2.5rem"
                  w="auto"
                  // alt="Iron Bank"
                />
              </Box>
              <IconButton
                variant="link"
                aria-label="close"
                color="text.primary"
                onClick={() => onClose()}
              >
                <Icon as={RiCloseLine} boxSize={6} />
              </IconButton>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <Stack h="full" spacing={4}>
              {map(navLinks, ({ name, path, children, badge }) =>
                children ? (
                  <Fragment key={name}>
                    <Button
                      variant="nav"
                      w="full"
                      justifyContent="flex-start"
                      onClick={() => onToggle()}
                    >
                      <HStack alignItems="center">
                        <Text>{name}</Text>
                        {isOpenSubLinks ? (
                          <Icon as={RiArrowUpSLine} />
                        ) : (
                          <Icon as={RiArrowDownSLine} />
                        )}
                      </HStack>
                    </Button>
                    <Collapse in={isOpenSubLinks} animateOpacity={false}>
                      <Stack px={8}>
                        {map(children, (subLink) => (
                          <Button
                            w="full"
                            justifyContent="flex-start"
                            as={TSLink}
                            variant="nav"
                            _activeLink={{
                              bgColor: "secondary.100",
                              color: "text.primary",
                            }}
                            to={subLink.path}
                            key={subLink.name}
                            onClick={() => onClose()}
                          >
                            {subLink.name}
                          </Button>
                        ))}
                      </Stack>
                    </Collapse>
                  </Fragment>
                ) : (
                  <Button
                    w="full"
                    justifyContent="flex-start"
                    as={TSLink}
                    variant="nav"
                    _activeLink={{
                      bgColor: "secondary.100",
                      color: "black",
                    }}
                    borderRadius={0}
                    to={path}
                    key={name}
                    onClick={() => onClose()}
                  >
                    {name}
                    {badge && (
                      <Tag ms={2} borderRadius="full" bgColor="primary.500">
                        <TagLabel>{badge}</TagLabel>
                      </Tag>
                    )}
                  </Button>
                ),
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileNavigationBar;
