import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/theme-tools";

// See https://github.com/chakra-ui/chakra-ui/blob/e3673f4f4697a673d6b267a12dad64ecfb5b6f26/packages/theme/src/components/tabs.ts#L10C1-L10C30
const $bg = cssVar("tabs-bg");

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const variantLine = definePartsStyle({
  tab: {
    _active: {
      [$bg.variable]: "unset",
    },
    _selected: {
      color: "primary.500",
    },
    color: "white",
    fontWeight: "bold",
    fontSize: "sm",
  },
});

const unstyledStyle = definePartsStyle({
  tab: {
    color: "text.secondary",
    _focus: {
      color: "ui.tag",
    },
    _selected: {
      color: "ui.tag",
    },
  },
});

export default defineMultiStyleConfig({
  variants: { line: variantLine, unstyled: unstyledStyle },
});
