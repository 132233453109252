export const MaxUint256 =
  0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffn;

export const WeiPerEth = 1000000000000000000n;

export const FactorScale = 10n ** 4n;

export type NavigationLink = {
  name: string;
  path: string;
  isExternal: boolean;
  badge?: string;
  children?: NavigationLink[];
};

export const navLinks: NavigationLink[] = [
  // {
  //   name: "Warp",
  //   path: "/warp",
  //   isExternal: false,
  // },
  {
    name: "Earn",
    path: "/earn",
    isExternal: false,
  },
  {
    name: "Lending",
    path: "/lending",
    isExternal: false,
  },
  {
    name: "Leverage",
    path: "/leverage",
    isExternal: false,
  },
  {
    name: "Market",
    path: "/markets",
    isExternal: false,
  },
  {
    name: "Docs",
    path: "https://docs.alien.finance",
    isExternal: true,
  },
  // {
  //   name: "Markets",
  //   path: "/markets",
  //   isExternal: false,
  // },
];

export const chartColors = [
  "#1A365D",
  "#1D4044",
  "#065666",
  "#1C4532",
  "#171923",
];

export const AlienAddress = "0xCa84812E477eE5a96a92328689D8Ce2589aB6FfD";

export const YesAddress = "0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C";

// Disable supply button for "pUSDB-WETH LP" market
export const supplyBlockList = ["0x373318cccC1da7934929d8af23eA17641522206B"];
