import { Pool } from "@/configs/chains.ts";
import { AlienLens } from "@/contracts/AlienLens";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserWrappedMarkets } from "@/hooks/useUserWrappedMarkets";
import { UserMarket } from "@/types";
import { filter, keyBy, set } from "lodash-es";
import { Address } from "viem";
import { useAccount, useSimulateContract } from "wagmi";

export function useUserPoolMarkets(pool: Pool) {
  const { address } = useAccount();

  const { isLoading: isLoadingMarkets, isSuccess: isMarketSuccess } =
    usePoolMarkets(pool);

  const {
    data: userMarkets,
    isLoading: isLoadingUserMarkets,
    isSuccess: isUserMarketsSuccess,
  } = useSimulateContract({
    abi: AlienLens,
    address: pool.contracts.lens,
    functionName: "getUserAllCurrentMarketsStatus",
    args: [pool.contracts.alien, address || "0x"],
    query: {
      enabled: !!address && isMarketSuccess,
    },
  });

  const userSupplyMarkets: UserMarket[] = filter(
    userMarkets?.result,
    (m) => m.supplyBalance !== 0n,
  );

  const userBorrowMarkets: UserMarket[] = filter(
    userMarkets?.result,
    (m) => m.borrowBalance !== 0n,
  );

  const {
    data: userWrappedMarkets,
    isLoading: isUserWrappedMarketsLoading,
    isSuccess: isUserWrappedMarketsSuccess,
  } = useUserWrappedMarkets();

  if (userMarkets) {
    for (const m of userMarkets.result) {
      if (userWrappedMarkets && userWrappedMarkets[m.market]) {
        set(m, "unwrappedMarket", userWrappedMarkets[m.market]);
      }
    }
  }

  const isSuccess =
    isMarketSuccess && isUserMarketsSuccess && isUserWrappedMarketsSuccess;

  const isLoading =
    isLoadingUserMarkets || isLoadingMarkets || isUserWrappedMarketsLoading;

  if (!isSuccess || userMarkets === undefined) {
    return {
      userMarkets: [],
      userMarketsByAddress: {} as Record<Address, UserMarket>,
      userSupplyMarkets: [] as UserMarket[],
      userBorrowMarkets: [] as UserMarket[],
      isLoading,
      isSuccess,
    };
  }

  return {
    userMarkets: userMarkets.result as UserMarket[],
    userMarketsByAddress: keyBy(userMarkets.result, "market") as Record<
      Address,
      UserMarket
    >,
    userSupplyMarkets,
    userBorrowMarkets,
    isLoading,
    isSuccess,
  };
}
