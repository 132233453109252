import TokenIcon from "@/components/TokenIcon.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { Market, UserMarket } from "@/types.ts";
import { findWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Box, HStack, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { RiShieldCheckLine } from "react-icons/ri";
import { formatUnits } from "viem";

interface AssetProps {
  market: Market;
  userMarket?: UserMarket;
  useUnwrappedMarket?: boolean;
}

export function Asset({ market, useUnwrappedMarket }: AssetProps) {
  const { marketSymbol, marketDecimals } = market;

  const { pool } = usePool();

  const symbol = useUnwrappedMarket
    ? findWrappedMarket(market, pool).unwrappedSymbol
    : marketSymbol;

  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: 2,
  });

  return (
    <HStack spacing={2}>
      <TokenIcon symbol={symbol} boxSize={8} />
      <Box>
        <HStack spacing={2}>
          <Text variant="subtitle3" color="text.primary">
            {symbol}
          </Text>
          {market.isPToken && (
            <Tooltip
              hasArrow={true}
              placement="top"
              label={
                <Box p={2}>
                  <Text variant="body3" lineHeight="1rem">
                    {/*For supply only. Your asset <br /> will not be borrowable.*/}
                    Rebalancing Thruster v3 LP Collateral Pilot - contact us for
                    waitlist
                  </Text>
                </Box>
              }
            >
              <Box as="span">
                <Icon as={RiShieldCheckLine} color="primary.500" />
              </Box>
            </Tooltip>
          )}
        </HStack>
        <Stack spacing={0}>
          <Text variant="caption1" color="secondary.500">
            Total Supply:{" "}
            {formatter.format(
              Number(formatUnits(market.totalSupply, marketDecimals)),
            )}
          </Text>
        </Stack>
      </Box>
    </HStack>
  );
}
