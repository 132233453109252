import ActionItem from "@/components/ActionItem.tsx";
import { SummaryItem, TxSummaryCard } from "@/components/TxSummaryCard.tsx";
import SubmitTxsButton from "@/features/lending/components/buttons/SubmitTxsButton.tsx";
import { useUserStats } from "@/hooks/useUserStats.ts";
import { Tx, TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { formatBigInt, formatPercentage } from "@/utils/format.ts";
import {
  Box,
  Button,
  Card,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { isEmpty, map } from "lodash-es";
import { useContext } from "react";
import { RiQuestionLine } from "react-icons/ri";

interface ActionEditorCardProps {
  onEditTx: (tx: Tx) => void;
}

const ActionEditorCard = ({ onEditTx }: ActionEditorCardProps) => {
  const { txs, removeAllTx, removeTx } = useContext(TxBuilderContext);

  const getPendingActionText = () => {
    if (txs.length === 1) {
      return "1 Pending Action";
    } else {
      return `${txs.length} Pending Actions`;
    }
  };

  const currentBorrowStats = useUserStats();
  const { finalBorrowStats } = useContext(TxBuilderContext);

  const borrowLimitChange =
    finalBorrowStats.borrowLimit - currentBorrowStats.borrowLimit;
  const liquidationThresholdChange =
    finalBorrowStats.liquidationThreshold -
    currentBorrowStats.liquidationThreshold;
  const liquidationPctChange =
    finalBorrowStats.liquidationPct - currentBorrowStats.liquidationPct;

  const txItems: SummaryItem[] = [
    {
      title: "Borrow Limit",
      primary: {
        value: formatBigInt(finalBorrowStats.borrowLimit, 18, 2),
        prefix: "$",
      },
      secondary:
        borrowLimitChange !== 0n
          ? {
              value: formatBigInt(borrowLimitChange, 18, 2),
              prefix: borrowLimitChange > 0n ? "+$" : "$",
              color: borrowLimitChange > 0n ? "color.success" : "color.error",
            }
          : undefined,
    },
    {
      title: "Liquidation Threshold",
      primary: {
        value: formatBigInt(finalBorrowStats.liquidationThreshold, 18, 2),
        prefix: "$",
      },
      secondary:
        liquidationThresholdChange !== 0n
          ? {
              value: formatBigInt(liquidationThresholdChange, 18, 2),
              prefix: liquidationThresholdChange > 0n ? "+$" : "$",
              color:
                liquidationThresholdChange > 0n
                  ? "color.success"
                  : "color.error",
            }
          : undefined,
    },
    {
      title: "Risk Level",
      primary: {
        value: formatPercentage(finalBorrowStats.liquidationPct, 2),
      },
      secondary:
        liquidationPctChange !== 0
          ? {
              value: formatPercentage(liquidationPctChange, 2),
              prefix: liquidationPctChange > 0 ? "+" : "",
              color: liquidationPctChange > 0 ? "color.error" : "color.success",
            }
          : undefined,
    },
  ];

  return (
    <Card py={6}>
      {!isEmpty(txs) && (
        <>
          <Stack spacing={6}>
            <HStack justifyContent="space-between" px={6}>
              <HStack alignItems="center" spacing={1}>
                <Text variant="subtitle4" color="white">
                  {getPendingActionText()}
                </Text>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={
                    <Box p={2}>
                      <Text variant="body3" lineHeight="1rem">
                        You can add multiple supply and borrow actions to
                        execute at once.
                      </Text>
                    </Box>
                  }
                >
                  <Box as="span">
                    <Icon as={RiQuestionLine} color="white" />
                  </Box>
                </Tooltip>
              </HStack>
              <Button
                variant="link"
                color="primary.700"
                onClick={() => removeAllTx()}
              >
                Clear All
              </Button>
            </HStack>
            {map(txs, (tx) => (
              <ActionItem
                key={tx.market.market}
                sx={{
                  pl: 6,
                  pr: 5,
                  py: 5,
                }}
                tx={tx}
                onClick={(tx) => onEditTx(tx)}
                onRemove={(tx) => removeTx(tx.market)}
              />
            ))}
            <Box px={6} w="full">
              <SubmitTxsButton />
            </Box>
          </Stack>
          <Divider my={6} borderColor="text.divider" />
        </>
      )}
      <TxSummaryCard items={txItems} />
    </Card>
  );
};

export default ActionEditorCard;
