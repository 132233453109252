import ProgressBar from "@/components/ProgressBar";
import useBannerStats from "@/hooks/useBannerStats";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserStats } from "@/hooks/useUserStats";
import { UserStats } from "@/types";
import { formatBigInt, formatPercentage, toDigits } from "@/utils/format";
import { getMarketUsdPrice } from "@/utils/market.ts";
import {
  Grid,
  GridItem,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { reduce } from "lodash-es";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";

interface StatBannerProps {
  newUserStats?: UserStats;
}

const StatBanner = ({ newUserStats }: StatBannerProps) => {
  const { isDesktop } = useMediaQuery();

  const { isConnected } = useAccount();

  const { supplyValue, borrowValue, netValue, netApy } = useBannerStats();

  const { pool } = usePool();
  const { markets } = usePoolMarkets(pool);

  const tvl = reduce(
    markets,
    (result, market) => {
      return (
        result +
        getMarketUsdPrice(market) *
          BigNumber(
            formatUnits(market.totalCash, market.marketDecimals),
          ).toNumber()
      );
    },
    0,
  );

  const userStats = useUserStats();

  const change = newUserStats
    ? {
        supply: newUserStats.supplyBalance - userStats.supplyBalance,
        borrow: newUserStats.borrowBalance - userStats.borrowBalance,
        netValue:
          newUserStats.supplyBalance -
          newUserStats.borrowBalance -
          (userStats.supplyBalance - userStats.borrowBalance),
        netApy: newUserStats.netApy - userStats.netApy,
      }
    : undefined;

  const color = {
    green: "color.success",
    red: "color.error",
  };

  const borrowLimitPct = (() => {
    if (userStats.liquidationThreshold === 0n) {
      return 0;
    }

    return BigNumber(userStats.borrowLimit.toString())
      .div(userStats.liquidationThreshold.toString())
      .multipliedBy(100)
      .toNumber();
  })();

  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      columnGap={6}
      pt={{ base: 6, lg: 8 }}
    >
      <GridItem colSpan={5}>
        <Stat textAlign="center">
          <StatLabel fontSize="xl">Total Value Locked</StatLabel>
          <StatNumber color="primary.500" fontSize="3xl">
            ${toDigits(tvl, 2)}
          </StatNumber>
        </Stat>
      </GridItem>
      <GridItem
        colSpan={{ base: 5, lg: 3 }}
        pt={{ base: 3, lg: 8 }}
        pb={{ base: 3, lg: 8 }}
      >
        <StatGroup>
          <Stat>
            <StatLabel>Net APY</StatLabel>
            <StatNumber>
              {netApy.toFixed(2)}
              <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
                %
              </Text>
            </StatNumber>
            {change && change.netApy !== 0 && (
              <Text
                variant="subtitle5"
                color={change.netApy < 0 ? color.red : color.green}
              >
                {formatPercentage(change.netApy, 1)}
              </Text>
            )}
          </Stat>
          {isDesktop && (
            <Stat>
              <StatLabel>Net Value</StatLabel>
              <StatNumber>
                <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
                  $
                </Text>
                {formatBigInt(netValue, 18, 2)}
              </StatNumber>
              {change && change.netValue !== 0n && (
                <Text
                  variant="subtitle5"
                  color={change.netValue < 0 ? color.red : color.green}
                >
                  ${formatBigInt(change.netValue, 18, 2)}
                </Text>
              )}
            </Stat>
          )}
          <Stat>
            <StatLabel>My Supply</StatLabel>
            <StatNumber>
              <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
                $
              </Text>
              {formatBigInt(supplyValue, 18, 2)}
            </StatNumber>
            {change && change.supply !== 0n && (
              <Text
                variant="subtitle5"
                color={change.supply < 0 ? color.red : color.green}
              >
                ${formatBigInt(change.supply, 18, 2)}
              </Text>
            )}
          </Stat>
          <Stat>
            <StatLabel>My Borrow</StatLabel>
            <StatNumber>
              <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
                $
              </Text>
              {formatBigInt(borrowValue, 18, 2)}
            </StatNumber>
            {change && change.borrow !== 0n && (
              <Text
                variant="subtitle5"
                color={change.borrow < 0 ? color.red : color.green}
              >
                ${formatBigInt(change.borrow, 18, 2)}
              </Text>
            )}
          </Stat>
        </StatGroup>
      </GridItem>
      <GridItem colSpan={{ base: 5, lg: 2 }}>
        <StatGroup pt={{ base: 3, lg: 8 }} pb={{ base: 6, lg: 8 }}>
          <Stat>
            <StatLabel>Risk Level</StatLabel>
            <HStack spacing={5}>
              <StatNumber>
                {(userStats.liquidationPct * 100).toFixed(1)}
                <Text as="span" fontSize="1.125rem" lineHeight="1.5rem">
                  %
                </Text>
              </StatNumber>
              <ProgressBar
                value={userStats.liquidationPct * 100}
                references={
                  isConnected
                    ? [
                        {
                          label: `Borrow limit: $${formatBigInt(
                            userStats.borrowLimit,
                            18,
                            2,
                          )}`,
                          value: borrowLimitPct,
                          color: "orange.500",
                          bgColor: "orange.700",
                        },
                        {
                          label: `Liquidation Threshold: $${formatBigInt(
                            userStats.liquidationThreshold,
                            18,
                            2,
                          )}`,
                          value: 100,
                          color: "red.500",
                          bgColor: "red.700",
                        },
                      ]
                    : []
                }
              />
            </HStack>
          </Stat>
        </StatGroup>
      </GridItem>
    </Grid>
  );
};

export default StatBanner;
