import alien from "@/assets/img/tokens/alien.png";
import blast from "@/assets/img/tokens/blast.png";
import blur from "@/assets/img/tokens/blur.png";
import dai from "@/assets/img/tokens/dai.svg";
import eth from "@/assets/img/tokens/eth.png";
import hyper from "@/assets/img/tokens/hyper.png";
import juice from "@/assets/img/tokens/juice.png";
import machi from "@/assets/img/tokens/machi.svg";
import orbit from "@/assets/img/tokens/orbit.png";
import pac from "@/assets/img/tokens/pacmoon.png";
import thrust from "@/assets/img/tokens/thrust.svg";
import usdb from "@/assets/img/tokens/usdb.svg";
import usdc from "@/assets/img/tokens/usdc.png";
import usdt from "@/assets/img/tokens/usdt.png";
import wethUsdb from "@/assets/img/tokens/weth-usdb.png";
import weth from "@/assets/img/tokens/weth.png";
import yes from "@/assets/img/tokens/yes.svg";

interface TokenIcon {
  [symbol: string]: string;
}

const tokenIcon: TokenIcon = {
  alien,
  blast,
  blur,
  dai,
  eth,
  hyper,
  juice,
  machi,
  orbit,
  pac,
  thrust,
  usdb,
  usdc,
  usdt,
  weth,
  yes,
  "pusdb-weth lp": wethUsdb,
};

const useTokenIcon = (symbol: string) => {
  const symbolLower = symbol.toLowerCase();

  return tokenIcon[symbolLower];
};

export default useTokenIcon;
