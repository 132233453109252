import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    border: "1px solid",
    borderColor: "ui.line",
    bg: "#000",
    py: 4,
  },
  item: {
    h: 14,
    px: 4,
    bg: "#000",
    _hover: {
      bg: "#1f1f1f",
    },
    _focus: {
      bg: "#1f1f1f",
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
