import ApproveButton from "@/components/ApproveButton.tsx";
import TokenIcon from "@/components/TokenIcon.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { Tx } from "@/providers/TxBuilder.tsx";
import { findWrappedMarket } from "@/utils/wrappedMarket.ts";
import { HStack, Text } from "@chakra-ui/react";
import { map } from "lodash-es";

interface UnapprovedTxProps {
  unapprovedTxs: Tx[];
}

export const UnapprovedTx = ({ unapprovedTxs }: UnapprovedTxProps) => {
  const { pool } = usePool();

  return (
    <>
      {map(unapprovedTxs, (tx) => {
        const { market, useUnwrappedMarket } = tx;

        let symbol = market.marketSymbol;
        let token = market.market;
        let spender = pool.contracts.alien;

        if (useUnwrappedMarket) {
          const wrappedMarket = findWrappedMarket(market, pool);
          symbol = wrappedMarket.unwrappedSymbol;
          token = wrappedMarket.unwrappedMarket;
          spender = pool.contracts.extension.txBuilder;
        }

        return (
          <HStack key={market.market} justifyContent="space-between">
            <HStack>
              <TokenIcon boxSize={8} symbol={symbol} />
              <Text variant="subtitle2" color="text.primary">
                {symbol}
              </Text>
            </HStack>
            <ApproveButton token={token} spender={spender} w="7.5rem">
              Approve
            </ApproveButton>
          </HStack>
        );
      })}
    </>
  );
};
