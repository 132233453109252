import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { getBalanceValue, getMarketApy } from "@/utils/market";
import BigNumber from "bignumber.js";
import { reduce } from "lodash-es";

function useBannerStats() {
  const { pool } = usePool();
  const { marketsByAddress } = usePoolMarkets(pool);
  const { userSupplyMarkets, userBorrowMarkets } = useUserPoolMarkets(pool);

  const supplyValue = (() => {
    return reduce(
      userSupplyMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        return prev + getBalanceValue(curr.supplyBalance, market.marketPrice);
      },
      0n,
    );
  })();

  const borrowValue = (() => {
    return reduce(
      userBorrowMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        return prev + getBalanceValue(curr.borrowBalance, market.marketPrice);
      },
      0n,
    );
  })();

  const supplyInterestValue = (() => {
    return reduce(
      userSupplyMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        const apy = getMarketApy(market.supplyRate);
        const interest = BigNumber(curr.supplyBalance.toString())
          .multipliedBy(apy)
          .toFixed(0);
        return prev + getBalanceValue(BigInt(interest), market.marketPrice);
      },
      0n,
    );
  })();

  const borrowInterestValue = (() => {
    return reduce(
      userBorrowMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        const apy = getMarketApy(market.borrowRate);
        const interest = BigNumber(curr.borrowBalance.toString())
          .multipliedBy(apy)
          .toFixed(0);
        return prev + getBalanceValue(BigInt(interest), market.marketPrice);
      },
      0n,
    );
  })();

  const netValue = supplyValue - borrowValue;

  const netInterestValue = supplyInterestValue - borrowInterestValue;

  const netApy = (() => {
    if (supplyValue === 0n) {
      return 0;
    }

    return BigNumber(netInterestValue.toString())
      .div(supplyValue.toString())
      .toNumber();
  })();

  return {
    supplyValue,
    borrowValue,
    netApy,
    netValue,
  };
}

export default useBannerStats;
