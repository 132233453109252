import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Address } from "viem";
import { useAccount } from "wagmi";

type UserAlienRewardsResponse = {
  contract: string;
  root: string;
  account: string;
  claimed: string;
  totalAmount: string;
  proof: `0x${string}`[];
  timestamp: number;
};

type UseUserAlienRewardsData = {
  contract: Address;
  root: string;
  account: string;
  claimed: bigint;
  totalAmount: bigint;
  claimable: bigint;
  proof: `0x${string}`[];
  timestamp: number;
};

export function useUserAlienRewards() {
  const { address } = useAccount();

  return useQuery({
    queryKey: ["alien-rewards", address],
    queryFn: async (): Promise<UseUserAlienRewardsData> => {
      const { data } = await axios.get<UserAlienRewardsResponse>(
        "https://api.alien.finance/api/v1/reward/claim-info",
        {
          params: {
            account: address,
          },
        },
      );

      const claimed = BigInt(data.claimed);
      const totalAmount = BigInt(data.totalAmount);

      return {
        contract: data.contract as Address,
        root: data.root,
        account: data.account,
        claimed: BigInt(data.claimed),
        totalAmount: BigInt(data.totalAmount),
        claimable: claimed <= totalAmount ? totalAmount - claimed : 0n,
        proof: data.proof,
        timestamp: data.timestamp,
      };
    },
    enabled: !!address,
  });
}
