import ApproveButton from "@/components/ApproveButton";
import { EnableExtensionButton } from "@/components/EnableExtensionButton";
import { useApproveExtension } from "@/hooks/extension/useApproveExtension";
import { useSendUniswapExtensionActions } from "@/hooks/uniswapExtension/useSendUniswapExtensionActions";
import { usePool } from "@/hooks/usePool.ts";
import { ExtensionAction } from "@/types";
import { Erc20Token } from "@/types/token";
import { Button } from "@chakra-ui/react";
import { useEffect } from "react";

interface SubmitTxsButtonProps {
  getExtensionActions: (() => ExtensionAction[]) | undefined;
  nextApprovalToken?: Erc20Token | null | undefined;
  onSuccess: () => void;
  error: string | null | undefined;
  isLoading?: boolean;
}

const SubmitTxsButton = ({
  isLoading,
  getExtensionActions,
  nextApprovalToken,
  onSuccess,
  error,
}: SubmitTxsButtonProps) => {
  const { pool } = usePool();

  const { isExtensionAllowed } = useApproveExtension(
    pool.contracts.extension.uniswap,
  );

  const { sendActions, isSending, isEstimatingGas, isSuccess } =
    useSendUniswapExtensionActions(getExtensionActions);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    // We only want onSuccess to run once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      {isExtensionAllowed === false ? (
        <EnableExtensionButton extension={pool.contracts.extension.uniswap} />
      ) : nextApprovalToken ? (
        <ApproveButton
          token={nextApprovalToken.address}
          spender={pool.contracts.alien}
          w="full"
        >
          {`Approve ${nextApprovalToken.symbol}`}
        </ApproveButton>
      ) : (
        <Button
          variant="primary"
          w="full"
          onClick={sendActions}
          isDisabled={!!error || !sendActions}
          isLoading={isSending || isLoading || isEstimatingGas}
          _disabled={
            error
              ? {
                  bgColor: "color.error",
                  textColor: "white",
                  cursor: "not-allowed",
                  _hover: {
                    bgColor: "color.error",
                    textColor: "white",
                  },
                }
              : undefined
          }
        >
          {error || "Submit Transaction"}
        </Button>
      )}
    </>
  );
};

export default SubmitTxsButton;
