import { TxBuilderExtension } from "@/contracts/TxBuilderExtension.ts";
import { usePool } from "@/hooks/usePool.ts";
import { ExtensionAction } from "@/types";
import { Address, encodeAbiParameters, parseAbiParameters, toHex } from "viem";
import { useWriteContract } from "wagmi";

function encodeActionName(name: string) {
  return toHex(name, { size: 32 });
}

export function newDeferLiquidityCheckAction(): ExtensionAction {
  return {
    name: encodeActionName("ACTION_DEFER_LIQUIDITY_CHECK"),
    data: "0x",
  };
}

export function newSupplyNativeTokenAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName("ACTION_SUPPLY_NATIVE_TOKEN"),
    data,
    value: amount,
  };
}

export function newBorrowNativeTokenAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName("ACTION_BORROW_NATIVE_TOKEN"),
    data,
  };
}

export function newWithdrawNativeTokenAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName("ACTION_REDEEM_NATIVE_TOKEN"),
    data,
  };
}

export function newRepayNativeTokenAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName("ACTION_REPAY_NATIVE_TOKEN"),
    data,
    value: amount,
  };
}

export function newStEthAction(
  amount: bigint,
  action: "SUPPLY" | "BORROW" | "REDEEM" | "REPAY",
): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName(`ACTION_${action}_STETH`),
    data,
  };
}

function newPoolAction(
  actionName: string,
  asset: Address,
  amount: bigint,
): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("address, uint256"), [
    asset,
    amount,
  ]);

  return {
    name: encodeActionName(actionName),
    data,
  };
}

export function newSupplyAction(
  asset: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_SUPPLY", asset, amount);
}

export function newBorrowAction(
  asset: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_BORROW", asset, amount);
}

export function newWithdrawAction(
  asset: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_REDEEM", asset, amount);
}

export function newRepayAction(
  asset: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_REPAY", asset, amount);
}

export function newSupplyPTokenAction(
  pToken: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_SUPPLY_PTOKEN", pToken, amount);
}

export function newWithdrawPTokenAction(
  pToken: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_REDEEM_PTOKEN", pToken, amount);
}

export function useSendExtensionActions(actions: ExtensionAction[]) {
  const { pool } = usePool();

  const value = actions.reduce((sum, action) => sum + (action.value || 0n), 0n);

  const { data, writeContract, isPending } = useWriteContract();

  return {
    data,
    writeContract: () => {
      writeContract({
        address: pool.contracts.extension.txBuilder,
        abi: TxBuilderExtension,
        functionName: "execute",
        args: [actions],
        value,
      });
    },
    isPending,
  };
}
