import { CreateVeAlien } from "@/features/stake/alien/components/CreateVeAlien.tsx";
import { ManageVeAlien } from "@/features/stake/alien/components/ManageVeAlien.tsx";
import { useUserVeAlienInfo } from "@/features/stake/alien/hooks/useUserVeAlienInfo.ts";
import { toDigits } from "@/utils/format.ts";
import {
  Box,
  Card,
  CardBody,
  Container,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";
import { formatDate } from "date-fns";
import { motion } from "framer-motion";
import { formatUnits } from "viem";

const VeAlien = () => {
  const info = useUserVeAlienInfo();

  return (
    <Container maxW="500px" py={8}>
      <Stack spacing={6}>
        <Text textAlign="center" fontSize="1.25rem" fontWeight="bold">
          ALIEN Staking
        </Text>
        <Text textAlign="center">
          Staking ALIEN gives you a boost in Blast Gold based on the stake
          duration and amount. The longer you stake and the more you stake, the
          greater your boost on Blast Gold.{" "}
          <Link
            variant="underline"
            href="https://docs.alien.finance/general/whats-native-on-blast#how-blast-gold-distributed"
            color="secondary.500"
            target="_blank"
          >
            (more detail)
          </Link>
        </Text>
        {info && (
          <Card>
            <CardBody>
              <Box>
                <Text fontWeight="bold" color="secondary.500">
                  Staked Alien
                </Text>
                <Text fontSize="2xl">
                  {toDigits(formatUnits(info.lockedAmount, 18), 2)}
                </Text>
              </Box>
              <Box mt={2}>
                <Text fontWeight="bold" color="secondary.500">
                  Staked Until
                </Text>
                <Text fontSize="2xl">
                  {formatDate(info.unlockDate, "PPpp")}
                </Text>
              </Box>
            </CardBody>
          </Card>
        )}
        {info ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring" }}
          >
            <ManageVeAlien />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring" }}
          >
            <CreateVeAlien />
          </motion.div>
        )}
      </Stack>
    </Container>
  );
};

export const Route = createFileRoute("/stake/alien")({
  component: VeAlien,
});
