import { ActionButtonProps } from "@/features/lending/components/buttons/index.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { Button, Icon, IconButton } from "@chakra-ui/react";
import { useContext } from "react";
import { RiAddLine } from "react-icons/ri";
import { useAccount } from "wagmi";

const BorrowButton = ({
  market,
  onClick,
  isMobile = false,
}: ActionButtonProps) => {
  const { isDisconnected } = useAccount();

  const { hasMarketTx, finalBorrowStats } = useContext(TxBuilderContext);

  const isPaused = market.borrowPaused;

  const canBorrow =
    finalBorrowStats.borrowLimit > finalBorrowStats.borrowBalance;

  return isMobile ? (
    <Button
      w="full"
      variant="primary"
      leftIcon={<Icon as={RiAddLine} />}
      onClick={() => onClick()}
    >
      Borrow
    </Button>
  ) : (
    <IconButton
      aria-label="plus"
      variant="actionIconButton"
      onClick={() => onClick()}
      isDisabled={
        isDisconnected || hasMarketTx(market) || isPaused || !canBorrow
      }
    >
      <RiAddLine />
    </IconButton>
  );
};

export default BorrowButton;
