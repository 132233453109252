import TokenIcon from "@/components/TokenIcon.tsx";
import { Pool } from "@/configs/chains";
import { useAlienPrice } from "@/hooks/useAlienPrice.ts";
import { useAlienRewardsStatus } from "@/hooks/useAlienRewardsStatus.ts";
import { Market } from "@/types.ts";
import { formatPercentage } from "@/utils/format.ts";
import { getMarketUsdPrice } from "@/utils/market.ts";
import { HStack, Text, Tooltip } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { formatUnits } from "viem";

interface SupplyRewardApyProps {
  market: Market;
  pool: Pool;
}

export const SupplyRewardApy = ({ market, pool }: SupplyRewardApyProps) => {
  const { data, status } = useAlienPrice();

  const { data: rewardData } = useAlienRewardsStatus();

  if (status !== "success" || !rewardData) {
    return null;
  }

  const { marketDecimals, totalSupply } = market;

  const alienAmount =
    rewardData.amountByPool[pool.contracts.alien].amountByMarket[market.market];
  if (alienAmount === undefined || alienAmount === 0n) {
    return null;
  }

  const alienAmountNumber = Number(alienAmount) / 1e18;

  const totalSupplyUsdValue = BigNumber(
    formatUnits(totalSupply, marketDecimals),
  )
    .multipliedBy(getMarketUsdPrice(market))
    .toNumber();

  const apy = ((alienAmountNumber * data) / totalSupplyUsdValue) * (365 / 7);

  // console.log("-======", market.marketSymbol);
  // console.log("alien price:", data);
  // console.log("supplied:", formatUnits(totalSupply, marketDecimals));
  // console.log("price:", getMarketUsdPrice(market));
  // console.log("usd value:", totalSupplyUsdValue);
  // console.log(market.marketSymbol, apy);

  return (
    <Tooltip
      placement="top"
      label={<Text>Only the net supply counts for reward calculations.</Text>}
    >
      <HStack spacing={1}>
        <Text variant="subtitle5" color="primary.700">
          {formatPercentage(apy, 0)}
        </Text>
        <TokenIcon symbol="alien" boxSize={4} />
      </HStack>
    </Tooltip>
  );
};
