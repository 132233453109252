import TokenIcon from "@/components/TokenIcon.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { Market, UserMarket } from "@/types.ts";
import {
  formatCollateralFactor,
  formatLiquidationThreshold,
} from "@/utils/format.ts";
import { findWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Box, HStack, Icon, Stack, Tag, Text, Tooltip } from "@chakra-ui/react";
import { RiShieldCheckLine } from "react-icons/ri";
import { formatUnits } from "viem";

interface AssetProps {
  market: Market;
  userMarket?: UserMarket;
  useUnwrappedMarket?: boolean;
}

export function Asset({
  market,
  // userMarket,
  useUnwrappedMarket,
}: AssetProps) {
  const {
    marketSymbol,
    collateralFactor,
    liquidationThreshold,
    marketDecimals,
  } = market;

  const { pool } = usePool();
  // const { isConnected } = useAccount();

  const symbol = useUnwrappedMarket
    ? findWrappedMarket(market, pool).unwrappedSymbol
    : marketSymbol;

  // const getFormattedBalance = () => {
  //   if (!userMarket) return "0";
  //
  //   if (useUnwrappedMarket) {
  //     const unwrappedMarket = getUserUnwrappedMarket(userMarket);
  //     const balance = unwrappedMarket.balance || 0n;
  //     return formatBigInt(balance, unwrappedMarket.decimals, 2);
  //   } else {
  //     return formatBigInt(userMarket.balance, marketDecimals, 2);
  //   }
  // };

  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: 2,
  });

  return (
    <HStack spacing={2}>
      <TokenIcon symbol={symbol} boxSize={8} />
      <Box>
        <HStack spacing={2}>
          <Text variant="subtitle3" color="text.primary">
            {symbol}
          </Text>
          <Tooltip
            placement="top"
            label={
              <Stack spacing={0} p={2}>
                <Text variant="body3" lineHeight="1rem">
                  Collateral Factor: {formatCollateralFactor(collateralFactor)}
                </Text>
                <Text variant="body3" lineHeight="1rem">
                  Liquidation Threshold:{" "}
                  {formatLiquidationThreshold(liquidationThreshold)}
                </Text>
              </Stack>
            }
            hasArrow={true}
          >
            <Tag variant="solid" size="2xs">
              <Text variant="body4">
                {formatCollateralFactor(collateralFactor)} /{" "}
                {formatLiquidationThreshold(liquidationThreshold)}
              </Text>
            </Tag>
          </Tooltip>
          {market.isPToken && (
            <Tooltip
              hasArrow={true}
              placement="top"
              label={
                <Box p={2}>
                  <Text variant="body3" lineHeight="1rem">
                    {/*For supply only. Your asset <br /> will not be borrowable.*/}
                    Rebalancing Thruster v3 LP Collateral Pilot - contact us for
                    waitlist
                  </Text>
                </Box>
              }
            >
              <Box as="span">
                <Icon as={RiShieldCheckLine} color="primary.500" />
              </Box>
            </Tooltip>
          )}
        </HStack>
        {/*{isConnected && (*/}
        {/*  <Text variant="caption1" color="secondary.500">*/}
        {/*    {getFormattedBalance()} in wallet*/}
        {/*  </Text>*/}
        {/*)}*/}
        <Stack spacing={0}>
          <Text variant="caption1" color="secondary.500">
            Total Supply:{" "}
            {formatter.format(
              Number(formatUnits(market.totalSupply, marketDecimals)),
            )}
          </Text>
          <Text variant="caption1" color="secondary.500">
            Liquidity:{" "}
            {formatter.format(
              Number(formatUnits(market.totalCash, marketDecimals)),
            )}
          </Text>
        </Stack>
      </Box>
    </HStack>
  );
}
