import { Pool } from "@/configs/chains.ts";
import { AlienLens } from "@/contracts/AlienLens";
import { Market } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { readContract } from "@wagmi/core";
import { assign, find, keyBy, map } from "lodash-es";
import { useMemo } from "react";
import { useConfig, useSimulateContract } from "wagmi";

export function usePoolMarkets(pool: Pool) {
  const config = useConfig();

  const { data: allMarketMetadataQuery, status: allMarketMetadataQueryStatus } =
    useQuery({
      queryKey: [pool.contracts.alien],
      queryFn: async () => {
        return readContract(config, {
          address: pool.contracts.lens,
          abi: AlienLens,
          functionName: "getAllMarketsMetadata",
          args: [pool.contracts.alien],
        });
      },
    });

  const {
    data: marketStatus,
    isLoading: isLoadingMarketStatus,
    isSuccess: isLoadingMarketStatusSuccess,
  } = useSimulateContract({
    address: pool.contracts.lens,
    abi: AlienLens,
    functionName: "getAllCurrentMarketsStatus",
    args: [pool.contracts.alien],
    account: "0x0000000000000000000000000000000000000000",
  });

  const { markets, marketsByAddress, nativeMarket } = useMemo(() => {
    let markets: Market[] | undefined;
    if (
      allMarketMetadataQueryStatus === "success" &&
      isLoadingMarketStatusSuccess
    ) {
      const marketMetadata = allMarketMetadataQuery;
      const { result: marketStats = [] } = marketStatus;

      markets = map(marketStats, (m, i) => assign({}, m, marketMetadata[i]));
    }

    const marketsByAddress = keyBy(markets, "market");
    const nativeMarket = find(
      markets,
      (m) => m.marketSymbol.toLowerCase() === "weth",
    );
    return {
      markets,
      marketsByAddress,
      nativeMarket,
    };
  }, [
    allMarketMetadataQuery,
    isLoadingMarketStatusSuccess,
    marketStatus,
    allMarketMetadataQueryStatus,
  ]);

  return {
    markets,
    marketsByAddress,
    nativeMarket,
    isLoading:
      allMarketMetadataQueryStatus === "pending" || isLoadingMarketStatus,
    isSuccess:
      allMarketMetadataQueryStatus === "success" &&
      isLoadingMarketStatusSuccess,
  };
}
