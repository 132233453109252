import { TokenInput } from "@/components/TokenInput.tsx";
import { Pool } from "@/configs/chains.ts";
import { Alien } from "@/contracts/Alien.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { useWriteContract } from "@/hooks/useWriteContract.ts";
import { Market } from "@/types.ts";
import { MaxUint256 } from "@/utils/constants.ts";
import { toDigits } from "@/utils/format.ts";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { formatUnits, parseUnits } from "viem";
import { useAccount } from "wagmi";

interface WithdrawButtonProps {
  market: Market;
  pool: Pool;
}

export const WithdrawButton = ({ market, pool }: WithdrawButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawMax, setWithdrawMax] = useState(false);

  const parsedWithdrawAmount = parseUnits(
    withdrawAmount,
    market.marketDecimals,
  );

  const { writeContract, isLoading: isWritingContract } = useWriteContract();

  const { address } = useAccount();

  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const supplyBalance =
    userMarketsByAddress[market.market]?.supplyBalance ?? 0n;

  const onWithdraw = () => {
    if (!address) {
      return;
    }

    const withdrawAmount = withdrawMax ? MaxUint256 : parsedWithdrawAmount;

    writeContract({
      abi: Alien,
      address: pool.contracts.alien,
      functionName: "redeem",
      args: [address, address, market.market, withdrawAmount],
    });
  };

  const onCloseModal = () => {
    onClose();
    setWithdrawAmount("");
  };

  const error = ((): string | null => {
    if (
      parsedWithdrawAmount === 0n ||
      withdrawAmount === "" ||
      parsedWithdrawAmount > supplyBalance
    ) {
      return "Invalid amount";
    }
    return null;
  })();

  return (
    <>
      <Button
        variant="ghost"
        onClick={onOpen}
        isDisabled={supplyBalance === 0n}
        width="full"
        color="primary.500"
        _disabled={{
          color: "#4C5354",
        }}
        px={0}
      >
        Withdraw
      </Button>
      <Modal isOpen={isOpen} onClose={() => onCloseModal()} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Withdraw {market.marketSymbol}</ModalHeader>
          <ModalBody>
            <TokenInput
              symbol={market.marketSymbol}
              token={market.market}
              inputName="amount"
              value={withdrawAmount}
              onChange={(e) => {
                setWithdrawAmount(e.target.value);
                setWithdrawMax(false);
              }}
              showMaxButton={true}
              onMax={() => {
                setWithdrawAmount(
                  formatUnits(supplyBalance, market.marketDecimals),
                );
                setWithdrawMax(true);
              }}
              balanceValue={toDigits(
                formatUnits(supplyBalance, market.marketDecimals),
                2,
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              w="full"
              variant="primary"
              onClick={() => onWithdraw()}
              isLoading={isWritingContract}
              isDisabled={!!error}
            >
              {error ?? "Withdraw"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
