import { chains, chainTransportMap } from "@/configs/chains.ts";
import { Chain } from "viem";
import { createConfig } from "wagmi";
import { injected, walletConnect } from "wagmi/connectors";

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

export const projectId = "60087164c2b274385d70967131ad5cdb";

export const config = createConfig({
  chains: chains as [Chain, ...Chain[]],
  transports: chainTransportMap,
  connectors: [
    walletConnect({
      projectId,
      showQrModal: false,
    }),
    injected({ shimDisconnect: true }),
  ],
});
