import {
  closeExactFieldAtom,
  longMarketAtom,
  openExactFieldAtom,
  shortMarketAtom,
} from "@/features/leverage/states/states";
import { tryParseUnits } from "@/features/leverage/utils/tryParseUnits";
import { useLesserSide } from "@/hooks/useLevXClose";
import { useSwapQuote } from "@/hooks/useSwapQuote";
import { Market } from "@/types";
import { getBalanceValue } from "@/utils/market";
import { useAtomValue } from "jotai";

export const useLevXOpenAmounts = () => {
  const longMarket = useAtomValue(longMarketAtom);
  const shortMarket = useAtomValue(shortMarketAtom);
  const exactField = useAtomValue(openExactFieldAtom);
  const exactMarket = exactField.side === "long" ? longMarket : shortMarket;
  const exactAmount =
    exactMarket && tryParseUnits(exactField.value, exactMarket.marketDecimals);
  const exactSide = exactField.side;

  return useLevXAmounts({
    exactSide,
    exactAmount,
    longMarket,
    shortMarket,
  });
};

export const useLevXCloseAmounts = () => {
  const longMarket = useAtomValue(longMarketAtom);
  const shortMarket = useAtomValue(shortMarketAtom);
  const exactField = useAtomValue(closeExactFieldAtom);

  const { exactSide, exactAmount } = useLesserSide({
    exactSide: exactField.side,
    exactAmount: exactField.value,
    longMarket,
    shortMarket,
  });

  return useLevXAmounts({
    exactSide,
    exactAmount,
    longMarket,
    shortMarket,
  });
};

export const useLevXAmounts = ({
  exactSide,
  exactAmount,
  longMarket,
  shortMarket,
}: {
  exactSide: "long" | "short" | undefined;
  exactAmount: bigint | undefined;
  longMarket: Market | undefined;
  shortMarket: Market | undefined;
}) => {
  const exactMarket =
    exactSide === undefined
      ? undefined
      : exactSide === "long"
        ? longMarket
        : shortMarket;
  const exactValue =
    exactMarket && exactAmount !== undefined
      ? getBalanceValue(exactAmount, exactMarket.marketPrice)
      : undefined;

  const estMarket =
    exactSide === undefined
      ? undefined
      : exactSide === "long"
        ? shortMarket
        : longMarket;

  const {
    amount: estAmount,
    error: estError,
    isLoading: isEstAmountLoading,
    overallSwapFee,
  } = useSwapQuote({
    exactSide:
      exactSide === undefined ? undefined : exactSide === "long" ? "out" : "in",
    exactAmount,
    tokenIn: shortMarket?.market,
    tokenInDecimals: shortMarket?.marketDecimals,
    tokenOut: longMarket?.market,
    tokenOutDecimals: longMarket?.marketDecimals,
  });

  const estValue =
    estMarket && estAmount !== undefined
      ? getBalanceValue(estAmount, estMarket.marketPrice)
      : undefined;

  const longAmount = exactSide === "long" ? exactAmount : estAmount;
  const shortAmount = exactSide === "short" ? exactAmount : estAmount;
  const longValue = exactSide === "long" ? exactValue : estValue;
  const shortValue = exactSide === "short" ? exactValue : estValue;

  return {
    estMarket,
    estAmount,
    estValue,
    estError,
    isEstAmountLoading,
    exactMarket,
    exactAmount,
    exactValue,
    longMarket,
    longAmount,
    longValue,
    shortMarket,
    shortAmount,
    shortValue,
    swapFee: overallSwapFee,
  };
};
