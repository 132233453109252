import { WrappedMarketType } from "@/configs/chains";
import { usePool } from "@/hooks/usePool.ts";
import { MaxUint256 } from "@/utils/constants.ts";
import { find } from "lodash-es";
import { Address } from "viem";
import { useAccount, useBalance } from "wagmi";

export type WrappedMarket = {
  market: Address;
  unwrappedMarket: Address;
  unwrappedSymbol: string;
  type: WrappedMarketType;
  balance: bigint;
  decimals: number;
  allowance: bigint;
};

type UserWrappedMarketsQuery = {
  data: Record<Address, WrappedMarket> | undefined;
  isSuccess: boolean;
  isLoading: boolean;
};

export function useUserWrappedMarkets(): UserWrappedMarketsQuery {
  const { pool } = usePool();
  const { address } = useAccount();

  const unwrappedNativeMarket = find(
    pool.wrappedMarkets,
    (wm) => wm.type === "wETH",
  );

  // const unwrappedErc20Markets = filter(
  //   protocol.wrappedMarkets,
  //   (wm) => wm.type !== "wETH",
  // );

  const {
    data: nativeBalance,
    isLoading: isLoadingBalance,
    isSuccess: isBalanceSuccess,
  } = useBalance({
    address,
  });

  // const balanceCalls = map(unwrappedErc20Markets, (m) => ({
  //   address: m.unwrappedMarket,
  //   abi: erc20Abi,
  //   functionName: "balanceOf",
  //   args: [address || "0x"],
  // }));
  //
  // const decimalsCalls = map(unwrappedErc20Markets, (m) => ({
  //   address: m.unwrappedMarket,
  //   abi: erc20Abi,
  //   functionName: "decimals",
  // }));
  //
  // const allowanceCalls = map(unwrappedErc20Markets, (m) => ({
  //   address: m.unwrappedMarket,
  //   abi: erc20Abi,
  //   functionName: "allowance",
  //   args: [address || "0x", protocol.contracts.extension.txBuilder],
  // }));
  //
  // const {
  //   data: results,
  //   isSuccess: isContractReadsSuccess,
  //   isLoading: isContractReadsLoading,
  // } = useReadContracts({
  //   contracts: [...balanceCalls, ...decimalsCalls, ...allowanceCalls],
  // });
  //
  // const isLoading = isLoadingBalance || isContractReadsLoading;
  //
  // const isSuccess = isBalanceSuccess && isContractReadsSuccess;
  //
  // if (isSuccess) {
  //   const data = chain(results)
  //     .chunk(3)
  //     .map(([balanceResult, decimalsResult, allowanceResult], index) => {
  //       return {
  //         market: unwrappedErc20Markets[index].market,
  //         unwrappedMarket: unwrappedErc20Markets[index].unwrappedMarket,
  //         unwrappedSymbol: unwrappedErc20Markets[index].unwrappedSymbol,
  //         type: unwrappedErc20Markets[index].type,
  //         balance: balanceResult.result as bigint,
  //         decimals: decimalsResult.result as number,
  //         allowance: allowanceResult.result as bigint,
  //       };
  //     })
  //     .concat(
  //       unwrappedNativeMarket
  //         ? {
  //             market: unwrappedNativeMarket.market,
  //             unwrappedMarket: unwrappedNativeMarket.unwrappedMarket,
  //             unwrappedSymbol: unwrappedNativeMarket.unwrappedSymbol,
  //             type: unwrappedNativeMarket.type,
  //             balance: (nativeBalance?.value || 0n) as bigint,
  //             decimals: 18,
  //             allowance: MaxUint256,
  //           }
  //         : [],
  //     )
  //     .keyBy("market")
  //     .value();
  //
  //   return { data, isSuccess, isLoading };
  // }

  const isSuccess = isBalanceSuccess;
  const isLoading = isLoadingBalance;

  if (!unwrappedNativeMarket) {
    return {
      data: undefined,
      isSuccess: true,
      isLoading: false,
    };
  }

  if (isSuccess) {
    return {
      data: {
        [unwrappedNativeMarket.market]: {
          market: unwrappedNativeMarket.market,
          unwrappedMarket: unwrappedNativeMarket.unwrappedMarket,
          unwrappedSymbol: unwrappedNativeMarket.unwrappedSymbol,
          type: unwrappedNativeMarket.type,
          balance: nativeBalance?.value || 0n,
          decimals: 18,
          allowance: MaxUint256,
        },
      },
      isSuccess,
      isLoading,
    };
  }

  return { data: undefined, isSuccess, isLoading };
}
