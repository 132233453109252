import { Pool } from "@/configs/chains";
import { Market, UserMarket } from "@/types";
import { find } from "lodash-es";
import { isAddressEqual } from "viem";

export function safeFindWrappedMarket(market: Market, pool: Pool) {
  return find(pool.wrappedMarkets, (wrappedMarket) =>
    isAddressEqual(market.market, wrappedMarket.market),
  );
}

export function findWrappedMarket(market: Market, pool: Pool) {
  const wrappedMarket = safeFindWrappedMarket(market, pool);
  if (!wrappedMarket) {
    throw Error("no wrapped market found");
  }
  return wrappedMarket;
}

export function isWrappedMarket(market: Market, pool: Pool) {
  return !!safeFindWrappedMarket(market, pool);
}

export function getUserUnwrappedMarket(userMarket: UserMarket) {
  const { unwrappedMarket } = userMarket;

  if (!unwrappedMarket) {
    throw Error(`no unwrapped market found: ${userMarket.market}`);
  }

  return unwrappedMarket;
}
