import StatBanner from "@/components/StatBanner.tsx";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { useContext } from "react";

const LendingStatBanner = () => {
  const { finalBorrowStats } = useContext(TxBuilderContext);

  return <StatBanner newUserStats={finalBorrowStats} />;
};

export default LendingStatBanner;
