import Table from "@/components/Table.tsx";
import { Pool } from "@/configs/chains.ts";
import { Asset } from "@/features/earn/components/Asset.tsx";
import { SupplyAndRewardApy } from "@/features/earn/components/SupplyAndRewardApy.tsx";
import { SupplyButton } from "@/features/earn/components/SupplyButton.tsx";
import { WithdrawButton } from "@/features/earn/components/WithdrawButton.tsx";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Market } from "@/types.ts";
import { YesAddress } from "@/utils/constants.ts";
import { HStack, Stack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { filter } from "lodash-es";
import { useMemo } from "react";
import { isAddressEqual } from "viem";

const columnHelper = createColumnHelper<Market>();

interface SimpleMarketTableProps {
  pool: Pool;
}

export const SimpleMarketTable = ({ pool }: SimpleMarketTableProps) => {
  const { markets } = usePoolMarkets(pool);
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const filteredMarkets = useMemo(
    () =>
      filter(markets, (m) => {
        if (isAddressEqual(m.market, YesAddress)) {
          const yesUserMarket = userMarketsByAddress[m.market];
          if (!yesUserMarket) {
            return false;
          }
          return (
            yesUserMarket.supplyBalance !== 0n ||
            yesUserMarket.borrowBalance !== 0n
          );
        }
        return true;
      }),
    [markets, userMarketsByAddress],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        header: "Asset",
        cell: ({ row: { original: market } }) => <Asset market={market} />,
      }),
      columnHelper.accessor("supplyRate", {
        header: "Net APY",
        cell: ({ row: { original: market } }) => {
          return (
            <Stack alignItems="flex-end" spacing={1}>
              <SupplyAndRewardApy pool={pool} market={market} />
              <HStack justifyContent="flex-end" h="30px">
                <SupplyButton pool={pool} market={market} />
                <WithdrawButton pool={pool} market={market} />
              </HStack>
            </Stack>
          );
        },
        meta: {
          isNumeric: true,
        },
      }),
    ];
  }, [pool]);

  return <Table columns={columns} data={filteredMarkets || []} px={1} />;
};
