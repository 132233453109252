import Table from "@/components/Table.tsx";
import TokenIcon from "@/components/TokenIcon.tsx";
import useMediaQuery from "@/hooks/useMediaQuery.ts";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { Market } from "@/types.ts";
import { formatRate, toDigits } from "@/utils/format.ts";
import {
  getMarketBorrowUsdValue,
  getMarketSupplyUsdValue,
} from "@/utils/market.ts";
import {
  aTokenToUnderlying,
  underlyingPriceToUsd,
  underlyingToUsd,
} from "@/utils/marketStatus.ts";
import {
  Box,
  FormLabel,
  HStack,
  Skeleton,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import BigNumber from "bignumber.js";
import { useMemo, useState } from "react";
import { formatUnits } from "viem";

// type PieData = {
//   name: string;
//   value: number;
//   percentage: string;
// };

// interface MarketOverviewProps {
//   network: Protocol;
// }

const MarketOverview = () => {
  const { isDesktop } = useMediaQuery();
  const columnHelper = createColumnHelper<Market>();
  const [isDisplayInUsd, setIsDisplayInUsd] = useState<boolean>(true);

  const { pool } = usePool();
  const { markets, isLoading } = usePoolMarkets(pool);

  const mobileColumns = useMemo(
    () => [
      columnHelper.display({
        id: "asset",
        header: "Market",
        cell: ({
          row: {
            original: { marketSymbol },
          },
        }) => {
          if (marketSymbol === "WETH") {
            marketSymbol = "ETH";
          }
          return (
            <HStack spacing={2}>
              <TokenIcon symbol={marketSymbol} boxSize={8} />
              <Text variant="body3" color="text.primary">
                {marketSymbol}
              </Text>
            </HStack>
          );
        },
        meta: {
          isSticky: true,
          maxWidth: "250px",
        },
      }),
      columnHelper.accessor("totalCash", {
        header: "Liquidity",
        cell: ({
          row: {
            original: { totalCash, marketDecimals, marketPrice },
          },
        }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${BigNumber(
                  underlyingToUsd(totalCash, marketPrice, marketDecimals),
                ).toFormat(2)}`
              : BigNumber(formatUnits(totalCash, marketDecimals)).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("totalSupply", {
        header: "Supply",
        cell: ({ row: { original: market } }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${toDigits(getMarketSupplyUsdValue(market), 2)}`
              : BigNumber(
                  aTokenToUnderlying(
                    market.totalSupply,
                    market.exchangeRate,
                    market.marketDecimals,
                  ),
                ).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("supplyRate", {
        header: "Supply APY",
        cell: ({ getValue }) => (
          <Stack spacing={1} alignItems="flex-end">
            <Text variant="subtitle4" color="text.primary">
              {formatRate(getValue(), 2)}
            </Text>
          </Stack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("totalBorrow", {
        header: "Borrow",
        cell: ({
          row: {
            original: market,
            original: { marketDecimals, totalBorrow },
          },
        }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${toDigits(getMarketBorrowUsdValue(market), 2)}`
              : BigNumber(formatUnits(totalBorrow, marketDecimals)).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("borrowRate", {
        header: "Borrow APY",
        cell: ({ getValue }) => (
          <Stack spacing={1} alignItems="flex-end">
            <Text variant="subtitle4" color="text.primary">
              {formatRate(getValue(), 2)}
            </Text>
          </Stack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [columnHelper, isDisplayInUsd],
  );

  const desktopColumns = useMemo(() => {
    return [
      columnHelper.display({
        id: "asset",
        header: "Market",
        cell: ({
          row: {
            original: { marketSymbol, marketName },
          },
        }) => {
          if (marketSymbol === "WETH") {
            marketSymbol = "ETH";
            marketName = "Ethereum";
          }
          return (
            <HStack spacing={2}>
              <TokenIcon symbol={marketSymbol} boxSize={8} />
              <Box>
                <Text variant="subtitle3" color="text.primary">
                  {marketName}
                </Text>
                <Text variant="body3" color="secondary.500">
                  {marketSymbol}
                </Text>
              </Box>
            </HStack>
          );
        },
      }),
      columnHelper.accessor("marketPrice", {
        header: "Price",
        cell: ({
          row: {
            original: { marketPrice, marketDecimals },
          },
        }) => (
          <Text variant="subtitle4" color="text.primary">
            $
            {BigNumber(
              underlyingPriceToUsd(marketPrice, marketDecimals),
            ).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("totalCash", {
        header: "Liquidity",
        cell: ({
          row: {
            original: { totalCash, marketDecimals, marketPrice },
          },
        }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${BigNumber(
                  underlyingToUsd(totalCash, marketPrice, marketDecimals),
                ).toFormat(2)}`
              : BigNumber(formatUnits(totalCash, marketDecimals)).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("totalSupply", {
        header: "Supply",
        cell: ({ row: { original: market } }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${toDigits(getMarketSupplyUsdValue(market), 2)}`
              : BigNumber(
                  aTokenToUnderlying(
                    market.totalSupply,
                    market.exchangeRate,
                    market.marketDecimals,
                  ),
                ).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("supplyRate", {
        header: "Supply APY",
        cell: ({ getValue }) => (
          <Stack spacing={1} alignItems="flex-end">
            <Text variant="subtitle4" color="text.primary">
              {formatRate(getValue(), 2)}
            </Text>
          </Stack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("totalBorrow", {
        header: "Borrow",
        cell: ({
          row: {
            original: market,
            original: { marketDecimals, totalBorrow },
          },
        }) => (
          <Text variant="subtitle4" color="text.primary">
            {isDisplayInUsd
              ? `$${toDigits(getMarketBorrowUsdValue(market), 2)}`
              : BigNumber(formatUnits(totalBorrow, marketDecimals)).toFormat(2)}
          </Text>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("borrowRate", {
        header: "Borrow APY",
        cell: ({ getValue }) => (
          <Stack spacing={1} alignItems="flex-end">
            <Text variant="subtitle4" color="text.primary">
              {formatRate(getValue(), 2)}
            </Text>
          </Stack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
    ];
  }, [columnHelper, isDisplayInUsd]);

  return (
    <Box px={9}>
      <Stack spacing={6}>
        <HStack justifyContent="space-between">
          <Text variant="subtitle3" color="text.primary">
            Market
          </Text>
          <HStack spacing={2} alignItems="start">
            <Switch
              size="sm"
              id="display-usd"
              isChecked={isDisplayInUsd}
              onChange={(event) => setIsDisplayInUsd(event.target.checked)}
            />
            <FormLabel htmlFor="display-usd">
              <Text variant="subtitle5" color="text.secondary">
                Display in USD Value
              </Text>
            </FormLabel>
          </HStack>
        </HStack>
        {isLoading ? (
          <Skeleton h="18.75rem" />
        ) : (
          <Table
            columns={isDesktop ? desktopColumns : mobileColumns}
            data={markets ?? []}
            // onRowClick={({ original: { market } }) =>
            //   navigate({
            //     to: `${network.chain.name.toLocaleLowerCase()}/market/${market}`,
            //   })
            // }
          />
        )}
      </Stack>
    </Box>
  );
};

export default MarketOverview;
