import { tryParseUnits } from "@/features/leverage/utils/tryParseUnits";
import {
  IncreasePositionSubAction,
  newSupplyAction,
  newSupplyNativeTokenAction,
  newUniswapV3ExactInputAction,
  newUniswapV3ExactOutputAction,
} from "@/hooks/uniswapExtension/useSendUniswapExtensionActions.ts";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserStats } from "@/hooks/useUserStats.ts";
import { ExtensionAction, Market } from "@/types.ts";
import { Erc20Token, Token } from "@/types/token.ts";
import { TokenAmount } from "@/types/tokenAmount.ts";
import {
  getBalanceValue,
  getMarketBorrowLimit,
  getUnderlyingBorrowLimit,
} from "@/utils/market.ts";
import { getUserUnwrappedMarket } from "@/utils/wrappedMarket";
import BigNumber from "bignumber.js";
import { addMinutes, getUnixTime } from "date-fns";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { useSwapQuote } from "./useSwapQuote";
import { useUserPoolMarkets } from "./useUserPoolMarkets";

type UseLevXParams = {
  exactSide: "long" | "short";
  longMarket: Market | undefined;
  shortMarket: Market | undefined;
  exactAmount: string;
  /** for a slippage tolerance of 5%, pass in 0.05 */
  slippage: number;
  collateralTokenAmount?: TokenAmount<Token>;
};

export type UseLevXResult =
  | {
      maxLeverage: number;
      leverage: number;
      borrowLimitLeft: bigint;
      getExtensionActions: (() => ExtensionAction[]) | undefined;
      nextApprovalToken: Erc20Token | null;
      getExactAmountByLeverage(leverage: number): string;
      error: string | null;
    }
  | undefined;

export const useLevX = ({
  longMarket,
  shortMarket,
  exactAmount: exactAmountStr,
  exactSide,
  collateralTokenAmount,
  slippage,
}: UseLevXParams): UseLevXResult => {
  const { isDisconnected } = useAccount();
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);
  const { marketsByAddress } = usePoolMarkets(pool);
  const { borrowLimit, borrowBalance } = useUserStats();

  const exactSideMarket = exactSide === "long" ? longMarket : shortMarket;
  const exactAmount =
    exactSideMarket &&
    tryParseUnits(exactAmountStr, exactSideMarket.marketDecimals);

  const { amount: estAmount, encodedPath } = useSwapQuote({
    exactSide: exactSide === "long" ? "out" : "in",
    exactAmount,
    tokenIn: shortMarket?.market,
    tokenInDecimals: shortMarket?.marketDecimals,
    tokenOut: longMarket?.market,
    tokenOutDecimals: longMarket?.marketDecimals,
  });

  if (isDisconnected) {
    return undefined;
  }

  if (!longMarket || !shortMarket) {
    return undefined;
  }

  if (exactAmount === undefined || exactSideMarket === undefined) {
    return undefined;
  }

  let extraBorrowLimit = 0n;
  if (collateralTokenAmount) {
    const tokenAddress = collateralTokenAmount.token.isNative
      ? collateralTokenAmount.token.wrapped.address
      : collateralTokenAmount.token.address;
    const collateralMarket = marketsByAddress[tokenAddress];
    extraBorrowLimit = getMarketBorrowLimit(
      collateralMarket,
      collateralTokenAmount.amount,
    );
  }

  const collateralFactor = longMarket.collateralFactor / 10000;
  const borrowLimitLeft = borrowLimit + extraBorrowLimit - borrowBalance;

  const leverage = BigNumber(
    getBalanceValue(exactAmount, exactSideMarket.marketPrice).toString(),
  )
    .dividedBy(BigNumber(borrowLimitLeft.toString()))
    .toNumber();

  const getExtensionActions = (() => {
    if (estAmount === undefined || encodedPath === undefined) {
      return;
    }

    return () => {
      const actions: ExtensionAction[] = [];

      if (collateralTokenAmount) {
        if (collateralTokenAmount.token.isNative) {
          actions.push(
            newSupplyNativeTokenAction(collateralTokenAmount.amount),
          );
        } else {
          actions.push(
            newSupplyAction(
              collateralTokenAmount.token.address,
              collateralTokenAmount.amount,
            ),
          );
        }
      }

      const slippageAmount =
        (estAmount * BigInt(Math.floor(slippage * 1e6))) / BigInt(1e6);
      const deadline = getUnixTime(addMinutes(new Date(), 3));
      if (exactSide === "long") {
        actions.push(
          newUniswapV3ExactOutputAction({
            outAsset: longMarket.market,
            outAmount: exactAmount,
            inAsset: shortMarket.market,
            maxInAmount: estAmount + slippageAmount,
            path: encodedPath,
            subAction: IncreasePositionSubAction,
            deadline,
          }),
        );
      } else {
        actions.push(
          newUniswapV3ExactInputAction({
            inAsset: shortMarket.market,
            inAmount: exactAmount,
            outAsset: longMarket.market,
            minOutAmount: estAmount - slippageAmount,
            path: encodedPath,
            subAction: IncreasePositionSubAction,
            deadline,
          }),
        );
      }

      return actions;
    };
  })();

  // const { newBorrowLimit, borrowBalance } = usePendingBorrowLimit();

  // const longAmount = exactSide === "long" ? exactAmount : estAmount;

  // const longBorrowLimit =
  //   longMarket && longAmount !== undefined
  //     ? getMarketBorrowLimit(longMarket, longAmount)
  //     : undefined;

  const tokenUsages: TokenAmount<Erc20Token>[] = [];
  if (
    collateralTokenAmount &&
    collateralTokenAmount.token instanceof Erc20Token
  ) {
    tokenUsages.push(collateralTokenAmount as TokenAmount<Erc20Token>);
  }

  const nextApprovalToken = (() => {
    for (const tokenUsage of tokenUsages) {
      const { token, amount } = tokenUsage;
      const userMarket = userMarketsByAddress[token.address];
      if (userMarket.allowanceToAlien < amount) {
        return token;
      }
    }
    return null;
  })();

  const error: string | null = (() => {
    if (exactAmount === undefined || exactAmount < 0n) {
      return "Invalid amount";
    }

    if (longMarket.market === shortMarket.market) {
      return "Longing and shorting same market";
    }

    if (collateralTokenAmount?.token.isNative) {
      const userMarket =
        userMarketsByAddress[collateralTokenAmount.token.wrapped.address];
      const unwrappedMarket = getUserUnwrappedMarket(userMarket);

      if (unwrappedMarket.balance < collateralTokenAmount.amount) {
        return `Insufficient ${collateralTokenAmount.token.symbol} Balance`;
      }
    }

    for (const tokenUsage of tokenUsages) {
      const { token, amount } = tokenUsage;
      const userMarket = userMarketsByAddress[token.address];
      if (userMarket.balance < amount) {
        return `Insufficient ${token.symbol} Balance`;
      }
    }

    // // TODO: This calculation is wrong
    // if (
    //   shortValue &&
    //   longBorrowLimit &&
    //   shortValue + borrowBalance > newBorrowLimit + longBorrowLimit
    // ) {
    //   return "Insufficient Collateral";
    // }

    return null;
  })();

  const getExactAmountByLeverage = (leverage: number) => {
    const leveragedLimit = BigInt(
      BigNumber(borrowLimitLeft.toString())
        .multipliedBy(leverage)
        .toFixed(0, BigNumber.ROUND_FLOOR),
    );
    const exactAmount = getUnderlyingBorrowLimit(
      exactSideMarket,
      leveragedLimit,
    );
    return formatUnits(exactAmount, exactSideMarket.marketDecimals);
  };

  return {
    maxLeverage: 1 / (1 - collateralFactor),
    leverage,
    borrowLimitLeft,
    getExtensionActions,
    nextApprovalToken,
    getExactAmountByLeverage,
    error,
  };
};
