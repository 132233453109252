import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const nav = defineStyle({
  color: "text.secondary",
  fontWeight: 600,
  lineHeight: "1.375rem",
  fontSize: "0.875rem",
  borderRadius: "0.375rem",
  _hover: {
    color: "text.primary",
    // bgColor: '#EAECEF',
  },
  _active: {
    color: "text.primary",
    // bgColor: '#EAECEF',
  },
});

const primary = defineStyle({
  color: "black",
  bgColor: "ui.circledBg",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  _disabled: {
    cursor: "not-allowed",
    color: "text.disabled",
    bgColor: "ui.disabled",
  },
  _hover: {
    _disabled: {
      bgColor: "ui.disabled",
    },
  },
});

const secondary = defineStyle({
  color: "white",
  bgColor: "ui.disabled",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  _disabled: {
    cursor: "not-allowed",
    color: "text.disabled",
    bgColor: "ui.disabled",
  },
  _hover: {
    _disabled: {
      bgColor: "ui.disabled",
    },
  },
});

const outline = defineStyle({
  bg: "bg",
  borderWidth: "1px",
  borderColor: "ui.line",
  color: "primary.500",
  _hover: {
    bg: "",
  },
  _active: {
    bg: "",
  },
});

const solid = defineStyle({
  bgColor: "secondary.100",
  color: "secondary.500",
  fontWeight: 600,
  fontSize: "md",
  lineHeight: "1.5rem",
  py: 1,
});

const ghost = defineStyle({
  color: "secondary.500",
  fontWeight: 600,
  fontSize: "0.875rem",
  lineHeight: "1.375rem",
  _hover: {
    bgColor: "bg.overall",
  },
  _active: {
    bgColor: "black",
  },
});

const actionIconButton = defineStyle({
  minW: "2rem",
  minH: "2rem",
  w: "2rem",
  h: "2rem",
  color: "black",
  bgColor: "ui.circledBg",
  borderRadius: "full",
  _disabled: {
    color: "disabled.700",
    bgColor: "ui.disabled",
    pointerEvents: "none",
  },
});

const actionItemButton = defineStyle({
  minW: "0.75rem",
  minH: "0.75rem",
  w: "0.75rem",
  h: "0.75rem",
  color: "secondary.500",
  bgColor: "transparent",
});

export default defineStyleConfig({
  variants: {
    nav,
    solid,
    primary,
    secondary,
    outline,
    ghost,
    actionIconButton,
    actionItemButton,
  },
});
