import { PoolContext } from "@/providers/PoolProvider.tsx";
import { useContext } from "react";

export const usePool = () => {
  const { pool, pools, setPool } = useContext(PoolContext);
  if (pool === undefined) {
    throw new Error("pool is undefined");
  }
  return {
    pool,
    pools,
    changePool: setPool,
  };
};
