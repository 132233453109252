import { statAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(statAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: "secondary.500",
    fontSize: "md",
    lineHeight: "1.5rem",
    fontWeight: 600,
  },
  number: {
    color: "text.primary",
    fontSize: "2xl",
    lineHeight: "2.125",
    fontWeight: 700,
  },
});

const Stat = defineMultiStyleConfig({
  sizes: {
    xs: baseStyle,
    md: baseStyle,
  },
  baseStyle,
});

export default Stat;
