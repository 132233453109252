import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  th: {
    color: "text.secondary",
    fontWeight: 600,
    fontSize: "sm",
    lineHeight: "1.125rem",
    "&[data-is-numeric=true]": {
      textAlign: "end",
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
