import { QueryClient } from "@tanstack/react-query";

export function invalidateWagmiQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(
    { queryKey: ["readContract"] },
    {
      cancelRefetch: false,
    },
  );
  queryClient.invalidateQueries(
    { queryKey: ["readContracts"] },
    {
      cancelRefetch: false,
    },
  );
  queryClient.invalidateQueries(
    { queryKey: ["balance"] },
    {
      cancelRefetch: false,
    },
  );
  queryClient.invalidateQueries(
    { queryKey: ["simulateContract"] },
    {
      cancelRefetch: false,
    },
  );
}
