import TokenIcon from "@/components/TokenIcon";
import { usePool } from "@/hooks/usePool.ts";
import { Tx } from "@/providers/TxBuilder";
import { formatBigInt } from "@/utils/format";
import { getBalanceValue } from "@/utils/market";
import { findWrappedMarket } from "@/utils/wrappedMarket";
import { ChakraProps, HStack, IconButton, Stack, Text } from "@chakra-ui/react";
import { capitalize } from "lodash-es";
import { AiOutlineClose } from "react-icons/ai";

interface ActionItemProps extends ChakraProps {
  tx: Tx;
  onClick: (tx: Tx) => void;
  onRemove?: (tx: Tx) => void;
  isMobile?: boolean;
  errorMessage?: string;
}

const ActionItem = ({
  tx,
  onClick,
  onRemove,
  isMobile = false,
  errorMessage = "",
  ...props
}: ActionItemProps) => {
  const { pool } = usePool();
  const { action, amount, market, useUnwrappedMarket } = tx;
  const { marketSymbol, marketDecimals, marketPrice } = market;

  let symbol = marketSymbol;
  let decimals = marketDecimals;

  if (useUnwrappedMarket) {
    const wrappedMarket = findWrappedMarket(market, pool);
    symbol = wrappedMarket.unwrappedSymbol;
    decimals = wrappedMarket.unwrappedDecimals;
  }

  const usdValue = getBalanceValue(amount, marketPrice);

  const isInvalid = errorMessage !== "";

  return (
    <HStack
      bgColor={isInvalid ? "background.error" : "background.primary"}
      cursor="pointer"
      _hover={
        isMobile
          ? undefined
          : {
              bgColor: "bg.overall",
            }
      }
      data-component="action-item"
      justifyContent="space-between"
      onClick={() => onClick(tx)}
      {...props}
    >
      <HStack>
        <TokenIcon symbol={symbol} boxSize={8} />
        <Stack>
          <Text variant="subtitle2" color="white">
            {capitalize(action)} {symbol}
          </Text>
          {errorMessage && (
            <Text variant="subtitle4" color="red.500">
              {errorMessage}
            </Text>
          )}
        </Stack>
      </HStack>
      <HStack>
        <Stack spacing={0}>
          <Text textAlign="right" variant="subtitle3" color="text.primary">
            {formatBigInt(amount, decimals, 4)}
          </Text>
          <Text textAlign="right" variant="subtitle4" color="secondary.500">
            ${formatBigInt(usdValue, 18, 2)}
          </Text>
        </Stack>
        {onRemove && (
          <IconButton
            sx={
              isMobile
                ? undefined
                : {
                    visibility: "hidden",
                    '[data-component="action-item"]:hover &': {
                      visibility: "unset",
                    },
                  }
            }
            variant="actionItemButton"
            aria-label="remove"
            borderRadius="full"
            onClick={(event) => {
              event.stopPropagation();
              onRemove(tx);
            }}
          >
            <AiOutlineClose />
          </IconButton>
        )}
      </HStack>
    </HStack>
  );
};

export default ActionItem;
