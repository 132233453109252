import { defineStyleConfig } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";

// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadowColor = cssVar("popper-arrow-shadow-color");

const baseStyle = {
  bgColor: "background.dark",
  borderRadius: "0.375rem",
  [$arrowBg.variable]: "colors.background.dark",
  [$arrowShadowColor.variable]: "colors.background.dark",
};

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle });

export default tooltipTheme;
