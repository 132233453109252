import { TxResult } from "@/providers/TxBuilder";

export function isValidTxResult(txResult: TxResult) {
  if (txResult.error) {
    return false;
  }

  const percentage = txResult.borrowStats.borrowPct;
  if (percentage < 0 || percentage > 1) {
    return false;
  }

  return true;
}
