import TokenIcon from "@/components/TokenIcon.tsx";
import { TokenInput } from "@/components/TokenInput.tsx";
import { VeAlien } from "@/contracts/veAlien.ts";
import {
  stakeDurationMap,
  StakeDurationOption,
  veAlienAddress,
} from "@/features/stake/alien/config.ts";
import { useUserVeAlienInfo } from "@/features/stake/alien/hooks/useUserVeAlienInfo.ts";
import { getContractUnlockDate } from "@/features/stake/alien/utils.ts";
import { AlienAddress } from "@/utils/constants.ts";
import { commify } from "@/utils/format.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import {
  Button,
  Card,
  CardBody,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  add,
  differenceInSeconds,
  formatDate,
  isAfter,
  isBefore,
} from "date-fns";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { formatUnits, parseUnits } from "viem";
import {
  useAccount,
  useBalance,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

export const ManageVeAlien = () => {
  const queryClient = useQueryClient();

  const userVeAlienInfo = useUserVeAlienInfo();
  const currentLockedAmount = userVeAlienInfo
    ? userVeAlienInfo.lockedAmount
    : 0n;
  const currentUnlockDate = userVeAlienInfo
    ? userVeAlienInfo.unlockDate
    : new Date();

  const [increaseAmount, setIncreaseAmount] = useState("");
  const [duration, setDuration] = useState<StakeDurationOption>("4y");
  const [unlockDate, setUnlockDate] = useState(
    add(new Date(), stakeDurationMap[duration]),
  );

  const isExpired =
    userVeAlienInfo && isAfter(new Date(), userVeAlienInfo.unlockDate);

  const newAmount = parseUnits(increaseAmount || "0", 18) + currentLockedAmount;

  const { address } = useAccount();

  const { data: alienBalance } = useBalance({
    token: AlienAddress,
    address,
  });

  const { data, writeContract, isPending: isTxSending } = useWriteContract();
  const { isSuccess: isTxSuccess, isLoading: isTxConfirming } =
    useWaitForTransactionReceipt({
      hash: data,
    });

  const renderDurationButton = (option: StakeDurationOption, text: string) => {
    return (
      <Button
        variant={duration === option ? "primary" : "outline"}
        onClick={() => {
          setDuration(option);
          setUnlockDate(add(new Date(), stakeDurationMap[option]));
        }}
        fontSize="sm"
      >
        {text}
      </Button>
    );
  };

  const onIncreaseAmount = () => {
    if (!userVeAlienInfo) return;

    const parsedAmount = parseUnits(increaseAmount ?? "0", 18);

    writeContract({
      abi: VeAlien,
      address: veAlienAddress,
      functionName: "increase_amount",
      args: [userVeAlienInfo.tokenId, parsedAmount],
    });
  };

  const onIncreaseLockTime = () => {
    if (!userVeAlienInfo || !unlockDate) return;

    const duration = differenceInSeconds(unlockDate, new Date());

    writeContract({
      abi: VeAlien,
      address: veAlienAddress,
      functionName: "increase_unlock_time",
      args: [userVeAlienInfo.tokenId, BigInt(duration)],
    });
  };

  const onWithdraw = () => {
    if (!userVeAlienInfo) return;

    writeContract({
      abi: VeAlien,
      address: veAlienAddress,
      functionName: "withdraw",
      args: [userVeAlienInfo.tokenId],
    });
  };

  const increaseAmountError: string | null = (() => {
    if (increaseAmount === "") {
      return "Enter an amount";
    }

    if (newAmount <= currentLockedAmount) {
      return "Invalid amount";
    }

    const parsedAmount = parseUnits(increaseAmount, 18);
    if (alienBalance && parsedAmount > alienBalance.value) {
      return "Insufficient ALIEN";
    }

    return null;
  })();

  const extendDateError: string | null = (() => {
    if (isBefore(unlockDate, currentUnlockDate)) {
      return "Invalid date";
    }

    return null;
  })();

  useEffect(() => {
    if (isTxSuccess) {
      invalidateWagmiQueries(queryClient);
      setIncreaseAmount("");
    }
  }, [isTxSuccess, queryClient]);

  return (
    <Card>
      <CardBody>
        <Stack>
          {/*<HStack justifyContent="space-between">*/}
          {/*  <Text>Staked Amount</Text>*/}
          {/*  <Text>{formatUnits(currentLockedAmount, 18)} ALIEN</Text>*/}
          {/*</HStack>*/}
          {/*<HStack justifyContent="space-between">*/}
          {/*  <Text>Stake until</Text>*/}
          {/*  <Text>{formatDate(currentUnlockDate, "PP")}</Text>*/}
          {/*</HStack>*/}
          {isExpired ? (
            <Button
              variant="primary"
              onClick={() => onWithdraw()}
              isLoading={isTxSending || isTxConfirming}
            >
              Withdraw
            </Button>
          ) : (
            <Tabs>
              <TabList>
                <Tab flex="0 0 50%">Increase Amount</Tab>
                <Tab flex="0 0 50%">Extend Date</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack>
                    <HStack textAlign="center" mb={4}>
                      <Stack flex="1 0 0" spacing={0} overflow="hidden">
                        <Text fontWeight="bold">Current Locked</Text>
                        <Text
                          overflow="hidden"
                          textOverflow="ellipsis"
                          sx={{ textWrap: "nowrap" }}
                        >
                          {commify(formatUnits(currentLockedAmount, 18))}
                        </Text>
                      </Stack>
                      <FaArrowRight />
                      <Stack flex="1 0 0" spacing={0} overflow="hidden">
                        <Text fontWeight="bold">Locked</Text>
                        <Text
                          overflow="hidden"
                          textOverflow="ellipsis"
                          sx={{ textWrap: "nowrap" }}
                        >
                          {commify(formatUnits(newAmount, 18))}
                        </Text>
                      </Stack>
                    </HStack>
                    <TokenInput
                      symbol="ALIEN"
                      token={AlienAddress}
                      value={increaseAmount}
                      inputName="alienAmount"
                      icon={<TokenIcon symbol="alien" boxSize={6} />}
                      onChange={(e) => setIncreaseAmount(e.target.value)}
                      showMaxButton={true}
                      onMax={
                        alienBalance &&
                        (() =>
                          setIncreaseAmount(
                            formatUnits(
                              alienBalance.value,
                              alienBalance.decimals,
                            ),
                          ))
                      }
                    />
                    <Button
                      variant="primary"
                      onClick={() => onIncreaseAmount()}
                      isLoading={isTxSending || isTxConfirming}
                      isDisabled={!!increaseAmountError}
                      mt={4}
                    >
                      {increaseAmountError || "Increase Amount"}
                    </Button>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack>
                    <HStack textAlign="center">
                      <Stack flex="1 0 0" spacing={0} overflow="hidden">
                        <Text fontWeight="bold">Current Date</Text>
                        <Text overflow="hidden" fontSize="sm">
                          {formatDate(currentUnlockDate, "PPpp")}
                        </Text>
                      </Stack>
                      <FaArrowRight />
                      <Stack flex="1 0 0" spacing={0} overflow="hidden">
                        <Text fontWeight="bold">New Date</Text>
                        <Text overflow="hidden" fontSize="sm">
                          {formatDate(
                            getContractUnlockDate(unlockDate),
                            "PPpp",
                          )}
                        </Text>
                      </Stack>
                    </HStack>
                    <HStack flexWrap="wrap" mt={4}>
                      {renderDurationButton("4y", "4 Years")}
                      {renderDurationButton("2y", "2 Years")}
                      {renderDurationButton("1y", "1 Year")}
                      {renderDurationButton("6m", "6 Months")}
                      {renderDurationButton("1m", "1 Month")}
                    </HStack>
                    <Button
                      variant="primary"
                      onClick={() => onIncreaseLockTime()}
                      isLoading={isTxSending || isTxConfirming}
                      isDisabled={!!extendDateError}
                      mt={4}
                    >
                      {extendDateError || "Extend Date"}
                    </Button>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};
