import { sliderAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(sliderAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bgColor: "primary.700",
    w: "1.25rem",
    h: "1.25rem",
    borderColor: "white",
    borderStyle: "solid",
  },
});

export default defineMultiStyleConfig({ baseStyle });
