import Table from "@/components/Table";
import TokenIcon from "@/components/TokenIcon";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { UserMarket } from "@/types";
import { formatBigInt, formatRate } from "@/utils/format";
import { getBalanceValue } from "@/utils/market";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const UserDashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const columnHelper = createColumnHelper<UserMarket>();

  const { pool } = usePool();
  const { marketsByAddress } = usePoolMarkets(pool);
  const { userSupplyMarkets, userBorrowMarkets } = useUserPoolMarkets(pool);

  useEffect(() => {
    if (userSupplyMarkets.length > 0 || userBorrowMarkets.length > 0) {
      onOpen();
    }
  }, [onOpen, userBorrowMarkets.length, userSupplyMarkets.length]);

  const supplyColumns = [
    columnHelper.display({
      id: "asset",
      header: "Asset",
      cell: ({ row: { original: userMarket } }) => {
        const market = marketsByAddress[userMarket.market];

        return (
          <HStack spacing={2}>
            <TokenIcon symbol={market.marketSymbol} boxSize={8} />
            <Text variant="subtitle3" color="text.primary">
              {market.marketName}
            </Text>
            <Text variant="body3" color="secondary.500">
              {market.marketSymbol}
            </Text>
          </HStack>
        );
      },
    }),
    columnHelper.accessor((userMarket) => marketsByAddress[userMarket.market], {
      id: "supplyApy",
      header: () => <Text textAlign="right">Supply APY</Text>,
      cell: ({ getValue }) => {
        const market = getValue();

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text variant="subtitle3" color="text.primary">
              {formatRate(market.supplyRate)}
            </Text>
            {/*<HStack spacing={1}>*/}
            {/*  <Text variant="subtitle5" color="primary.700">*/}
            {/*    ####%*/}
            {/*  </Text>*/}
            {/*  <TokenIcon symbol="ib" boxSize={4} />*/}
            {/*</HStack>*/}
          </Stack>
        );
      },
    }),
    columnHelper.accessor("supplyBalance", {
      header: () => <Text textAlign="right">Balance</Text>,
      cell: ({
        row: {
          original: { market: marketAddress, supplyBalance },
        },
      }) => {
        const market = marketsByAddress[marketAddress];

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text variant="subtitle3" color="text.primary">
              {formatBigInt(supplyBalance, market.marketDecimals, 4)}
            </Text>
            <HStack spacing={1}>
              <Text variant="subtitle5" color="secondary.500">
                $
                {formatBigInt(
                  getBalanceValue(supplyBalance, market.marketPrice),
                  18,
                  2,
                )}
              </Text>
            </HStack>
          </Stack>
        );
      },
    }),
  ];

  const borrowColumns = [
    columnHelper.display({
      id: "asset",
      header: "Asset",
      cell: ({ row: { original: userMarket } }) => {
        const market = marketsByAddress[userMarket.market];

        return (
          <HStack spacing={2}>
            <TokenIcon symbol={market.marketSymbol} boxSize={8} />
            <Text variant="subtitle3" color="text.primary">
              {market.marketName}
            </Text>
            <Text variant="body3" color="secondary.500">
              {market.marketSymbol}
            </Text>
          </HStack>
        );
      },
    }),
    columnHelper.accessor((userMarket) => marketsByAddress[userMarket.market], {
      id: "borrowApy",
      header: () => <Text textAlign="right">Borrow APY</Text>,
      cell: ({ getValue }) => {
        const market = getValue();

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text variant="subtitle3" color="text.primary">
              {formatRate(market.borrowRate)}
            </Text>
            {/*<HStack spacing={1}>*/}
            {/*  <Text variant="subtitle5" color="primary.700">*/}
            {/*    ####%*/}
            {/*  </Text>*/}
            {/*  <TokenIcon symbol="ib" boxSize={4} />*/}
            {/*</HStack>*/}
          </Stack>
        );
      },
    }),
    columnHelper.accessor("borrowBalance", {
      header: () => <Text textAlign="right">Balance</Text>,
      cell: ({
        row: {
          original: { market: marketAddress, borrowBalance },
        },
      }) => {
        const market = marketsByAddress[marketAddress];

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text variant="subtitle3" color="text.primary">
              {formatBigInt(borrowBalance, market.marketDecimals, 4)}
            </Text>
            <HStack spacing={1}>
              <Text variant="subtitle5" color="secondary.500">
                $
                {formatBigInt(
                  getBalanceValue(borrowBalance, market.marketPrice),
                  18,
                  2,
                )}
              </Text>
            </HStack>
          </Stack>
        );
      },
    }),
  ];

  return (
    <Box>
      <Tabs variant="unstyled">
        {isOpen ? (
          <Flex alignItems="center" px={{ base: 6, lg: 9 }}>
            <TabList gap={2} alignItems="center">
              <Tab p={0}>
                <Text variant="subtitle4">My Supply</Text>
              </Tab>
              <Divider
                orientation="vertical"
                h="1.375rem"
                borderColor="text.divider"
              />
              <Tab p={0}>
                <Text variant="subtitle4">My Borrow</Text>
              </Tab>
            </TabList>
            <Spacer onClick={() => onClose()} />
            <IconButton
              variant="ghost"
              size="md"
              borderRadius="full"
              aria-label="close"
              onClick={() => onClose()}
            >
              <RiArrowUpSLine />
            </IconButton>
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            px={{ base: 6, lg: 9 }}
            onClick={() => onOpen()}
          >
            <Button variant="link" p={0}>
              <Text variant="subtitle4" color="text.link">
                My Account
              </Text>
            </Button>
            <Spacer />
            <IconButton
              variant="ghost"
              aria-label="open"
              size="md"
              borderRadius="full"
              onClick={() => onOpen()}
            >
              <RiArrowDownSLine />
            </IconButton>
          </Flex>
        )}
        <Collapse in={isOpen} animateOpacity>
          <TabPanels>
            <TabPanel p={0}>
              <Table columns={supplyColumns} data={userSupplyMarkets} px={6} />
            </TabPanel>
            <TabPanel p={0}>
              <Table columns={borrowColumns} data={userBorrowMarkets} px={6} />
            </TabPanel>
          </TabPanels>
        </Collapse>
      </Tabs>
    </Box>
  );
};

export default UserDashboard;
