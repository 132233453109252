import { useFastForwardBlockNumber } from "@/hooks/useBlockNumber";
import { useEffect } from "react";
import {
  useWriteContract as useWagmiWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";

export function useWriteContract() {
  const fastForwardBlockNumber = useFastForwardBlockNumber();

  const {
    data: hash,
    writeContract,
    isPending: isWritingContract,
    error,
  } = useWagmiWriteContract();

  const {
    data,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirmed) {
      fastForwardBlockNumber(data.blockNumber);
    }
  });

  return {
    writeContract,
    isLoading: isWritingContract || isConfirming,
    error,
  };
}
