import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { fromUnixTime } from "date-fns";
import { mapValues } from "lodash-es";
import { Address } from "viem";

type PoolData = {
  amount: string; // amount distributing this epoch
  end: number; // unix timestamp (end of this epoch)
  amountByMarket: Record<Address, string>;
};

// All amounts are in unformatted string
type AlienRewardsResponse = {
  totalDistributed: string;
  amountByPool: Record<Address, PoolData>;
};

type UseAlienRewardsData = {
  totalDistributed: bigint;
  amountByPool: Record<
    Address,
    {
      amount: bigint;
      end: Date;
      amountByMarket: Record<Address, bigint>;
    }
  >;
};

export function useAlienRewardsStatus() {
  return useQuery({
    queryKey: ["alien-rewards"],
    queryFn: async (): Promise<UseAlienRewardsData> => {
      const { data } = await axios.get<AlienRewardsResponse>(
        "https://api.alien.finance/api/v1/reward/status",
      );

      return {
        totalDistributed: BigInt(data.totalDistributed),
        amountByPool: mapValues(data.amountByPool, (v) => ({
          amount: BigInt(v.amount),
          end: fromUnixTime(v.end),
          amountByMarket: mapValues(v.amountByMarket, (n) => BigInt(n)),
        })),
      };
    },
  });
}
