import { MaxUint256 } from "@/utils/constants.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import { Button, ButtonProps } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Address, erc20Abi } from "viem";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

interface ApproveButtonProps extends ButtonProps {
  token: Address;
  spender: Address;
}

const ApproveButton = ({
  token,
  spender,
  children,
  ...buttonProps
}: ApproveButtonProps) => {
  const {
    data: txHash,
    writeContract: approve,
    isPending,
  } = useWriteContract();

  const { isLoading: isConfirming, isSuccess } = useWaitForTransactionReceipt({
    hash: txHash,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      invalidateWagmiQueries(queryClient);
    }
  }, [isSuccess, queryClient]);

  return (
    <Button
      w="7.5rem"
      variant="primary"
      onClick={() =>
        approve({
          address: token,
          abi: erc20Abi,
          functionName: "approve",
          args: [spender, MaxUint256],
        })
      }
      disabled={!approve}
      isLoading={isConfirming || isPending}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default ApproveButton;
