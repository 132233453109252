import BlastIcon from "@/assets/img/networks/blast.png";
import HardhatIcon from "@/assets/img/networks/hardhat.svg";
import { isDev, isStaging } from "@/utils/env.ts";
import { Transport, http } from "@wagmi/core";
import { reduce } from "lodash-es";
import { Address, Chain } from "viem";
import { blast, blastSepolia, hardhat } from "wagmi/chains";

export type WrappedMarketType = "wETH" | "wstETH";

export type Contract = {
  alien: Address;
  extension: {
    txBuilder: Address;
    uniswap: Address;
  };
  lens: Address;
};

export type WrappedMarket = {
  market: Address;
  unwrappedMarket: Address;
  unwrappedSymbol: string;
  unwrappedDecimals: number;
  type: WrappedMarketType;
};

export type Pool = {
  name: string;
  contracts: Contract;
  wrappedMarkets: WrappedMarket[];
};

// Here is the refactored Protocol type
export type Protocol = {
  chain: Chain;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  pools: Pool[];
};

export const BlastProtocol: Protocol = {
  chain: blast,
  icon: BlastIcon,
  pools: [
    {
      name: "Blast Pool",
      contracts: {
        alien: "0x02B7BF59e034529d90e2ae8F8d1699376Dd05ade",
        extension: {
          txBuilder: "0xe262e0bE40B7Bb822dd6fC07e37F55836d85C4e8",
          uniswap: "0x2D07601Cf96Ee56E3370A05F7054e1044b40faEe",
        },
        lens: "0xF090b119b10FE4aF048B3EAEB9c0d4821CaBcD30",
      },
      wrappedMarkets: [
        {
          market: "0x4300000000000000000000000000000000000004",
          unwrappedMarket: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
          unwrappedSymbol: "ETH",
          unwrappedDecimals: 18,
          type: "wETH",
        },
      ],
    },
    {
      name: "DApp Pool",
      contracts: {
        alien: "0x50454acC07bf8fC78100619a1b68e9E8d28cE022",
        extension: {
          txBuilder: "0x037941872e2b6F9e2554B86a662D2cc6D90B7b69",
          uniswap: "0x7eef9De7f35F66495139326Df12e6c38311Adc2a",
        },
        lens: "0xF090b119b10FE4aF048B3EAEB9c0d4821CaBcD30",
      },
      wrappedMarkets: [
        {
          market: "0x4300000000000000000000000000000000000004",
          unwrappedMarket: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
          unwrappedSymbol: "ETH",
          unwrappedDecimals: 18,
          type: "wETH",
        },
      ],
    },
  ],
};

export const BlastTestnetProtocol: Protocol = {
  chain: blastSepolia,
  icon: BlastIcon,
  pools: [
    {
      name: "",
      contracts: {
        alien: "0x11f880598A256827C6010f8aBFDBdaaE0b8d7b0a",
        extension: {
          txBuilder: "0x1cC10D503Cf947797cE967f868Bb5608a41072bc",
          uniswap: "0x",
        },
        lens: "0x792d7BF73AbC7D578050044AA4d2EAD18fa16A36",
      },
      wrappedMarkets: [
        {
          market: "0x4200000000000000000000000000000000000023",
          unwrappedMarket: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
          unwrappedSymbol: "ETH",
          unwrappedDecimals: 18,
          type: "wETH",
        },
      ],
    },
  ],
};

export const HardhatProtocol: Protocol = {
  chain: {
    ...blast,
    ...hardhat,
  },
  icon: HardhatIcon,
  pools: BlastProtocol.pools,
};

export const ProductionProtocols = [BlastProtocol];

export const StagingProtocols = [BlastProtocol, BlastTestnetProtocol];

export const DevelopmentProtocols = [
  BlastProtocol,
  BlastTestnetProtocol,
  HardhatProtocol,
];

export function getProtocols() {
  if (isStaging) return StagingProtocols;
  if (isDev) return DevelopmentProtocols;
  return ProductionProtocols;
}

export const protocols = isDev ? DevelopmentProtocols : ProductionProtocols;

export const chains = protocols.map((p) => p.chain);

export const chainTransportMap = reduce(
  chains,
  (r, chain) => {
    r[chain.id] = http();
    return r;
  },
  {} as Record<number, Transport>,
);
