import { AlienClaim } from "@/contracts/AlienClaim.ts";
import { RewardToken } from "@/features/earn/components/RewardToken.tsx";
import { SimpleMarketCardList } from "@/features/earn/components/SimpleMarketCardList.tsx";
import { SimpleMarketTable } from "@/features/earn/components/SimpleMarketTable.tsx";
import {
  getTotalDistributed,
  useBlastPointsStatus,
} from "@/features/earn/hooks/useBlastPointsStatus.ts";
import { useUserBlastGoldStatus } from "@/features/earn/hooks/useUserBlastGoldStatus.ts";
import { useUserBlastPointsStatus } from "@/features/earn/hooks/useUserBlastPointsStatus.ts";
import { useAlienRewardsStatus } from "@/hooks/useAlienRewardsStatus.ts";
import { useMediaQuery } from "@/hooks/useMediaQuery.ts";
import { usePool } from "@/hooks/usePool.ts";
import { useProtocolMarkets } from "@/hooks/useProtocalMarkets";
import { useUserAlienRewards } from "@/hooks/useUserAlienRewards.ts";
import { toDigits } from "@/utils/format.ts";
import { getMarketUsdPrice } from "@/utils/market.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Container,
  Divider,
  HStack,
  Icon,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import BigNumber from "bignumber.js";
import { reduce } from "lodash-es";
import { useEffect } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { formatUnits } from "viem";
import { useTransactionReceipt, useWriteContract } from "wagmi";

const Earn = () => {
  const { markets } = useProtocolMarkets();

  const { pool, pools, changePool } = usePool();

  const { isDesktop } = useMediaQuery();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const tvl = reduce(
    markets,
    (result, market) => {
      return (
        result +
        getMarketUsdPrice(market) *
          BigNumber(
            formatUnits(market.totalCash, market.marketDecimals),
          ).toNumber()
      );
    },
    0,
  );

  const { data: blastPointsStatus } = useBlastPointsStatus();

  const { data: userBlastPoints } = useUserBlastPointsStatus();
  const { data: userBlastGold } = useUserBlastGoldStatus();

  const { data: alienRewardsStatus } = useAlienRewardsStatus();
  const { data: userAlienRewards } = useUserAlienRewards();

  const {
    data: claimTx,
    writeContract,
    isPending: isClaimingAlien,
  } = useWriteContract();

  const { isSuccess: claimSuccess, isLoading: claimConfirming } =
    useTransactionReceipt({
      hash: claimTx,
    });

  const onClaimAlien = () => {
    if (!userAlienRewards) return;

    writeContract({
      abi: AlienClaim,
      address: userAlienRewards.contract,
      functionName: "claim",
      args: [userAlienRewards.totalAmount, userAlienRewards.proof],
    });
  };

  useEffect(() => {
    if (claimSuccess) {
      invalidateWagmiQueries(queryClient);
      void queryClient.invalidateQueries({ queryKey: ["alien-rewards"] });
    }
  }, [claimSuccess, queryClient]);

  return (
    <Container maxW="800px" py={8}>
      <Stack spacing={6}>
        <Card color="text.primary">
          <CardBody>
            <Stack
              spacing={4}
              divider={<Divider w={10} borderColor="secondary.500" />}
            >
              <Stack spacing={0}>
                <Text color="text.secondary" fontWeight="bold">
                  TVL
                </Text>
                <Text color="primary.500" fontSize="2xl" fontWeight="bold">
                  ${toDigits(tvl, 2)}
                </Text>
              </Stack>
              <HStack flexWrap="wrap" alignItems="stretch" spacing={5}>
                <Stack spacing={0} flex="1 0 auto">
                  <Text color="text.secondary" fontWeight="bold">
                    Total Blast Points distributed
                  </Text>
                  <Skeleton isLoaded={!!blastPointsStatus}>
                    <Text color="primary.500" fontSize="2xl" fontWeight="bold">
                      {blastPointsStatus
                        ? toDigits(getTotalDistributed(blastPointsStatus), 2)
                        : "-"}
                    </Text>
                  </Skeleton>
                  <Box fontSize="sm">
                    <Text mb={2}>
                      100% of Blast Points goes to our community
                    </Text>
                    {userBlastPoints && userBlastGold && (
                      <>
                        <Text>
                          <b>Your Pending Points: </b>
                          {userBlastPoints.pending === 0 ||
                          userBlastPoints.pending >= 0.01
                            ? toDigits(userBlastPoints.pending, 2)
                            : "< 0.01"}
                        </Text>
                        {/*<Text>*/}
                        {/*  <b>Time to settlement: </b>*/}
                        {/*  <CountDown end={end} />*/}
                        {/*</Text>*/}
                        <Text>
                          <b>Your Blast Points per hour: </b>
                          {userBlastPoints.earningRate === 0 ||
                          userBlastPoints.earningRate >= 0.01
                            ? toDigits(userBlastPoints.earningRate, 2)
                            : "< 0.01"}
                        </Text>
                        <Text>
                          <b>Your Blast Points received: </b>
                          {userBlastPoints.distributed === 0 ||
                          userBlastPoints.distributed >= 0.01
                            ? toDigits(userBlastPoints.distributed, 2)
                            : "< 0.01"}
                        </Text>
                        <Text>
                          <b>Your Blast Gold received: </b>
                          {userBlastGold.distributed === 0 ||
                          userBlastGold.distributed >= 0.01
                            ? toDigits(userBlastGold.distributed, 2)
                            : "< 0.01"}
                        </Text>
                        <Box
                          as="a"
                          href="https://blast.io/en/airdrop"
                          target="_blank"
                          w="fit-content"
                        >
                          <Button variant="primary" mt={4}>
                            View in Dashboard
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Stack>
                <Stack spacing={0} flex="1 0 auto">
                  <Text color="text.secondary" fontWeight="bold">
                    Total ALIEN token distributed
                  </Text>
                  <Skeleton isLoaded={!!alienRewardsStatus}>
                    <Text color="primary.500" fontSize="2xl" fontWeight="bold">
                      {alienRewardsStatus
                        ? toDigits(
                            formatUnits(
                              alienRewardsStatus.totalDistributed,
                              18,
                            ),
                            2,
                          )
                        : "-"}
                    </Text>
                  </Skeleton>
                  <Box fontSize="sm">
                    <Text
                      as="div"
                      mb={2}
                      display="flex"
                      alignItems="center"
                      gap={1}
                    >
                      {alienRewardsStatus ? (
                        toDigits(
                          formatUnits(
                            alienRewardsStatus.amountByPool[
                              pool.contracts.alien
                            ].amount,
                            18,
                          ),
                          2,
                        )
                      ) : (
                        <SkeletonText noOfLines={1} w="10">
                          -
                        </SkeletonText>
                      )}{" "}
                      ALIEN tokens distributing this week
                    </Text>
                    {userAlienRewards && (
                      <>
                        <Text>
                          <b>ALIEN token received: </b>
                          {toDigits(
                            formatUnits(userAlienRewards.claimed, 18),
                            2,
                          )}
                        </Text>
                        <Text>
                          <b>ALIEN token claimable: </b>
                          {toDigits(
                            formatUnits(userAlienRewards.claimable, 18),
                            2,
                          )}
                        </Text>
                        <Box mt={4}>
                          <Button
                            variant="primary"
                            onClick={() => onClaimAlien()}
                            isDisabled={userAlienRewards.claimable === 0n}
                            isLoading={isClaimingAlien || claimConfirming}
                          >
                            Claim ALIEN
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Stack>
              </HStack>
              <HStack flexWrap="wrap" alignItems="stretch" gap={5}>
                {pools.map((pool) =>
                  isDesktop ? (
                    <Box
                      flex="1 0 0"
                      key={pool.name}
                      bg="#141414"
                      borderRadius={8}
                    >
                      <Button
                        variant="ghost"
                        w="full"
                        color="white"
                        onClick={() => {
                          changePool(pool);
                          void navigate({
                            to: "/lending",
                          });
                        }}
                        mt={4}
                      >
                        {pool.name}{" "}
                        <Icon
                          as={RiArrowRightLine}
                          color="ui.line"
                          boxSize={5}
                        />
                      </Button>
                      <SimpleMarketTable key={pool.name} pool={pool} />
                    </Box>
                  ) : (
                    <Box
                      flex="0 0 100%"
                      key={pool.name}
                      bg="#141414"
                      px={3}
                      borderRadius={8}
                    >
                      <Button
                        variant="ghost"
                        w="full"
                        color="white"
                        onClick={() => {
                          changePool(pool);
                          void navigate({
                            to: "/lending",
                          });
                        }}
                        mt={4}
                      >
                        {pool.name}{" "}
                        <Icon
                          as={RiArrowRightLine}
                          color="ui.line"
                          boxSize={5}
                        />
                      </Button>
                      <SimpleMarketCardList key={pool.name} pool={pool} />
                    </Box>
                  ),
                )}
              </HStack>
            </Stack>
          </CardBody>
        </Card>
        <Card color="text.primary">
          <CardBody>
            <Center as={Stack} spacing={5}>
              <Stack>
                <Text
                  textAlign="center"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  Farm rewards with ALIEN-ETH LP Token
                </Text>
                <Text textAlign="center" sx={{ textWrap: "balance" }}>
                  ALIEN-ETH LP is the liquidity provider token of the Thruster
                  pool. You can pair these two tokens in Thruster and stake the
                  LP token into Hyperlock to earn rewards!
                </Text>
              </Stack>
              <Center as={Stack}>
                <Text color="text.secondary" fontWeight="bold">
                  Rewards
                </Text>
                <HStack spacing={4} flexWrap="wrap" justifyContent="center">
                  <RewardToken symbol="blast" text="Blast Points + Gold" />
                  <RewardToken symbol="thrust" />
                  <RewardToken symbol="hyper" />
                </HStack>
              </Center>
              <a
                href="https://app.hyperlock.finance/"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="primary" mt={2}>
                  Go to Hyperlock
                </Button>
              </a>
            </Center>
          </CardBody>
        </Card>
        {/*{!isDesktop ? <SimpleMarketTable /> : <></>}*/}
      </Stack>
    </Container>
  );
};

export const Route = createFileRoute("/earn")({
  component: Earn,
});
