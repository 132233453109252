import {
  Box,
  Flex,
  Input,
  Select,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { findIndex, map } from "lodash-es";
import { ReactNode, useMemo } from "react";
import { IoIosArrowForward } from "react-icons/io";

import InputSpinner from "@/components/InputSpinner";
import { usePool } from "@/hooks/usePool";
import { Market } from "@/types";
import { findWrappedMarket, isWrappedMarket } from "@/utils/wrappedMarket";
import { MarketSelectorModal } from "./MarketSelectorModal";

export interface WrappedOption {
  market: Market;
  isUnwrapped: boolean;
}

const toAssetSelectorOption = (market: Market) => ({
  market,
  isUnwrapped: false,
  symbol: market.marketSymbol,
  key: market.market,
});

function isWrappedOption(
  value: WrappedOption | Market,
): value is WrappedOption {
  if ((value as WrappedOption).isUnwrapped !== undefined) {
    return true;
  }
  return false;
}

interface AssetSelectorProps {
  isFetching?: boolean;
  label: ReactNode;
  modalTitle: string;
  showModalSupplyAPY?: boolean;
  showModalBorrowAPY?: boolean;
  options: Market[];
  value?: WrappedOption | Market;
  onChange: (market: Market, isUnwrapped: boolean) => void;
  amount: string;
  onAmountChange: (amount: string) => void;
  isInValidAmount: boolean;
  readOnly?: boolean;
  showUnwrappedMarkets?: boolean;
}

const AssetSelector = ({
  isFetching = false,
  label,
  modalTitle,
  showModalSupplyAPY,
  showModalBorrowAPY,
  isInValidAmount,
  value,
  onChange,
  options,
  amount,
  onAmountChange,
  readOnly,
  showUnwrappedMarkets = false,
}: AssetSelectorProps) => {
  const { pool } = usePool();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const derivedOptions = useMemo(() => {
    const _options = options.map(toAssetSelectorOption);
    if (!showUnwrappedMarkets) {
      return _options;
    }
    const unwrappedOptions = options
      .filter((market) => isWrappedMarket(market, pool))
      .map((market) => {
        const wrappedMarket = findWrappedMarket(market, pool);
        return {
          market,
          isUnwrapped: true,
          symbol: wrappedMarket.unwrappedSymbol,
          key: wrappedMarket.unwrappedMarket,
        };
      });
    return [..._options, ...unwrappedOptions];
  }, [options, pool, showUnwrappedMarkets]);

  if (value !== undefined && !isWrappedOption(value)) {
    value = toAssetSelectorOption(value);
  }

  return (
    <Stack spacing={3}>
      {label}
      <Flex gap={6}>
        <Select
          onMouseDown={(e) => {
            e.preventDefault();
            openModal();
          }}
          flex={1}
          variant="filled"
          placeholder={value === undefined ? "Select" : undefined}
          value={findIndex(
            derivedOptions,
            (option) =>
              option.market.market === value?.market.market &&
              option.isUnwrapped === value?.isUnwrapped,
          )}
          onChange={(e) => {
            const option = derivedOptions[Number(e.target.value)];
            onChange(option.market, option.isUnwrapped);
          }}
          icon={<IoIosArrowForward />}
        >
          {map(derivedOptions, (option, idx) => {
            return (
              <option key={option.key} value={idx}>
                {option.symbol}
              </option>
            );
          })}
        </Select>
        {isFetching ? (
          <InputSpinner />
        ) : (
          <Box flex={1}>
            <Input
              w="full"
              variant="filled"
              type="number"
              isInvalid={isInValidAmount}
              value={amount}
              onChange={(e) => {
                onAmountChange(e.target.value);
              }}
              readOnly={readOnly}
            />
          </Box>
        )}
      </Flex>
      <MarketSelectorModal
        isOpen={isModalOpen}
        onClose={closeModal}
        options={derivedOptions}
        title={modalTitle}
        showSupplyAPY={showModalSupplyAPY}
        showBorrowAPY={showModalBorrowAPY}
        onSelect={onChange}
      />
    </Stack>
  );
};

export default AssetSelector;
