import { Address, concatHex, encodePacked, Hex } from "viem";

// Function to encode path and fee to bytes
export function encodeUniswapV3Path(
  path: Address[],
  fee: number[],
): `0x${string}` {
  if (path.length < 2) {
    throw new Error("Invalid path length");
  }
  if (fee.length !== path.length - 1) {
    throw new Error("Invalid fee length");
  }

  let encodedPath: Hex = path[0];
  for (let i = 0; i < fee.length; i++) {
    encodedPath = concatHex([
      encodedPath,
      encodePacked(["uint24", "address"], [fee[i], path[i + 1]]),
    ]);
  }

  return encodedPath;
}
