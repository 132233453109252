import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: "0.625rem",
    lineHeight: "1rem",
    fontWeight: 400,
  },
});

const solidStyle = definePartsStyle({
  container: {
    bgColor: "ui.tag",
    color: "black",
    borderRadius: "1.25rem",
    px: 1,
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: { solid: solidStyle },
});
