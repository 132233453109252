import { Spinner, Stack } from "@chakra-ui/react";

const InputSpinner = () => {
  return (
    <Stack
      bg="background.input"
      borderRadius="0.375rem"
      h="3.5rem"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner color="text.secondary" size="lg" />
    </Stack>
  );
};

export default InputSpinner;
