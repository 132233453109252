import TokenIcon from "@/components/TokenIcon";
import { Flex, Text } from "@chakra-ui/react";

export const TokenView = ({ symbol }: { symbol: string }) => {
  return (
    <Flex gap={2} alignItems="center">
      <TokenIcon symbol={symbol} boxSize={6} />
      <Text
        variant="subtitle3"
        color="text.primary"
        fontWeight={500}
        fontSize={16}
      >
        {symbol}
      </Text>
    </Flex>
  );
};
