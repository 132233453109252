import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    bg: "black",
    border: "1px solid",
    borderColor: "ui.line",
  },
  closeButton: {
    color: "white",
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

export default modalTheme;
