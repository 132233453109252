import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Address } from "viem";
import { useAccount } from "wagmi";

export type HistoryItem = {
  type: "increase_position" | "decrease_position";
  detail: {
    longAsset: Address;
    longAmount: string;
    shortAsset: Address;
    shortAmount: string;
  };
  timestamp: number;
};

export type UseLevXHistoryParams = {
  poolName: string;
};

const poolTypeMap: { [key: string]: string } = {
  "Blast Pool": "blast_pool",
  "DApp Pool": "main_pool",
};

export const useLevXHistory = ({ poolName }: UseLevXHistoryParams) => {
  const { address } = useAccount();

  const poolType = poolTypeMap[poolName];

  return useQuery({
    queryKey: ["useLevXHistory", address, poolType],
    queryFn: async () => {
      if (!address) {
        return [];
      }
      const response = await axios.get<HistoryItem[]>(
        "https://api.alien.finance/api/v1/activity",
        {
          params: {
            account: address,
            pool_type: poolType,
          },
        },
      );
      return response.data;
    },
  });
};
