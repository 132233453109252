import { Alien } from "@/contracts/Alien";
import { usePool } from "@/hooks/usePool.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { Address } from "viem";
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

export function useApproveExtension(extension: Address) {
  const { pool } = usePool();
  const { address } = useAccount();

  const { data: isExtensionAllowed } = useReadContract({
    abi: Alien,
    address: pool.contracts.alien,
    functionName: "isAllowedExtension",
    args: [address || "0x", extension],
    query: {
      enabled: !!address,
    },
  });

  const { data: txResult, writeContract, isPending } = useWriteContract();

  const allowExtension = useCallback(
    () =>
      writeContract({
        abi: Alien,
        address: pool.contracts.alien,
        functionName: "setUserExtension",
        args: [extension, true],
      }),
    [extension, pool.contracts.alien, writeContract],
  );

  const { isLoading: isAllowingExtension, isSuccess } =
    useWaitForTransactionReceipt({
      hash: txResult,
    });

  const queryClient = useQueryClient();
  useEffect(() => {
    if (isSuccess) {
      invalidateWagmiQueries(queryClient);
    }
  }, [isSuccess, queryClient]);

  return {
    isExtensionAllowed,
    allowExtension,
    isAllowingExtension: isPending || isAllowingExtension,
  };
}
