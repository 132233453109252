import { encodeUniswapV3Path } from "@/swapRouter/encodeUniswapV3Path";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BigNumber from "bignumber.js";
import { useDebounceValue } from "usehooks-ts";
import { Address, formatUnits, Hex, parseUnits } from "viem";

export type GetRouteFromAPIProps = {
  /** The contract address of the input token */
  tokenIn: string;
  /** The contract address of the output token */
  tokenOut: string;
  exactAmount: string;
  exactSide: "in" | "out";
};

export type GetRouteResponse = {
  /** An array of fees applied during the swap */
  fees: string[];
  /** The path of token addresses used in the swap */
  path: Hex[];
  /** Amount in decimal number*/
  amount: string;
};

const fetchRouting = async ({
  tokenIn,
  tokenOut,
  exactAmount,
  exactSide,
}: GetRouteFromAPIProps) => {
  const response = await axios.get<GetRouteResponse>(
    "https://api.alien.finance/api/v1/routing",
    {
      params: {
        tokenIn,
        tokenOut,
        [exactSide === "in" ? "tokenInAmount" : "tokenOutAmount"]: exactAmount,
      },
    },
  );
  return response.data;
};

const convertFeeStringToNumber = (feeStr: string) => {
  // Use BigNumber to avoid floating point calculation
  return BigNumber(feeStr).multipliedBy(1e6).toNumber();
};

const debounceOptions = {
  leading: false,
  trailing: true,
};

export const useSwapQuote = ({
  exactSide,
  exactAmount,
  tokenIn,
  tokenInDecimals,
  tokenOut,
  tokenOutDecimals,
}: {
  exactSide: "in" | "out" | undefined;
  exactAmount: bigint | undefined;
  tokenIn: Address | undefined;
  tokenInDecimals: number | undefined;
  tokenOut: Address | undefined;
  tokenOutDecimals: number | undefined;
}) => {
  const [debouncedExactAmount] = useDebounceValue(
    exactAmount,
    500,
    debounceOptions,
  );

  const exactTokenDecimals =
    exactSide === "in" ? tokenInDecimals : tokenOutDecimals;
  const estTokenDecimals =
    exactSide === "in" ? tokenOutDecimals : tokenInDecimals;

  const {
    data: routing,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "useAPIRouting",
      tokenIn,
      tokenOut,
      exactSide,
      debouncedExactAmount?.toString(),
    ],
    queryFn: () => {
      if (
        !tokenIn ||
        !tokenOut ||
        exactSide === undefined ||
        exactTokenDecimals === undefined ||
        !debouncedExactAmount
      ) {
        throw new Error("Invalid input");
      }
      return fetchRouting({
        tokenIn,
        tokenOut,
        exactSide,
        exactAmount: formatUnits(debouncedExactAmount, exactTokenDecimals),
      });
    },
    enabled: !!(tokenIn && tokenOut && exactSide !== undefined && exactAmount),
  });

  const routingPath = routing && [...routing.path];
  const routingFees =
    routing && [...routing.fees].map(convertFeeStringToNumber);

  const encodedPath =
    routingPath &&
    routingFees &&
    (exactSide === "out"
      ? encodeUniswapV3Path(routingPath.reverse(), routingFees.reverse())
      : encodeUniswapV3Path(routingPath, routingFees));

  // Overall Swap Fee: 1 - (1 - fee1) * (1 - fee2) * ... * (1- feeN)
  const overallSwapFee =
    routing !== undefined
      ? new BigNumber(1).minus(
          routing.fees
            .map((fee) => BigNumber(fee))
            .reduce(
              (outRatio, fee) =>
                outRatio.multipliedBy(new BigNumber(1).minus(fee)),
              new BigNumber(1),
            ),
        )
      : undefined;

  const isDebounced = exactAmount !== debouncedExactAmount;

  if (isDebounced) {
    return {
      amount: undefined,
      error: null,
      isLoading: true,
      encodedPath,
      overallSwapFee,
    };
  }

  return {
    amount:
      routing && estTokenDecimals
        ? parseUnits(routing.amount, estTokenDecimals)
        : undefined,
    error,
    isLoading,
    encodedPath,
    overallSwapFee,
  };
};
