export const AlienLens = [
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
    ],
    name: "getAllCurrentMarketsStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalCash",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalBorrow",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalReserves",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxSupplyAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxBorrowAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "marketPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowRate",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketStatus[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
    ],
    name: "getAllMarketsMetadata",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "string",
            name: "marketName",
            type: "string",
          },
          {
            internalType: "string",
            name: "marketSymbol",
            type: "string",
          },
          {
            internalType: "uint8",
            name: "marketDecimals",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "isListed",
            type: "bool",
          },
          {
            internalType: "uint16",
            name: "collateralFactor",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "liquidationThreshold",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "liquidationBonus",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "reserveFactor",
            type: "uint16",
          },
          {
            internalType: "bool",
            name: "isPToken",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "supplyPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "borrowPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "transferPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isSoftDelisted",
            type: "bool",
          },
          {
            internalType: "address",
            name: "aTokenAddress",
            type: "address",
          },
          {
            internalType: "address",
            name: "debtTokenAddress",
            type: "address",
          },
          {
            internalType: "address",
            name: "interestRateModelAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "supplyCap",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowCap",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketMetadata[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
    ],
    name: "getAllMarketsStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalCash",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalBorrow",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalReserves",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxSupplyAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxBorrowAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "marketPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowRate",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketStatus[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "market",
        type: "address",
      },
    ],
    name: "getCurrentMarketStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalCash",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalBorrow",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalReserves",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxSupplyAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxBorrowAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "marketPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowRate",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketStatus",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "address",
        name: "market",
        type: "address",
      },
    ],
    name: "getCurrentUserMarketStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "allowanceToAlien",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "aTokenBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowBalance",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.UserMarketStatus",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "market",
        type: "address",
      },
    ],
    name: "getMarketMetadata",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "string",
            name: "marketName",
            type: "string",
          },
          {
            internalType: "string",
            name: "marketSymbol",
            type: "string",
          },
          {
            internalType: "uint8",
            name: "marketDecimals",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "isListed",
            type: "bool",
          },
          {
            internalType: "uint16",
            name: "collateralFactor",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "liquidationThreshold",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "liquidationBonus",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "reserveFactor",
            type: "uint16",
          },
          {
            internalType: "bool",
            name: "isPToken",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "supplyPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "borrowPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "transferPaused",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isSoftDelisted",
            type: "bool",
          },
          {
            internalType: "address",
            name: "aTokenAddress",
            type: "address",
          },
          {
            internalType: "address",
            name: "debtTokenAddress",
            type: "address",
          },
          {
            internalType: "address",
            name: "interestRateModelAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "supplyCap",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowCap",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketMetadata",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "market",
        type: "address",
      },
    ],
    name: "getMarketStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalCash",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalBorrow",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalReserves",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxSupplyAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxBorrowAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "marketPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowRate",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.MarketStatus",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserAllCurrentMarketsStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "allowanceToAlien",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "aTokenBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowBalance",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.UserMarketStatus[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserAllMarketsStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "allowanceToAlien",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "aTokenBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowBalance",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.UserMarketStatus[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AlienFinance",
        name: "alien",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "address",
        name: "market",
        type: "address",
      },
    ],
    name: "getUserMarketStatus",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "market",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "allowanceToAlien",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "exchangeRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "aTokenBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "supplyBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "borrowBalance",
            type: "uint256",
          },
        ],
        internalType: "struct AlienFinanceLens.UserMarketStatus",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;
