import { usePool } from "@/hooks/usePool.ts";
import {
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { RiArrowDownSLine, RiCheckLine } from "react-icons/ri";

export const PoolSwitch = () => {
  const { pool: currentPool, pools, changePool } = usePool();

  return (
    <Menu>
      <MenuButton as={Button} variant="ghost" fontWeight="500" color="white">
        <HStack alignItems="center">
          <Box>{currentPool.name}</Box>
          <Icon as={RiArrowDownSLine} boxSize={4} />
        </HStack>
      </MenuButton>
      <MenuList w="173px" minW="173px">
        {pools.map((pool) => (
          <MenuItem
            key={pool.name}
            justifyContent="space-between"
            fontWeight="500"
            onClick={() => changePool(pool)}
          >
            {pool.name}
            {currentPool === pool && (
              <Icon as={RiCheckLine} color="ui.line" w={5} h={5} />
            )}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
