import { HStack, Stack, Text } from "@chakra-ui/react";
import { map } from "lodash-es";

export type SummaryItem = {
  title: string;
  primary: {
    value: string;
    prefix?: string;
    suffix?: string;
    color?: string;
  };
  secondary?: {
    value: string;
    prefix?: string;
    suffix?: string;
    color?: string;
  };
};

interface TxSummaryCardProps {
  items: SummaryItem[];
}

export const TxSummaryCard = ({ items }: TxSummaryCardProps) => {
  return (
    <Stack spacing={6} px={6}>
      <Text variant="subtitle4" color="secondary.500">
        Tx Summary
      </Text>
      <Stack spacing={3}>
        {map(items, ({ title, primary, secondary }) => (
          <HStack
            key={title}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text variant="subtitle4" color="text.primary">
              {title}
            </Text>
            <Stack textAlign="right" spacing={0}>
              <Text
                variant="subtitle4"
                color={primary.color || "secondary.500"}
              >
                {`${primary.prefix || ""}${primary.value}${
                  primary.suffix || ""
                }`}
              </Text>
              {secondary && (
                <Text
                  variant="subtitle5"
                  color={secondary.color || "primary.500"}
                >
                  {`${secondary.prefix || ""}${secondary.value}${
                    secondary.suffix || ""
                  }`}
                </Text>
              )}
            </Stack>
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
};

export default TxSummaryCard;
