import { VeAlien } from "@/contracts/veAlien.ts";
import { veAlienAddress } from "@/features/stake/alien/config.ts";
import { fromUnixTime } from "date-fns";
import { useAccount, useReadContract } from "wagmi";

// user might have multiple veAlien NFTs, this hook only return the latest veAlien info
export function useUserVeAlienInfo():
  | { tokenId: bigint; lockedAmount: bigint; unlockDate: Date }
  | undefined {
  const { address } = useAccount();

  const { data: balance } = useReadContract({
    abi: VeAlien,
    address: veAlienAddress,
    functionName: "balanceOf",
    args: [address ?? "0x"],
    query: {
      enabled: !!address,
    },
  });

  const { data: lastIndex } = useReadContract({
    abi: VeAlien,
    address: veAlienAddress,
    functionName: "tokenOfOwnerByIndex",
    args: [address ?? "0x", balance !== undefined ? balance - 1n : 0n],
    query: {
      enabled: balance !== undefined,
    },
  });

  const { data } = useReadContract({
    abi: VeAlien,
    address: veAlienAddress,
    functionName: "locked",
    args: [lastIndex ?? 0n],
    query: {
      enabled: lastIndex !== undefined,
    },
  });

  if (lastIndex !== undefined && data) {
    return {
      tokenId: lastIndex,
      lockedAmount: data[0],
      unlockDate: fromUnixTime(Number(data[1])),
    };
  } else {
    return undefined;
  }
}
