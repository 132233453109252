// https://www.tradingview.com/widget-docs/widgets/charts/advanced-chart/

import { Market } from "@/types";
import { useEffect, useRef } from "react";

const marketSymbolMap: Record<string, string> = {
  BLAST: "COINBASE:BLASTUSD",
  BTC: "COINBASE:BTCUSD",
  DAI: "COINBASE:DAIUSD",
  JUICE: "COINEX:JUICEUSDT",
  MACHI: "MACHIUSDB_2E0041.USD",
  ORBIT: "ORBITWETH_E9B623.USD",
  PUSDC: "GEMINI:USDCUSD",
  PWETH: "COINBASE:ETHUSD",
  USDB: "PYTH:USDBUSD",
  USDC: "GEMINI:USDCUSD",
  USDT: "COINBASE:USDTUSD",
  WBTC: "COINBASE:BTCUSD",
  WETH: "COINBASE:ETHUSD",
  WSTETH: "COINBASE:ETHUSD",
  YES: "YESWETH_D0F1E1.USD",
};

interface TradingViewWidgetProps {
  longMarket: Market;
  shortMarket: Market;
}

export const TradingViewWidget = ({
  longMarket,
  shortMarket,
}: TradingViewWidgetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const longMarketSymbol =
    marketSymbolMap[longMarket.marketSymbol.toUpperCase()];
  const shortMarketSymbol =
    marketSymbolMap[shortMarket.marketSymbol.toUpperCase()];
  const symbol = `${longMarketSymbol}/${shortMarketSymbol}`;

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const config = {
      autosize: true,
      symbol,
      interval: "5",
      timezone: "Etc/UTC",
      theme: "dark",
      style: "1",
      locale: "en",
      hide_top_toolbar: true,
      allow_symbol_change: false,
      save_image: false,
      calendar: false,
      support_host: "https://www.tradingview.com",
    };

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify(config);
    containerRef.current.appendChild(script);
    // console.log("append child");
    return () => {
      // console.log("remove child");
      script.parentElement?.removeChild(script);
    };
  }, [symbol, containerRef]);

  return (
    <div
      key={symbol}
      ref={containerRef}
      style={{ height: "100%", width: "100%" }}
    />
  );
};
