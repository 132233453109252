import { Box, Button, HStack, Input, Stack, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";

interface BalanceInputProps {
  value: string;
  maxValue: string;
  usdRate: number;
  onChange: (value: string) => void;
  maxValuePrefixText?: string;
  maxValueSuffixText?: string;
  errorMessage?: string;
}

const BalanceInput = ({
  value,
  maxValue,
  usdRate,
  onChange,
  maxValuePrefixText = "",
  maxValueSuffixText = "",
  errorMessage = "",
}: BalanceInputProps) => {
  const usdValue = new BigNumber(value || "0")
    .multipliedBy(usdRate)
    .toFormat(2);

  const isInvalid = errorMessage !== "";

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          px: 3,
          py: 1,
          bgColor: "background.input",
          borderRadius: "0.375rem",
          border: isInvalid ? "0.063rem solid" : "unset",
          borderColor: "red.500",
        }}
      >
        <Input
          value={value}
          placeholder="0.00"
          variant="unstyled"
          type="number"
          inputMode="decimal"
          fontWeight="600"
          color="white"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <Text variant="body3">${usdValue}</Text>
      </Box>
      <Stack spacing={0}>
        <HStack justifyContent="space-between">
          <Text variant="body2" color="text.primary">
            {maxValuePrefixText}
            {new BigNumber(maxValue).toFormat(4)}
            {maxValueSuffixText}
          </Text>
          <Button
            color="ui.tag"
            variant="link"
            onClick={() => {
              onChange(maxValue);
            }}
          >
            Max
          </Button>
        </HStack>
        {errorMessage && (
          <Text variant="subtitle4" color="red.500">
            {errorMessage}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default BalanceInput;
