import { toDigits } from "@/utils/format";
import { formatUnits } from "viem";

export function formatUSDValue(value: bigint): string;
export function formatUSDValue(value: undefined): undefined;
export function formatUSDValue(value: bigint | undefined): string | undefined;
export function formatUSDValue(value: bigint | undefined): string | undefined {
  if (value === undefined) {
    return;
  }
  return `$${toDigits(formatUnits(value, 18), 2)}`;
}
