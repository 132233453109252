import { Pool } from "@/configs/chains.ts";
import { AlienLens } from "@/contracts/AlienLens";
import { usePool } from "@/hooks/usePool.ts";
import { Market } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { Config, readContract, simulateContract } from "@wagmi/core";
import { assign, concat, map } from "lodash-es";
import { useConfig } from "wagmi";

async function getMarkets(config: Config, pool: Pool): Promise<Market[]> {
  const allMarketMetadata = await readContract(config, {
    address: pool.contracts.lens,
    abi: AlienLens,
    functionName: "getAllMarketsMetadata",
    args: [pool.contracts.alien],
  });

  const { result: allCurrentMarketsStatus } = await simulateContract(config, {
    address: pool.contracts.lens,
    abi: AlienLens,
    functionName: "getAllCurrentMarketsStatus",
    args: [pool.contracts.alien],
    account: "0x0000000000000000000000000000000000000000",
  });

  return map(allCurrentMarketsStatus, (m, i) =>
    assign({}, m, allMarketMetadata[i]),
  );
}

export function useProtocolMarkets() {
  const { pools } = usePool();

  const config = useConfig();

  const { data, isLoading, isSuccess } = useQuery({
    // A little hack here: we use the `readContracts` here so when we're invalidating
    // wagmi's queries, we're also invalidating this query.
    queryKey: ["readContracts", "useProtocolMarkets", pools],
    queryFn: async () => {
      const marketsList = await Promise.all(
        pools.map((p) => getMarkets(config, p)),
      );
      return concat([], ...marketsList);
    },
  });

  return {
    markets: data,
    isLoading,
    isSuccess,
  };
}
