import BigNumber from "bignumber.js";
import { formatUnits } from "viem";

export const aTokenToUnderlying = (
  aTokenAmount: bigint,
  exchangeRate: bigint,
  underlyingDecimal: number,
) => {
  return BigNumber(formatUnits(aTokenAmount, 18))
    .multipliedBy(formatUnits(exchangeRate, 18))
    .shiftedBy(18 - underlyingDecimal)
    .toString();
};

export const underlyingToUsd = (
  underlyingAmount: bigint,
  usdPrice: bigint,
  underlyingDecimal: number,
) => {
  if (underlyingAmount === 0n || usdPrice === 0n) {
    return "0";
  }

  return BigNumber(formatUnits(underlyingAmount, 18))
    .multipliedBy(formatUnits(usdPrice, 18))
    .shiftedBy(underlyingDecimal - 18)
    .toString();
};

export const underlyingPriceToUsd = (
  underlyingPrice: bigint,
  underlyingDecimal: number,
) => {
  if (underlyingPrice === 0n) {
    return "0";
  }

  return BigNumber(formatUnits(underlyingPrice, 18))
    .shiftedBy(underlyingDecimal - 18)
    .toString();
};

export const aTokenToUsd = (
  aTokenAmount: bigint,
  exchangeRate: bigint,
  underlyingDecimal: number,
  usdPrice: bigint,
) => {
  if (aTokenAmount === 0n || exchangeRate === 0n || usdPrice === 0n) {
    return "0";
  }

  return BigNumber(formatUnits(aTokenAmount, 18))
    .multipliedBy(formatUnits(exchangeRate, 18))
    .shiftedBy(18 - underlyingDecimal)
    .multipliedBy(formatUnits(usdPrice, 18))
    .shiftedBy(underlyingDecimal - 18)
    .toString();
};

export const underlyingBalance = (
  underlying: bigint,
  underlyingDecimal: number,
) => {
  return formatUnits(underlying, underlyingDecimal);
};

export const getReChartsTickUSDValue = (value: number) =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
