import { useLevXCloseAmounts } from "@/features/leverage/hooks/useLevXAmounts";
import { slippageAtom } from "@/features/leverage/states/states";
import { formatUSDValue } from "@/features/leverage/utils/formatUSDValue";
import { useUserStats } from "@/hooks/useUserStats";
import { getMarketBorrowLimit } from "@/utils/market";
import { Text } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { formatUnits } from "viem";
import { TxSummaryCard, TxSummaryItem } from "./TxSummaryCard";

export const LevXCloseTxSummaryCard = () => {
  const { borrowLimit: borrowLimitBefore, borrowBalance } = useUserStats();

  const {
    longMarket,
    longAmount,
    shortMarket,
    longValue,
    shortValue,
    swapFee,
  } = useLevXCloseAmounts();
  const slippage = useAtomValue(slippageAtom);

  const longBorrowLimit =
    longMarket && longAmount !== undefined
      ? getMarketBorrowLimit(longMarket, longAmount)
      : undefined;

  const borrowLimitAfter =
    longBorrowLimit != undefined
      ? borrowLimitBefore - longBorrowLimit
      : undefined;

  const borrowLimitUsedBefore =
    borrowLimitBefore > 0n
      ? (borrowBalance * 10000n) / borrowLimitBefore
      : undefined;

  const borrowLimitUsed =
    shortValue !== undefined ? borrowBalance - shortValue : undefined;

  const borrowLimitUsedAfter =
    borrowLimitUsed !== undefined &&
    borrowLimitAfter !== undefined &&
    borrowLimitAfter > 0n
      ? (borrowLimitUsed * 10000n) / borrowLimitAfter
      : undefined;

  const withdrawValueStr = formatUSDValue(longValue) || "--";
  const repayValueStr = formatUSDValue(shortValue) || "--";

  const slippageStr = `${slippage * 100}%`;
  const swapFeeStr =
    swapFee !== undefined ? `${swapFee.multipliedBy(100).toFixed(2)}%` : "--";

  const borrowLimitBeforeStr = formatUSDValue(borrowLimitBefore);
  const borrowLimitAfterStr = formatUSDValue(borrowLimitAfter) || "--";
  const borrowLimitUsedBeforeStr =
    borrowLimitUsedBefore !== undefined
      ? `${formatUnits(borrowLimitUsedBefore, 2)}%`
      : "--";
  const borrowLimitUsedAfterStr =
    borrowLimitUsedAfter !== undefined
      ? `${formatUnits(borrowLimitUsedAfter, 2)}%`
      : "--";

  return (
    <TxSummaryCard>
      {longMarket && (
        <TxSummaryItem name={longMarket.marketSymbol} badgeType="Withdraw">
          {withdrawValueStr}
        </TxSummaryItem>
      )}

      {shortMarket && (
        <TxSummaryItem name={shortMarket.marketSymbol} badgeType="Repay">
          {repayValueStr}
        </TxSummaryItem>
      )}
      <TxSummaryItem name="Slippage">{slippageStr}</TxSummaryItem>
      <TxSummaryItem name="Swap Fee">{swapFeeStr}</TxSummaryItem>
      <TxSummaryItem name="Borrow Limit">
        <Text>{borrowLimitBeforeStr}</Text>
        <Text textColor="white">{borrowLimitAfterStr}</Text>
      </TxSummaryItem>
      <TxSummaryItem name="Borrow Limit Used">
        <Text>{borrowLimitUsedBeforeStr}</Text>
        <Text textColor="white">{borrowLimitUsedAfterStr}</Text>
      </TxSummaryItem>
    </TxSummaryCard>
  );
};
