import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bgColor: "primary.500",
    alignItems: "center",
  },
  thumb: {},
});

export default defineMultiStyleConfig({
  defaultProps: {
    size: "md",
  },
  baseStyle,
});
