import { addingCollateralAtom } from "@/features/leverage/states/states";

import { useUserStats } from "@/hooks/useUserStats";
import { getMarketBorrowLimit } from "@/utils/market";
import { useAtomValue } from "jotai";

export const usePendingBorrowLimit = () => {
  const { borrowLimit: currentBorrowLimit, borrowBalance } = useUserStats();
  const addingCollateral = useAtomValue(addingCollateralAtom);
  const addingBorrowLimit =
    addingCollateral != undefined
      ? getMarketBorrowLimit(addingCollateral.market, addingCollateral.amount)
      : 0n;

  const newBorrowLimit = currentBorrowLimit + addingBorrowLimit;

  return {
    currentBorrowLimit,
    addingBorrowLimit,
    newBorrowLimit,
    borrowBalance,
  };
};
