import { Market } from "@/types";
import { supplyBlockList } from "@/utils/constants";

export const canLeverage = (market: Market) => {
  const isBlocked = supplyBlockList.includes(market.market);
  if (isBlocked || market.supplyPaused) {
    return false;
  }
  return true;
};
