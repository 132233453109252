import { UniswapExtension } from "@/contracts/UniswapExtension.ts";
import { usePool } from "@/hooks/usePool.ts";
import { ExtensionAction } from "@/types";
import {
  Address,
  encodeAbiParameters,
  encodeFunctionData,
  Hex,
  parseAbiParameters,
  toHex,
} from "viem";
import {
  useEstimateGas,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

function encodeActionName(name: string) {
  return toHex(name, { size: 32 });
}

export function newSupplyNativeTokenAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);
  return {
    name: encodeActionName("ACTION_SUPPLY_NATIVE_TOKEN"),
    data,
    value: amount,
  };
}

export function newSupplyStEthAction(amount: bigint): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("uint256"), [amount]);

  return {
    name: encodeActionName(`ACTION_SUPPLY_STETH`),
    data,
  };
}

function newPoolAction(
  actionName: string,
  asset: Address,
  amount: bigint,
): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("address, uint256"), [
    asset,
    amount,
  ]);

  return {
    name: encodeActionName(actionName),
    data,
  };
}

export function newSupplyAction(
  asset: Address,
  amount: bigint,
): ExtensionAction {
  const data = encodeAbiParameters(parseAbiParameters("address, uint256"), [
    asset,
    amount,
  ]);

  return {
    name: encodeActionName("ACTION_SUPPLY"),
    data,
  };
}

export function newSupplyPTokenAction(
  pToken: Address,
  amount: bigint,
): ExtensionAction {
  return newPoolAction("ACTION_SUPPLY_PTOKEN", pToken, amount);
}

type UniswapCommonParams = {
  subAction: Hex;
  deadline: number;
};

type UniswapV3ExactOutputParams = {
  inAsset: Address;
  outAsset: Address;
  outAmount: bigint;
  maxInAmount: bigint;
  path: Hex;
} & UniswapCommonParams;

type UniswapV3ExactInputParams = {
  inAsset: Address;
  outAsset: Address;
  inAmount: bigint;
  minOutAmount: bigint;
  path: Hex;
} & UniswapCommonParams;

type UniswapV2ExactOutputParams = {
  outAmount: bigint;
  maxInAmount: bigint;
  path: Address[];
} & UniswapCommonParams;

type UniswapV2ExactInputParams = {
  inAmount: bigint;
  minOutAmount: bigint;
  path: Address[];
} & UniswapCommonParams;

export function newUniswapV3ExactOutputAction({
  outAsset,
  outAmount,
  inAsset,
  maxInAmount,
  path,
  subAction,
  deadline,
}: UniswapV3ExactOutputParams) {
  const data = encodeAbiParameters(
    parseAbiParameters(
      "address, uint256, address, uint256, bytes, bytes32, uint256",
    ),
    [
      outAsset,
      outAmount,
      inAsset,
      maxInAmount,
      path,
      subAction,
      BigInt(deadline),
    ],
  );

  return {
    name: encodeActionName("ACTION_UNISWAP_V3_EXACT_OUTPUT"),
    data,
  };
}

export function newUniswapV3ExactInputAction({
  inAsset,
  inAmount,
  outAsset,
  minOutAmount,
  path,
  subAction,
  deadline,
}: UniswapV3ExactInputParams) {
  const data = encodeAbiParameters(
    parseAbiParameters(
      "address, uint256, address, uint256, bytes, bytes32, uint256",
    ),
    [
      inAsset,
      inAmount,
      outAsset,
      minOutAmount,
      path,
      subAction,
      BigInt(deadline),
    ],
  );

  return {
    name: encodeActionName("ACTION_UNISWAP_V3_EXACT_INPUT"),
    data,
  };
}

export function newUniswapV2ExactOutputAction({
  outAmount,
  maxInAmount,
  path,
  subAction,
  deadline,
}: UniswapV2ExactOutputParams) {
  const data = encodeAbiParameters(
    parseAbiParameters("uint256, uint256, address[], bytes32, uint256"),
    [outAmount, maxInAmount, path, subAction, BigInt(deadline)],
  );

  return {
    name: encodeActionName("ACTION_UNISWAP_V2_EXACT_OUTPUT"),
    data,
  };
}

export function newUniswapV2ExactInputAction({
  inAmount,
  minOutAmount,
  path,
  subAction,
  deadline,
}: UniswapV2ExactInputParams) {
  const data = encodeAbiParameters(
    parseAbiParameters("uint256, uint256, address[], bytes32, uint256"),
    [inAmount, minOutAmount, path, subAction, BigInt(deadline)],
  );

  return {
    name: encodeActionName("ACTION_UNISWAP_V2_EXACT_INPUT"),
    data,
  };
}

export const IncreasePositionSubAction = encodeActionName(
  "SUB_ACTION_INCREASE_POSITION",
);

export const DecreasePositionSubAction = encodeActionName(
  "SUB_ACTION_DECREASE_POSITION",
);

const getActionsValue = (actions: ExtensionAction[]) => {
  return actions.reduce((sum, action) => sum + (action.value || 0n), 0n);
};

export function useSendUniswapExtensionActions(
  getActions: (() => ExtensionAction[]) | undefined,
) {
  const { pool } = usePool();

  const estActions = getActions?.();
  const estValue = estActions && getActionsValue(estActions);
  const estFunctionData =
    estActions &&
    encodeFunctionData({
      abi: UniswapExtension,
      functionName: "execute",
      args: [estActions],
    });

  const {
    data: estimatedGas,
    error: estimateGasError,
    isLoading: isEstimatingGas,
  } = useEstimateGas({
    data: estFunctionData,
    to: pool.contracts.extension.uniswap,
    value: estValue,
    query: {
      enabled: !!estActions,
    },
  });

  const {
    writeContract,
    data: hash,
    isPending: isWriteContractPending,
    error: writeContractError,
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    error,
    isSuccess,
  } = useWaitForTransactionReceipt({
    hash,
  });

  const sendActions =
    getActions !== undefined
      ? () => {
          if (estimatedGas === undefined) {
            throw new Error("no estimated gas");
          }
          const actions = getActions();
          writeContract({
            address: pool.contracts.extension.uniswap,
            abi: UniswapExtension,
            functionName: "execute",
            args: [actions],
            gas: (estimatedGas * 120n) / 100n,
            value: getActionsValue(actions),
          });
        }
      : undefined;

  return {
    sendActions,
    isEstimatingGas,
    isSuccess,
    isSending: isWriteContractPending || isConfirming,
    error: writeContractError || estimateGasError || error,
  };
}
