import { Tooltip, TooltipProps, useDisclosure } from "@chakra-ui/react";
import {
  ReactElement,
  RefObject,
  cloneElement,
  useEffect,
  useRef,
} from "react";

import useMediaQuery from "@/hooks/useMediaQuery.ts";

export function ResponsiveTooltip({
  triggerRef,
  children,
  closeDelay = 1500,
  ...restProps
}: Omit<TooltipProps, "children" | "isOpen" | "openDelay"> & {
  triggerRef?: RefObject<HTMLElement | undefined>;
  children: ReactElement;
}) {
  const { isMobile, isDesktop } = useMediaQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fallbackRef = useRef<HTMLElement>();

  const bindingTriggerRef = triggerRef || fallbackRef;

  const child = triggerRef
    ? children
    : cloneElement(children, {
        ref: fallbackRef,
      });

  useEffect(() => {
    onClose();
  }, [isMobile, onClose]);

  useEffect(() => {
    const element = bindingTriggerRef.current;
    if (!element) {
      return;
    }

    if (isDesktop) {
      // Desktop trigger
      // Mouse enter to open, leave to close
      element.addEventListener("mouseenter", onOpen);
      element.addEventListener("mouseleave", onClose);

      return () => {
        element.removeEventListener("mouseenter", onOpen);
        element.removeEventListener("mouseleave", onClose);
      };
    } else {
      // Mobile trigger
      // Touch to open for `closeDelay` milliseconds
      // Tap to toggle open

      const setToggleTrigger = () => {
        window.document.addEventListener("touchstart", onClose, {
          once: true,
        });
      };
      const clearToggleTrigger = () => {
        window.document.removeEventListener("touchstart", onClose);
      };

      let timeoutHandle: ReturnType<typeof setTimeout>;
      const touchHandler = () => {
        clearTimeout(timeoutHandle);
        clearToggleTrigger();
        timeoutHandle = setTimeout(() => {
          onClose();
        }, closeDelay);
        onOpen();
      };

      const clickHandler = () => {
        clearTimeout(timeoutHandle);
        setTimeout(setToggleTrigger, 0);
        onOpen();
      };

      element.addEventListener("touchstart", touchHandler);
      element.addEventListener("touchmove", touchHandler);
      element.addEventListener("click", clickHandler);

      return () => {
        clearTimeout(timeoutHandle);
        element.removeEventListener("touchstart", touchHandler);
        element.removeEventListener("touchmove", touchHandler);
        element.removeEventListener("click", clickHandler);
        clearToggleTrigger();
      };
    }
  }, [onClose, onOpen, bindingTriggerRef, isDesktop, closeDelay]);

  return (
    <Tooltip {...restProps} isOpen={isOpen}>
      {child}
    </Tooltip>
  );
}
