import { UnapprovedTx } from "@/components/UnapprovedTx.tsx";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { Card, Stack } from "@chakra-ui/react";
import { isEmpty } from "lodash-es";
import { useContext } from "react";

const ApproveCard = () => {
  const { unapprovedTxs } = useContext(TxBuilderContext);

  return isEmpty(unapprovedTxs) ? null : (
    <Card p={6}>
      <Stack spacing={6}>
        <UnapprovedTx unapprovedTxs={unapprovedTxs} />
      </Stack>
    </Card>
  );
};

export default ApproveCard;
