import { Box, Card, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

export type BadgeType = "Long" | "Short" | "Supply" | "Withdraw" | "Repay";

const badgeColors: {
  [k in BadgeType]: { bg: string; fg: string };
} = {
  Long: {
    bg: "color.long",
    fg: "#000000",
  },
  Short: {
    bg: "color.short",
    fg: "#000000",
  },
  Supply: {
    bg: "ui.tag",
    fg: "#000000",
  },
  Withdraw: {
    bg: "color.label",
    fg: "#000000",
  },
  Repay: {
    bg: "color.label",
    fg: "#000000",
  },
};

const SummaryBadge = ({ type }: { type: BadgeType }) => {
  return (
    <Box
      backgroundColor={badgeColors[type].bg}
      textColor={badgeColors[type].fg}
      borderRadius={20}
      fontSize={10}
      fontWeight={400}
      paddingX={"4px"}
      lineHeight={16 / 10}
    >
      {type.toString()}
    </Box>
  );
};

export const TxSummaryItem = ({
  name,
  badgeType,
  children,
}: {
  name: string;
  badgeType?: BadgeType;
  children: string | React.ReactNode;
}) => {
  return (
    <Flex gap={1} alignItems="flex-start">
      <Flex gap={1} alignItems="center">
        <Text fontWeight={600} fontSize={14} lineHeight={22 / 14}>
          {name}
        </Text>
        {badgeType && <SummaryBadge type={badgeType} />}
      </Flex>

      <Text
        flex={1}
        textAlign="right"
        fontWeight={600}
        fontSize={14}
        lineHeight={22 / 14}
        textColor="color.label"
      >
        <Stack gap={1}>
          {typeof children === "string" ? <Text>{children}</Text> : children}
        </Stack>
      </Text>
    </Flex>
  );
};

export const TxSummaryCard = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Card>
      <Stack padding={6} gap={6}>
        <Heading
          textColor="color.label"
          fontWeight={600}
          fontSize={14}
          lineHeight={22 / 14}
        >
          Tx Summary
        </Heading>
        <Stack>{children}</Stack>
      </Stack>
    </Card>
  );
};
