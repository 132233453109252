import { MaxUint256 } from "@/utils/constants.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Address, erc20Abi } from "viem";
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

export const useApprove = (
  token: Address,
  spender: Address,
  amount: bigint,
) => {
  const { address } = useAccount();

  const { data: allowance } = useReadContract({
    abi: erc20Abi,
    address: token,
    functionName: "allowance",
    args: [address || "0x", spender],
    query: { enabled: !!address },
  });

  const {
    data: hash,
    writeContract,
    isPending: isSendingTx,
  } = useWriteContract();

  const approve = () => {
    writeContract({
      abi: erc20Abi,
      address: token,
      functionName: "approve",
      args: [spender, MaxUint256],
    });
  };

  const { isLoading: isWaitingReceipt, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
    });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      invalidateWagmiQueries(queryClient);
    }
  }, [isSuccess, queryClient]);

  return {
    isApproved: allowance && allowance !== 0n && allowance >= amount,
    isApproving: isSendingTx || isWaitingReceipt,
    approve,
  };
};
