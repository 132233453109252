import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface StickyFooterProps extends BoxProps {
  children: ReactNode;
}

const StickyFooter = ({ children, ...props }: StickyFooterProps) => {
  return (
    <Box
      pos="sticky"
      bottom={0}
      zIndex={20}
      w="full"
      bgColor="black"
      px={6}
      pt={6}
      pb={10}
      border="1px solid"
      borderColor="ui.line"
      borderRadius="6px 6px 0px 0px"
      boxShadow="0px 0px 15px rgba(0, 0, 0, 0.05)"
      {...props}
    >
      {children}
    </Box>
  );
};

export default StickyFooter;
