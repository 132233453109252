import AssetSelector from "@/components/AssetSelector";
import LeverageSelector from "@/components/LeverageSelector";
import { useLevXOpenAmounts } from "@/features/leverage/hooks/useLevXAmounts";
import { usePendingBorrowLimit } from "@/features/leverage/hooks/usePendingBorrowLimit";
import {
  addingCollateralAtom,
  closeExactFieldAtom,
  isInOpenAtom,
  longMarketAtom,
  openExactFieldAtom,
  shortMarketAtom,
  slippageAtom,
  statTabAtom,
} from "@/features/leverage/states/states";
import { formatUSDValue } from "@/features/leverage/utils/formatUSDValue";
import { useLevX } from "@/hooks/useLevX";
import { useLesserSide, useLevXClose } from "@/hooks/useLevXClose";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePool } from "@/hooks/usePool";
import { usePoolMarkets } from "@/hooks/usePoolMarkets";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { TokenAmount } from "@/types/tokenAmount";
import { getMarketErc20Token, getMarketUnwrappedToken } from "@/utils/market";
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo } from "react";
import { TbCirclePlus } from "react-icons/tb";
import { formatUnits } from "viem/utils";
import { useAccount } from "wagmi";
import { canLeverage } from "../utils/canLeverage";
import { CustomTabList } from "./CustomTabList";
import { EditCollateralModal } from "./EditCollateralModal";
import SubmitTxsButton from "./SubmitTxsButton";

export const LeverageInputCard = () => {
  const setStatTab = useSetAtom(statTabAtom);

  const [isInOpen, setIsInOpen] = useAtom(isInOpenAtom);

  return (
    <Card>
      <Stack gap={0} py={6}>
        <Tabs
          variant="unstyled"
          p={0}
          onChange={(index) => {
            setIsInOpen(index === 0);
            if (index === 1) {
              setStatTab("position");
            }
          }}
          tabIndex={isInOpen ? 0 : 1}
          isLazy
        >
          <CustomTabList tabs={["Open", "Close"]} />
          <TabPanels>
            <TabPanel p={0}>
              <LevXOpenPanel />
            </TabPanel>
            <TabPanel p={0}>
              <LevXClosePanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Card>
  );
};

const CollateralView = () => {
  const { currentBorrowLimit, addingBorrowLimit, borrowBalance } =
    usePendingBorrowLimit();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const borrowLimitLeft = currentBorrowLimit - borrowBalance;
  const borrowLimitLeftStr = formatUSDValue(borrowLimitLeft);
  const addingBorrowLimitStr = `+${formatUSDValue(addingBorrowLimit)}`;

  return (
    <Stack px={6} mt={6}>
      <Heading
        textColor="color.label"
        fontWeight={600}
        fontSize={14}
        lineHeight={22 / 14}
      >
        Borrow Limit Left
      </Heading>
      <Flex>
        <HStack flex={1} fontWeight={600} fontSize={14} lineHeight={22 / 14}>
          <Text>{borrowLimitLeftStr}</Text>
          {addingBorrowLimit > 0n && (
            <Text textColor="color.long">{addingBorrowLimitStr}</Text>
          )}
        </HStack>
        <HStack onClick={onOpen} gap={1} cursor="pointer">
          <Icon boxSize={5} color="ui.tag" as={TbCirclePlus} />
          <Text color="ui.tag" fontWeight={600} fontSize={14}>
            {addingBorrowLimit ? "Edit" : "Increase"}
          </Text>
        </HStack>
      </Flex>
      <EditCollateralModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};

const LevXOpenPanel = () => {
  const { isDesktop, isMobile } = useMediaQuery();
  const { pool } = usePool();
  const { markets } = usePoolMarkets(pool);
  const { isDisconnected } = useAccount();

  const [longMarket, setLongMarket] = useAtom(longMarketAtom);
  const [shortMarket, setShortMarket] = useAtom(shortMarketAtom);

  const [exactField, setExactField] = useAtom(openExactFieldAtom);
  const { estAmount, estMarket, isEstAmountLoading } = useLevXOpenAmounts();

  const addingCollateral = useAtomValue(addingCollateralAtom);

  const slippage = useAtomValue(slippageAtom);

  const levX = useLevX({
    longMarket,
    shortMarket,
    exactAmount: exactField.value,
    exactSide: exactField.side,
    collateralTokenAmount: addingCollateral
      ? new TokenAmount(
          addingCollateral.useUnwrappedMarket
            ? getMarketUnwrappedToken(pool, addingCollateral.market)
            : getMarketErc20Token(addingCollateral.market),
          addingCollateral.amount,
        )
      : undefined,
    slippage,
  });

  const onLongValueChange = useCallback(
    (value: string) => {
      setExactField({
        value,
        side: "long",
      });
    },
    [setExactField],
  );

  const onShortValueChange = useCallback(
    (value: string) => {
      setExactField({
        value,
        side: "short",
      });
    },
    [setExactField],
  );

  const onLeverageChange =
    levX !== undefined
      ? (value: string) => {
          const leverage = parseFloat(value);
          const exactAmount = levX.getExactAmountByLeverage(leverage);
          setExactField({
            side: exactField.side,
            value: exactAmount,
          });
        }
      : undefined;

  const marketsList = useMemo(
    () => (markets || []).map((market) => market.marketSymbol).join(","),
    [markets],
  );

  useEffect(() => {
    if (!pool || !markets) {
      return;
    }
    const wethMarket = markets.find(
      (market) => market.marketSymbol.toLocaleUpperCase() === "WETH",
    );
    const usdbMarket = markets.find(
      (market) => market.marketSymbol.toLocaleUpperCase() === "USDB",
    );
    if (!longMarket && wethMarket) setLongMarket(wethMarket);
    if (!shortMarket && usdbMarket) setShortMarket(usdbMarket);
  }, [
    pool,
    marketsList,
    setLongMarket,
    setShortMarket,
    markets,
    longMarket,
    shortMarket,
  ]);

  useEffect(() => {
    return () => {
      setLongMarket(undefined);
      setShortMarket(undefined);
    };
  }, [setLongMarket, setShortMarket]);

  const estAmountStr =
    estAmount !== undefined && estMarket !== undefined
      ? formatUnits(estAmount, estMarket.marketDecimals)
      : "";

  const longAmount =
    exactField.side === "long" ? exactField.value : estAmountStr;
  const shortAmount =
    exactField.side === "short" ? exactField.value : estAmountStr;

  const onSuccess = () => {
    setExactField({
      side: exactField.side,
      value: "",
    });
  };

  const marketOptions = markets?.filter(canLeverage);

  return (
    <>
      <Stack gap={0}>
        <CollateralView />
        <Divider borderColor="ui.tag" mt={6} />
        <Stack p={6} pb={0} gap={6}>
          <AssetSelector
            label={
              <Heading
                textColor="color.long"
                fontWeight={600}
                fontSize={16}
                lineHeight={24 / 16}
              >
                Long
              </Heading>
            }
            modalTitle="Select Long Token"
            showModalSupplyAPY
            options={marketOptions || []}
            value={longMarket}
            onChange={setLongMarket}
            amount={longAmount}
            onAmountChange={onLongValueChange}
            isInValidAmount={false}
            readOnly={isDisconnected}
          />
          <AssetSelector
            label={
              <Heading
                textColor="color.short"
                fontWeight={600}
                fontSize={16}
                lineHeight={24 / 16}
              >
                Short
              </Heading>
            }
            modalTitle="Select Short Token"
            showModalBorrowAPY
            options={marketOptions || []}
            value={shortMarket}
            onChange={setShortMarket}
            amount={shortAmount}
            onAmountChange={onShortValueChange}
            isInValidAmount={false}
            readOnly={isDisconnected}
          />
          <LeverageSelector levX={levX} min={0.1} onChange={onLeverageChange} />
          {isDesktop && (
            <SubmitTxsButton
              getExtensionActions={levX?.getExtensionActions}
              onSuccess={onSuccess}
              error={levX?.error}
              nextApprovalToken={levX?.nextApprovalToken}
              isLoading={isEstAmountLoading}
            />
          )}
        </Stack>
      </Stack>
      {isMobile && (
        <MobileCTAPanel>
          <SubmitTxsButton
            getExtensionActions={levX?.getExtensionActions}
            nextApprovalToken={levX?.nextApprovalToken}
            onSuccess={onSuccess}
            error={levX?.error}
          />
        </MobileCTAPanel>
      )}
    </>
  );
};

const LevXClosePanel = () => {
  const { isDesktop, isMobile } = useMediaQuery();
  const { pool } = usePool();
  const { marketsByAddress } = usePoolMarkets(pool);
  const { isDisconnected } = useAccount();
  const { userSupplyMarkets, userBorrowMarkets } = useUserPoolMarkets(pool);

  const [longMarket, setLongMarket] = useAtom(longMarketAtom);
  const [shortMarket, setShortMarket] = useAtom(shortMarketAtom);

  const [exactField, setExactField] = useAtom(closeExactFieldAtom);

  const slippage = useAtomValue(slippageAtom);

  const { exactSide, exactAmount } = useLesserSide({
    exactSide: exactField.side,
    exactAmount: exactField.value,
    longMarket,
    shortMarket,
  });

  const levX = useLevXClose({
    closeAll: exactField.side === "lesser",
    longMarket,
    shortMarket,
    exactSide,
    exactAmount,
    slippage,
  });

  const onLongValueChange = useCallback(
    (value: string) => {
      setExactField({
        value,
        side: "long",
      });
    },
    [setExactField],
  );

  const onShortValueChange = useCallback(
    (value: string) => {
      setExactField({
        value,
        side: "short",
      });
    },
    [setExactField],
  );

  const longAmount =
    exactField.side !== "long"
      ? levX?.longAmount !== undefined && longMarket
        ? formatUnits(levX?.longAmount, longMarket?.marketDecimals)
        : "undefined"
      : exactField.value;
  const shortAmount =
    exactField.side !== "short"
      ? levX?.shortAmount !== undefined && shortMarket
        ? formatUnits(levX?.shortAmount, shortMarket?.marketDecimals)
        : "undefined"
      : exactField.value;

  const longMarkets = userSupplyMarkets
    .map((m) => marketsByAddress[m.market])
    .filter(canLeverage);
  const shortMarkets = userBorrowMarkets
    .map((m) => marketsByAddress[m.market])
    .filter(canLeverage);

  useEffect(() => {
    if (!longMarket && !shortMarket) {
      setLongMarket(longMarkets[0]);
      setShortMarket(
        shortMarkets.find(
          (shortMarket) => shortMarket.market !== longMarkets[0].market,
        ),
      );
    }
  }, [
    longMarket,
    longMarkets,
    setLongMarket,
    setShortMarket,
    shortMarket,
    shortMarkets,
  ]);

  useEffect(() => {
    return () => {
      setLongMarket(undefined);
      setShortMarket(undefined);
    };
  }, [setLongMarket, setShortMarket]);

  const onSuccess = () => {
    setExactField({
      side: exactField.side,
      value: "",
    });
  };

  return (
    <>
      <Stack gap={0}>
        <Divider borderColor="ui.tag" mt={6} />
        <Stack p={6} pb={0} gap={4}>
          <AssetSelector
            label={
              <Heading
                textColor="color.label"
                fontWeight={600}
                fontSize={16}
                lineHeight={24 / 16}
              >
                Close Long
              </Heading>
            }
            modalTitle="Select Long Token to Close"
            showModalSupplyAPY
            options={longMarkets || []}
            value={longMarket}
            onChange={setLongMarket}
            amount={longAmount}
            onAmountChange={onLongValueChange}
            isInValidAmount={false}
            readOnly={isDisconnected}
          />
          <AssetSelector
            label={
              <Heading
                textColor="color.label"
                fontWeight={600}
                fontSize={16}
                lineHeight={24 / 16}
              >
                Close Short
              </Heading>
            }
            modalTitle="Select Short Token to Close"
            showModalBorrowAPY
            options={shortMarkets || []}
            value={shortMarket}
            onChange={setShortMarket}
            amount={shortAmount}
            onAmountChange={onShortValueChange}
            isInValidAmount={false}
            readOnly={isDisconnected}
          />
          <HStack>
            <Button
              w="auto"
              fontSize={14}
              fontWeight={600}
              lineHeight={22 / 14}
              variant="link"
              color="ui.tag"
              _disabled={{ color: "ui.disabled" }}
              onClick={() => {
                setExactField({ side: "lesser", value: "" });
              }}
              isDisabled={isDisconnected || exactField.side === "lesser"}
            >
              Close All
            </Button>
          </HStack>
          {isDesktop && (
            <SubmitTxsButton
              getExtensionActions={levX?.getExtensionActions}
              onSuccess={onSuccess}
              error={levX?.error}
            />
          )}
        </Stack>
      </Stack>
      {isMobile && (
        <MobileCTAPanel>
          <SubmitTxsButton
            getExtensionActions={levX?.getExtensionActions}
            onSuccess={onSuccess}
            error={levX?.error}
          />
        </MobileCTAPanel>
      )}
    </>
  );
};

const MobileCTAPanel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      w="100%"
      px={6}
      pt={6}
      pb={12}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      zIndex={500} // Web3 Modal has a default z-index 999 (--w3m-z-index). Do not overlay it
      backgroundColor="black"
      borderTop="1px solid #313131"
      boxShadow="0 0 15px rgba(0,0,0, 0.05)"
    >
      {children}
    </Box>
  );
};
