import { Pool } from "@/configs/chains.ts";
import { Asset } from "@/features/earn/components/Asset.tsx";
import { SupplyAndRewardApy } from "@/features/earn/components/SupplyAndRewardApy.tsx";
import { SupplyButton } from "@/features/earn/components/SupplyButton.tsx";
import { WithdrawButton } from "@/features/earn/components/WithdrawButton.tsx";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Market } from "@/types.ts";
import { YesAddress } from "@/utils/constants.ts";
import { HStack, Stack } from "@chakra-ui/react";
import { filter } from "lodash-es";
import { useMemo } from "react";
import { isAddressEqual } from "viem";

interface SimpleMarketCardProps {
  pool: Pool;
  market: Market;
}

const SimpleMarketCard = ({ pool, market }: SimpleMarketCardProps) => {
  return (
    <Stack>
      <HStack justifyContent="space-between">
        <Asset market={market} />
        <SupplyAndRewardApy pool={pool} market={market} />
      </HStack>
      <HStack>
        <SupplyButton pool={pool} market={market} />
        <WithdrawButton pool={pool} market={market} />
      </HStack>
    </Stack>
  );
};

interface SimpleMarketCardListProps {
  pool: Pool;
}

export const SimpleMarketCardList = ({ pool }: SimpleMarketCardListProps) => {
  const { markets } = usePoolMarkets(pool);
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const filteredMarkets = useMemo(
    () =>
      filter(markets, (m) => {
        if (isAddressEqual(m.market, YesAddress)) {
          const yesUserMarket = userMarketsByAddress[m.market];
          if (!yesUserMarket) {
            return false;
          }
          return (
            yesUserMarket.supplyBalance !== 0n ||
            yesUserMarket.borrowBalance !== 0n
          );
        }
        return true;
      }),
    [markets, userMarketsByAddress],
  );

  return (
    <Stack spacing={8}>
      {filteredMarkets?.map((market) => (
        <SimpleMarketCard key={market.market} market={market} pool={pool} />
      ))}
    </Stack>
  );
};
