import ActionItem from "@/components/ActionItem.tsx";
import StickyFooter from "@/components/StickyFooter.tsx";
import { UnapprovedTx } from "@/components/UnapprovedTx.tsx";
import SubmitTxsButton from "@/features/lending/components/buttons/SubmitTxsButton.tsx";
import { Tx, TxBuilderContext } from "@/providers/TxBuilder.tsx";
import {
  Button,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { map } from "lodash-es";
import { useContext } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

interface MobileActionEditorProps {
  onEditTx: (tx: Tx) => void;
}

const MobileActionEditor = ({ onEditTx }: MobileActionEditorProps) => {
  const { txs, unapprovedTxs, removeAllTx, removeTx } =
    useContext(TxBuilderContext);

  const { isOpen: isOpenList, onToggle } = useDisclosure();

  return (
    <StickyFooter>
      <Stack spacing={6}>
        <UnapprovedTx unapprovedTxs={unapprovedTxs} />
        <HStack justifyContent="space-between" onClick={() => onToggle()}>
          <HStack alignItems="center">
            <Text variant="subtitle4" color="white">
              {txs.length} Pending {txs.length > 1 ? "Actions" : "Action"}
            </Text>
            <IconButton
              variant="link"
              aria-label="show"
              onClick={() => onToggle()}
            >
              {isOpenList ? (
                <Icon as={RiArrowUpSLine} />
              ) : (
                <Icon as={RiArrowDownSLine} />
              )}
            </IconButton>
          </HStack>
          <HStack alignItems="center">
            <Button
              variant="link"
              color="primary.700"
              onClick={() => removeAllTx()}
            >
              Clear All
            </Button>
          </HStack>
        </HStack>
        <Collapse in={isOpenList} animateOpacity>
          <Stack spacing={0}>
            {map(txs, (tx) => (
              <ActionItem
                key={tx.market.market}
                isMobile={true}
                sx={{ pl: 0, pr: 0, py: 5 }}
                tx={tx}
                onClick={(tx) => onEditTx(tx)}
                onRemove={(tx) => removeTx(tx.market)}
              />
            ))}
          </Stack>
        </Collapse>
        <SubmitTxsButton />
      </Stack>
    </StickyFooter>
  );
};

export default MobileActionEditor;
