import TokenIcon from "@/components/TokenIcon.tsx";
import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";

interface RewardTokenProps extends BoxProps {
  symbol: string;
  text?: string;
}

export const RewardToken = ({
  symbol,
  text,
  ...boxProps
}: RewardTokenProps) => {
  return (
    <Box {...boxProps}>
      <HStack spacing={1}>
        <TokenIcon symbol={symbol} boxSize={6} />
        <Text fontSize="0.875rem">{text ?? symbol.toUpperCase()}</Text>
      </HStack>
    </Box>
  );
};
