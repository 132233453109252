import { statTabAtom } from "@/features/leverage/states/states";
import useMediaQuery from "@/hooks/useMediaQuery";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Flex,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  useAccordionContext,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { CustomTabList } from "./CustomTabList";
import { HistoryView } from "./HistoryView";
import { PositionView } from "./PositionView";

export const StatsCard = () => {
  return (
    <AccordionProvider>
      <TabsProvider>
        <StatsCardContent />
      </TabsProvider>
    </AccordionProvider>
  );
};

const AccordionProvider = ({ children }: { children: React.ReactNode }) => {
  const { isDesktop } = useMediaQuery();

  return (
    <Accordion defaultIndex={isDesktop ? [0] : []}>
      <AccordionItem border={"none"}>
        {/* This AccordionButton from library is required for some reason */}
        <AccordionButton display="none" />
        {children}
      </AccordionItem>
    </Accordion>
  );
};

const TabsProvider = ({ children }: { children: React.ReactNode }) => {
  const [tab, setTab] = useAtom(statTabAtom);
  const { setIndex: setAccordionIndex } = useAccordionContext();

  const tabIndex = tab === "history" ? 0 : 1;
  const onChange = (index: number) => {
    setTab(index === 0 ? "history" : "position");
    setAccordionIndex([0]);
  };

  return (
    <Tabs variant="unstyled" isLazy index={tabIndex} onChange={onChange}>
      {children}
    </Tabs>
  );
};

const CustomAccordionButton = () => {
  const { index, setIndex } = useAccordionContext();

  const toggleAccordion = () =>
    setIndex((index as number[]).includes(0) ? [] : [0]);
  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      onClick={toggleAccordion}
      w={16}
      h={16}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
    >
      <AccordionIcon />
    </Flex>
  );
};

const StatsCardContent = () => {
  return (
    <Card py={6}>
      <CustomAccordionButton />
      <CustomTabList tabs={["History", "Position"]} />
      <AccordionPanel p={0}>
        <Stack>
          <TabPanels>
            <TabPanel pb={0}>
              <HistoryView />
            </TabPanel>
            <TabPanel pb={0}>
              <PositionView />
            </TabPanel>
          </TabPanels>
        </Stack>
      </AccordionPanel>
    </Card>
  );
};
