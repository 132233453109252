import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useWatchBlockNumber } from "wagmi";

interface BlockNumberContextType {
  blockNumber?: bigint;
  fastForward: (blockNumber: bigint) => void;
}

export const BlockNumberContext = createContext<BlockNumberContextType | null>(
  null,
);

export function BlockNumberProvider({ children }: { children: ReactNode }) {
  const [blockNumber, setBlockNumber] = useState<bigint>();

  const onBlockNumber = useCallback((newBlockNumber: bigint) => {
    setBlockNumber((blockNumber) => {
      if (blockNumber === undefined || newBlockNumber > blockNumber) {
        return newBlockNumber;
      }
      return blockNumber;
    });
  }, []);

  useWatchBlockNumber({
    onBlockNumber,
    emitOnBegin: true,
    pollingInterval: 15 * 1000,
  });

  const value = useMemo<BlockNumberContextType>(() => {
    return {
      blockNumber,
      fastForward: onBlockNumber,
    };
  }, [blockNumber, onBlockNumber]);

  return (
    <BlockNumberContext.Provider value={value}>
      {children}
    </BlockNumberContext.Provider>
  );
}
