import { usePool } from "@/hooks/usePool.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { useUserStats } from "@/hooks/useUserStats";
import { Market, MarketAction, UserMarket, UserStats } from "@/types";
import { MaxUint256 } from "@/utils/constants";
import {
  getNewBorrowStats,
  getUnderlyingBorrowLimit,
  getUnderlyingWithdrawLimit,
} from "@/utils/market";
import { isValidTxResult } from "@/utils/txBuilder";
import { getUserUnwrappedMarket } from "@/utils/wrappedMarket";
import { every, filter, keyBy, min, reduce } from "lodash-es";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isAddressEqual } from "viem";
import { useAccount } from "wagmi";

export type Tx = {
  market: Market;
  action: MarketAction;
  amount: bigint;
  useUnwrappedMarket: boolean;
};

export type TxResult = {
  tx: Tx;
  error: string | null;
  borrowStats: UserStats;
  maxAmount: bigint;
};

const defaultBorrowStats: UserStats = {
  borrowBalance: 0n,
  supplyBalance: 0n,
  borrowLimit: 0n,
  borrowPct: 0,
  netInterestValue: 0n,
  netApy: 0,
  liquidationThreshold: 0n,
  liquidationPct: 0,
};

interface Context {
  isLoading: boolean;
  isReady: boolean; // whether txs are ready to be sent
  txs: Tx[];
  addTx: (tx: Tx) => void;
  prepareTx: (tx: Tx) => TxResult;
  checkTx: (priorBorrowStats: UserStats, tx: Tx) => TxResult;
  removeTx: (market: Market) => void;
  removeAllTx: () => void;
  modifyTx: (market: Market, amount: bigint) => void;
  hasMarketTx: (market: Market) => boolean;
  getTxResult: (market: Market) => TxResult;
  finalBorrowStats: UserStats;
  unapprovedTxs: Tx[];
}

export const TxBuilderContext = createContext<Context>({
  isLoading: true,
  isReady: false,
  txs: [],
  addTx: () => undefined,
  prepareTx: () => {
    throw Error("not implemented");
  },
  checkTx: () => {
    throw Error("not implemented");
  },
  removeTx: () => undefined,
  removeAllTx: () => undefined,
  modifyTx: () => undefined,
  hasMarketTx: () => false,
  getTxResult: () => {
    throw Error("not implemented");
  },
  finalBorrowStats: defaultBorrowStats,
  unapprovedTxs: [],
});

function checkSupplyTx(
  priorBorrowStats: UserStats,
  tx: Tx,
  userMarket: UserMarket,
): TxResult {
  const { action, amount, market } = tx;

  let userBalance;
  if (tx.useUnwrappedMarket) {
    userBalance = getUserUnwrappedMarket(userMarket).balance;
  } else {
    userBalance = userMarket.balance;
  }

  let error = null;
  if (amount <= 0n) {
    error = "Invalid amount.";
  } else if (userBalance < amount) {
    error = "Insufficient balance.";
  } else if (market.supplyCap > 0n) {
    const totalSupply = market.totalCash + market.totalBorrow;
    const supplyQuota = market.supplyCap - totalSupply;
    if (supplyQuota < amount) {
      error = "Supply cap reached.";
    }
  }

  return {
    tx,
    error,
    borrowStats: getNewBorrowStats(priorBorrowStats, market, action, amount),
    maxAmount: userBalance,
  };
}

function checkBorrowTx(priorBorrowStats: UserStats, tx: Tx): TxResult {
  const { action, amount, market } = tx;

  const marketBorrowLeft =
    market.borrowCap === 0n
      ? MaxUint256
      : market.borrowCap > market.totalBorrow
        ? market.borrowCap - market.totalBorrow
        : 0n;

  const underlyingBorrowLeft = getUnderlyingBorrowLimit(
    market,
    priorBorrowStats.borrowLimit - priorBorrowStats.borrowBalance,
  );

  const maxBorrowableAmount = min([
    marketBorrowLeft,
    underlyingBorrowLeft,
    market.totalCash,
  ]) as bigint;

  let error = null;
  if (amount <= 0n) {
    error = "Invalid amount.";
  } else if (amount > maxBorrowableAmount) {
    error = "Exceeded maximum borrow amount.";
  }

  return {
    tx,
    error,
    borrowStats: getNewBorrowStats(priorBorrowStats, market, action, amount),
    maxAmount: maxBorrowableAmount,
  };
}

function checkWithdrawTx(
  priorBorrowStats: UserStats,
  tx: Tx,
  userMarket: UserMarket,
): TxResult {
  const { action, amount, market } = tx;

  const maxWithdrawableAmount = (() => {
    if (priorBorrowStats.borrowBalance === 0n) {
      return min([userMarket.supplyBalance, market.totalCash]) as bigint;
    }

    const withdrawLimit =
      priorBorrowStats.borrowLimit >= priorBorrowStats.borrowBalance
        ? priorBorrowStats.borrowLimit - priorBorrowStats.borrowBalance
        : 0n;
    const underlyingWithdrawLimit = getUnderlyingWithdrawLimit(
      market,
      withdrawLimit,
    );

    return min([
      userMarket.supplyBalance,
      underlyingWithdrawLimit,
      market.totalCash,
    ]) as bigint;
  })();

  const newBorrowStats = getNewBorrowStats(
    priorBorrowStats,
    market,
    action,
    amount,
  );

  let error = null;
  if (amount <= 0n) {
    error = "Invalid amount.";
  } else if (amount > userMarket.supplyBalance) {
    error = "Withdrawing more than supplied.";
  } else if (amount > market.totalCash) {
    error = "Insufficient liquidity.";
  }

  return {
    tx,
    error,
    borrowStats: newBorrowStats,
    maxAmount: maxWithdrawableAmount,
  };
}

function checkRepayTx(
  priorBorrowStats: UserStats,
  tx: Tx,
  userMarket: UserMarket,
): TxResult {
  const { action, amount, market } = tx;

  let userBalance;
  if (tx.useUnwrappedMarket) {
    userBalance = getUserUnwrappedMarket(userMarket).balance;
  } else {
    userBalance = userMarket.balance;
  }

  let error = null;
  if (amount <= 0n) {
    error = "Invalid amount.";
  } else if (amount > userBalance) {
    error = "Insufficient balance.";
  } else if (amount > userMarket.borrowBalance) {
    error = "Exceed borrowed amount.";
  }

  const maxRepayAmount = min([userBalance, userMarket.borrowBalance]) as bigint;

  return {
    tx,
    error,
    borrowStats: getNewBorrowStats(priorBorrowStats, market, action, amount),
    maxAmount: maxRepayAmount,
  };
}

export function TxBuilderProvider({ children }: { children: React.ReactNode }) {
  const { address } = useAccount();

  const { pool } = usePool();
  const { userMarketsByAddress, isLoading } = useUserPoolMarkets(pool);

  const borrowStats = useUserStats();

  const [txs, setTxs] = useState<Tx[]>([]);

  const hasMarketTx = useCallback(
    (market: Market) => {
      return !!txs.find((a) => isAddressEqual(a.market.market, market.market));
    },
    [txs],
  );

  const finalBorrowStats = address
    ? reduce(
        txs,
        (prev, tx) => {
          return getNewBorrowStats(prev, tx.market, tx.action, tx.amount);
        },
        borrowStats,
      )
    : borrowStats;

  const unapprovedTxs = useMemo(
    () =>
      address
        ? filter(txs, (tx) => {
            if (tx.action === "borrow" || tx.action === "withdraw") {
              return false;
            }

            const userMarket = userMarketsByAddress[tx.market.market];

            if (tx.useUnwrappedMarket) {
              const unwrappedMarket = getUserUnwrappedMarket(userMarket);
              return unwrappedMarket.allowance < tx.amount;
            }

            return userMarket.allowanceToAlien < tx.amount;
          })
        : [],
    [address, txs, userMarketsByAddress],
  );

  const checkTx = useCallback(
    (priorBorrowStats: UserStats, tx: Tx) => {
      const { action, market } = tx;

      const userMarket = userMarketsByAddress[market.market];
      if (!userMarket) {
        throw Error(`user market not found: ${market.marketSymbol}`);
      }

      if (action === "supply") {
        return checkSupplyTx(priorBorrowStats, tx, userMarket);
      } else if (action === "borrow") {
        return checkBorrowTx(priorBorrowStats, tx);
      } else if (action === "withdraw") {
        return checkWithdrawTx(priorBorrowStats, tx, userMarket);
      } else if (action == "repay") {
        return checkRepayTx(priorBorrowStats, tx, userMarket);
      } else {
        throw Error(`invalid action`);
      }
    },
    [userMarketsByAddress],
  );

  const addTx = useCallback(
    (tx: Tx) => {
      const { action, amount, useUnwrappedMarket, market } = tx;

      if (hasMarketTx(market)) {
        throw Error(`market exists: ${market.marketSymbol}`);
      }

      const txResult = checkTx(finalBorrowStats, tx);
      if (!isValidTxResult(txResult)) {
        throw Error(`invalid tx: ${txResult.error}`);
      }

      setTxs([
        ...txs,
        { market, action, amount, useUnwrappedMarket: useUnwrappedMarket },
      ]);
    },
    [checkTx, finalBorrowStats, hasMarketTx, txs],
  );

  const removeTx = useCallback(
    (market: Market) => {
      setTxs(
        txs.filter((tx) => !isAddressEqual(tx.market.market, market.market)),
      );
    },
    [txs],
  );

  const removeAllTx = () => {
    setTxs([]);
  };

  const txResults = address
    ? (() => {
        const txResults: TxResult[] = [];
        let priorBorrowStats = borrowStats;

        for (const tx of txs) {
          const result = checkTx(priorBorrowStats, tx);
          txResults.push(result);
          priorBorrowStats = result.borrowStats;
        }

        return txResults;
      })()
    : [];

  const txResultsByMarketAddress = keyBy(txResults, (r) => r.tx.market.market);

  const getTxResult = useCallback(
    (market: Market) => {
      return txResultsByMarketAddress[market.market];
    },
    [txResultsByMarketAddress],
  );

  const modifyTx = useCallback(
    (market: Market, amount: bigint) => {
      setTxs(
        txs.map((tx) => {
          if (!isAddressEqual(tx.market.market, market.market)) {
            return tx;
          }

          const txResult = getTxResult(tx.market);
          if (!isValidTxResult(checkTx(txResult.borrowStats, tx))) {
            throw Error("invalid tx");
          }

          return {
            ...tx,
            amount,
          };
        }),
      );
    },
    [checkTx, getTxResult, txs],
  );

  const prepareTx = useCallback(
    (_tx: Tx) => {
      let priorBorrowStats = borrowStats;

      for (const tx of txs) {
        const result = checkTx(priorBorrowStats, tx);
        priorBorrowStats = result.borrowStats;

        if (isAddressEqual(tx.market.market, _tx.market.market)) {
          return result;
        }
      }

      return checkTx(priorBorrowStats, _tx);
    },
    [borrowStats, checkTx, txs],
  );

  const isReady =
    unapprovedTxs.length === 0 && every(txResults, isValidTxResult);

  // remove all txs when address changes
  useEffect(() => {
    removeAllTx();
  }, [address]);

  const context = useMemo(
    () => ({
      isLoading,
      isReady,
      txs,
      addTx,
      prepareTx,
      checkTx,
      removeTx,
      removeAllTx,
      modifyTx,
      hasMarketTx,
      getTxResult,
      finalBorrowStats,
      unapprovedTxs,
    }),
    [
      addTx,
      checkTx,
      finalBorrowStats,
      getTxResult,
      hasMarketTx,
      isLoading,
      isReady,
      modifyTx,
      prepareTx,
      removeTx,
      txs,
      unapprovedTxs,
    ],
  );

  return (
    <TxBuilderContext.Provider value={context}>
      {children}
    </TxBuilderContext.Provider>
  );
}

export default TxBuilderProvider;
