import { FactorScale } from "@/utils/constants";
import BigNumber from "bignumber.js";
import { formatEther, formatUnits } from "viem";

export function commify(n: string | number | bigint): string {
  const parts = n.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function toDigits(
  n: string | number | BigNumber,
  digits: number,
  _commify = true,
): string {
  if (new BigNumber(n).isZero()) return "0";

  const m = new BigNumber(n).toFixed(digits);
  return _commify ? commify(m) : m;
}

export function formatBigInt(n: bigint, decimals: number, digits: number) {
  return toDigits(formatUnits(n, decimals), digits);
}

export function formatRate(rate: bigint, digits = 2) {
  if (rate === 0n) {
    return "0%";
  }

  const bn = BigNumber.clone({ POW_PRECISION: 6 });
  const apy = bn(formatEther(rate))
    .plus(1)
    .pow(31536000)
    .minus(1)
    .multipliedBy(100)
    .toFormat(digits);
  return `${apy}%`;
}

export function shortAddress(address: string) {
  return `${address.substring(0, 6)}...${address.substring(38)}`;
}

export function formatCollateralFactor(cf: number, digits = 0) {
  const pct = (cf / Number(FactorScale)) * 100;
  return commify(pct.toFixed(digits)) + "%";
}

export const formatLiquidationThreshold = formatCollateralFactor;

export function formatPercentage(n: number, digits: number) {
  return BigNumber(n * 100).toFixed(digits) + "%";
}
