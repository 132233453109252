import BalanceInput from "@/components/BalanceInput.tsx";
import TokenIcon from "@/components/TokenIcon.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { NumberString, TxPreparation } from "@/types.ts";
import { getMarketUsdPrice } from "@/utils/market.ts";
import { isValidTxResult } from "@/utils/txBuilder.ts";
import { findWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Button, Card, HStack, Stack, Text } from "@chakra-ui/react";
import { capitalize } from "lodash-es";
import { useContext, useState } from "react";
import { formatUnits, parseUnits } from "viem";

interface BalanceInputCardProps {
  txPreparation: TxPreparation;
  onCloseTx: () => void;
}

const BalanceInputCard = ({
  txPreparation,
  onCloseTx,
}: BalanceInputCardProps) => {
  const defaultAmount = txPreparation.amount
    ? formatUnits(txPreparation.amount, txPreparation.market.marketDecimals)
    : "";

  const { addTx, modifyTx, hasMarketTx } = useContext(TxBuilderContext);

  const isModifying = hasMarketTx(txPreparation.market);

  const [amount, setAmount] = useState(defaultAmount);

  const { marketSymbol, marketDecimals } = txPreparation.market;

  const { pool } = usePool();

  const symbol = txPreparation.useUnwrappedMarket
    ? findWrappedMarket(txPreparation.market, pool).unwrappedSymbol
    : marketSymbol;

  const { prepareTx } = useContext(TxBuilderContext);

  const txResult = prepareTx({
    market: txPreparation.market,
    action: txPreparation.marketAction,
    amount: parseUnits(amount as NumberString, marketDecimals),
    useUnwrappedMarket: !!txPreparation.useUnwrappedMarket,
  });

  return (
    <Card p={6}>
      <Stack spacing={6}>
        <HStack spacing={2} mb={6}>
          <TokenIcon symbol={symbol} boxSize={8} />
          <Text variant="subtitle2" color="text.primary">
            {capitalize(txPreparation.marketAction)} {symbol}
          </Text>
        </HStack>
        <BalanceInput
          value={amount}
          maxValue={formatUnits(txResult.maxAmount, marketDecimals)}
          usdRate={getMarketUsdPrice(txPreparation.market)}
          onChange={setAmount}
          maxValuePrefixText="Available: "
        />
        <HStack justifyContent="center" spacing={3}>
          <Button
            variant="primary"
            w="full"
            isDisabled={!isValidTxResult(txResult)}
            onClick={() => {
              if (isModifying) {
                modifyTx(
                  txPreparation.market,
                  parseUnits(amount as NumberString, marketDecimals),
                );
              } else {
                addTx({
                  market: txPreparation.market,
                  action: txPreparation.marketAction,
                  amount: parseUnits(amount as NumberString, marketDecimals),
                  useUnwrappedMarket: !!txPreparation.useUnwrappedMarket,
                });
              }

              onCloseTx();
            }}
          >
            {isModifying ? "Update Action" : "Add Action"}
          </Button>
          <Button
            variant="solid"
            bgColor="ui.disabled"
            color="text.primary"
            w="full"
            _hover={{ bgColor: "ui.disabled" }}
            onClick={() => onCloseTx()}
          >
            Cancel
          </Button>
        </HStack>
      </Stack>
    </Card>
  );
};

export default BalanceInputCard;
