import { SupplyRewardApy } from "@/components/SupplyRewardApy.tsx";
import Table from "@/components/Table.tsx";
import { Asset } from "@/features/lending/components/Asset.tsx";
import BorrowButton from "@/features/lending/components/buttons/BorrowButton.tsx";
import RepayButton from "@/features/lending/components/buttons/RepayButton.tsx";
import SupplyButton from "@/features/lending/components/buttons/SupplyButton.tsx";
import WithdrawButton from "@/features/lending/components/buttons/WithdrawButton.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Market, TxPreparation } from "@/types.ts";
import { YesAddress } from "@/utils/constants.ts";
import { formatRate } from "@/utils/format.ts";
import { isWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Box, HStack, Icon, IconButton, Stack, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { filter } from "lodash-es";
import { useMemo } from "react";
import { RiArrowLeftRightLine } from "react-icons/ri";
import { isAddressEqual } from "viem";

interface DesktopMarketTableProps {
  onPrepareTx: (txPreparation: TxPreparation) => void;
  onToggleWrappedMarket: (market: Market) => void;
  usingUnwrappedMarket: (market: Market) => boolean;
}

const columnHelper = createColumnHelper<Market>();

const DesktopMarketTable = ({
  onPrepareTx,
  onToggleWrappedMarket,
  usingUnwrappedMarket,
}: DesktopMarketTableProps) => {
  const { pool } = usePool();
  const { markets } = usePoolMarkets(pool);
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const filteredMarkets = filter(markets, (m) => {
    if (isAddressEqual(m.market, YesAddress)) {
      const yesUserMarket = userMarketsByAddress[m.market];
      if (!yesUserMarket) {
        return false;
      }
      return (
        yesUserMarket.supplyBalance !== 0n || yesUserMarket.borrowBalance !== 0n
      );
    }
    return true;
  });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "wrappedToken",
        header: "",
        cell: ({ row: { original: market } }) => {
          if (!isWrappedMarket(market, pool)) {
            return null;
          }

          return (
            <Box role="group">
              <IconButton
                aria-label="wrapped"
                color="primary.500"
                variant="link"
                onClick={() => onToggleWrappedMarket(market)}
              >
                <Icon as={RiArrowLeftRightLine} boxSize={5} />
              </IconButton>
            </Box>
          );
        },
        meta: {
          isIcon: true,
          maxWidth: "1.2rem",
        },
      }),
      columnHelper.display({
        header: "Asset",
        cell: ({ row: { original: market } }) => (
          <Asset
            market={market}
            userMarket={userMarketsByAddress[market.market]}
            useUnwrappedMarket={usingUnwrappedMarket(market)}
          />
        ),
      }),
      columnHelper.accessor("supplyRate", {
        header: "Supply APY",
        cell: ({ row: { original: market } }) => {
          const { supplyRate, isPToken } = market;
          return (
            <Stack alignItems="flex-end" spacing={1}>
              <Text
                variant="subtitle3"
                color={
                  supplyRate === 0n && isPToken ? "red.500" : "text.primary"
                }
              >
                {formatRate(supplyRate, 2)}
              </Text>
              <SupplyRewardApy market={market} pool={pool} />
            </Stack>
          );
        },
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor("borrowRate", {
        header: "Borrow APY",
        cell: ({ getValue }) => {
          const borrowRate = getValue();

          return (
            <Stack alignItems="flex-end" spacing={1}>
              <Text variant="subtitle3" color="text.primary">
                {formatRate(borrowRate, 2)}
              </Text>
              {/*<HStack spacing={1}>*/}
              {/*  <Text variant="subtitle5" color="primary.700">*/}
              {/*    ####*/}
              {/*    /!*{row.original.borrowRate}%*!/*/}
              {/*  </Text>*/}
              {/*  <TokenIcon symbol="ib" boxSize={4} />*/}
              {/*</HStack>*/}
            </Stack>
          );
        },
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.display({
        id: "supply",
        header: "Supply",
        cell: ({ row: { original: market } }) => (
          <HStack justifyContent="flex-end">
            <SupplyButton
              market={market}
              userMarket={userMarketsByAddress[market.market]}
              useUnwrappedMarket={usingUnwrappedMarket(market)}
              onClick={() =>
                onPrepareTx({
                  market,
                  marketAction: "supply",
                  useUnwrappedMarket: usingUnwrappedMarket(market),
                })
              }
            />
            <WithdrawButton
              market={market}
              userMarket={userMarketsByAddress[market.market]}
              useUnwrappedMarket={usingUnwrappedMarket(market)}
              onClick={() =>
                onPrepareTx({
                  market,
                  marketAction: "withdraw",
                  useUnwrappedMarket: usingUnwrappedMarket(market),
                })
              }
            />
          </HStack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.display({
        id: "borrow",
        header: "Borrow",
        cell: ({ row: { original: market } }) => (
          <HStack justifyContent="flex-end">
            <BorrowButton
              market={market}
              userMarket={userMarketsByAddress[market.market]}
              useUnwrappedMarket={usingUnwrappedMarket(market)}
              onClick={() =>
                onPrepareTx({
                  market,
                  marketAction: "borrow",
                  useUnwrappedMarket: usingUnwrappedMarket(market),
                })
              }
            />
            <RepayButton
              market={market}
              userMarket={userMarketsByAddress[market.market]}
              useUnwrappedMarket={usingUnwrappedMarket(market)}
              onClick={() =>
                onPrepareTx({
                  market,
                  marketAction: "repay",
                  useUnwrappedMarket: usingUnwrappedMarket(market),
                })
              }
            />
          </HStack>
        ),
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [
      pool,
      onToggleWrappedMarket,
      userMarketsByAddress,
      usingUnwrappedMarket,
      onPrepareTx,
    ],
  );

  return <Table columns={columns} data={filteredMarkets || []} px={1} />;
};

export default DesktopMarketTable;
