import { PriceOracle } from "@/contracts/PriceOracle.ts";
import { UniswapV3Pool } from "@/contracts/UniswapV3Pool.ts";
import BigNumber from "bignumber.js";
import { formatUnits } from "viem";
import { useReadContracts } from "wagmi";

type UseAlienPriceReturn =
  | {
      status: "error" | "pending";
      data: undefined;
    }
  | {
      status: "success";
      data: number;
    };

export const useAlienPrice = (): UseAlienPriceReturn => {
  const { data, status } = useReadContracts({
    contracts: [
      {
        abi: UniswapV3Pool,
        address: "0xa5989532604525eceA68835B6C2e592A945D1155", // YES-ALIEN pool
        functionName: "slot0",
      },
      {
        abi: UniswapV3Pool,
        address: "0x1d16788b97eDB7d9a6aE66D5C5C16469037Faa00", // YES-ETH pool
        functionName: "slot0",
      },
      {
        abi: PriceOracle,
        address: "0x3A9B69eE4b7F238c38380A540B211f682f724968",
        functionName: "getPrice",
        args: ["0x4300000000000000000000000000000000000004"],
      },
    ],
    allowFailure: false,
  });

  if (status !== "success") {
    return {
      status,
      data: undefined,
    };
  }

  // To calculate price in pool:
  // https://blog.uniswap.org/uniswap-v3-math-primer#how-do-i-calculate-the-current-exchange-rate
  const alienYesPrice = BigNumber(data[0][0].toString()) // price = alien / yes
    .div(BigNumber(2).exponentiatedBy(96))
    .exponentiatedBy(2)
    .toNumber();
  const ethYesPrice = BigNumber(data[1][0].toString()) // price = eth / yes
    .div(BigNumber(2).exponentiatedBy(96))
    .exponentiatedBy(2)
    .toNumber();
  const usdEthPrice = BigNumber(formatUnits(data[2], 18)).toNumber(); // price = usd / eth

  const price = (ethYesPrice * usdEthPrice) / alienYesPrice;

  return {
    status: "success",
    data: price,
  };
};
