import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BigNumber from "bignumber.js";
import { omit } from "lodash-es";
import { Address } from "viem";

type BlastPointsStatusData = Record<
  Address,
  Record<
    string,
    {
      rates: string;
      total: string;
      distributed: string;
    }
  >
>;

export const useBlastPointsStatus = () => {
  return useQuery({
    queryKey: ["blast-points-status"],
    queryFn: async () => {
      const { data } = await axios.get<BlastPointsStatusData>(
        "https://api.alien.finance/api/v1/blast-point/status",
      );
      return omit(data, ["WETH", "USDB", "BLAST"]) as BlastPointsStatusData;
    },
  });
};

export function getTotalDistributed(data: BlastPointsStatusData): string {
  let total = BigNumber(0);
  for (const poolAddress in data) {
    for (const symbol in data[poolAddress as Address]) {
      total = total.plus(data[poolAddress as Address][symbol].distributed);
    }
  }
  return total.shiftedBy(-12).toFixed(2);
}
