import { usePool } from "@/hooks/usePool.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Market, TxPreparation } from "@/types.ts";
import { isWrappedMarket } from "@/utils/wrappedMarket.ts";
import { Box, HStack, Icon, IconButton, Stack, Text } from "@chakra-ui/react";
import { RiArrowLeftRightLine } from "react-icons/ri";
import { Asset } from "./Asset.tsx";
import BorrowButton from "./buttons/BorrowButton.tsx";
import RepayButton from "./buttons/RepayButton.tsx";
import SupplyButton from "./buttons/SupplyButton.tsx";
import WithdrawButton from "./buttons/WithdrawButton.tsx";

interface MobileActionSelectorProps {
  market: Market;
  onPrepareTx: (txPreparation: TxPreparation) => void;
  usingUnwrappedMarket: (market: Market) => boolean;
  onToggleWrappedMarket: (market: Market) => void;
}
const MobileActionSelector = ({
  market,
  onPrepareTx,
  usingUnwrappedMarket,
  onToggleWrappedMarket,
}: MobileActionSelectorProps) => {
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  return (
    <Stack spacing={6}>
      <HStack spacing={2}>
        {isWrappedMarket(market, pool) && (
          <Box role="group">
            <IconButton
              aria-label="wrapped"
              color="primary.500"
              variant="link"
              onClick={() => onToggleWrappedMarket(market)}
            >
              <Icon as={RiArrowLeftRightLine} boxSize={5} />
            </IconButton>
          </Box>
        )}
        <Asset
          market={market}
          userMarket={userMarketsByAddress[market.market]}
          useUnwrappedMarket={usingUnwrappedMarket(market)}
        />
      </HStack>
      <Stack spacing={4}>
        <Text variant="subtitle3" color="text.secondary">
          Supply
        </Text>
        <HStack spacing={3}>
          <SupplyButton
            isMobile={true}
            market={market}
            userMarket={userMarketsByAddress[market.market]}
            useUnwrappedMarket={usingUnwrappedMarket(market)}
            onClick={() => {
              onPrepareTx({
                market,
                marketAction: "supply",
                useUnwrappedMarket: usingUnwrappedMarket(market),
              });
            }}
          />
          <WithdrawButton
            isMobile={true}
            market={market}
            userMarket={userMarketsByAddress[market.market]}
            useUnwrappedMarket={usingUnwrappedMarket(market)}
            onClick={() =>
              onPrepareTx({
                market,
                marketAction: "withdraw",
                useUnwrappedMarket: usingUnwrappedMarket(market),
              })
            }
          />
        </HStack>
      </Stack>
      <Stack spacing={4}>
        <Text variant="subtitle3" color="text.secondary">
          Borrow
        </Text>
        <HStack spacing={3}>
          <BorrowButton
            isMobile={true}
            market={market}
            userMarket={userMarketsByAddress[market.market]}
            useUnwrappedMarket={usingUnwrappedMarket(market)}
            onClick={() =>
              onPrepareTx({
                market,
                marketAction: "borrow",
                useUnwrappedMarket: usingUnwrappedMarket(market),
              })
            }
          />
          <RepayButton
            isMobile={true}
            market={market}
            userMarket={userMarketsByAddress[market.market]}
            useUnwrappedMarket={usingUnwrappedMarket(market)}
            onClick={() =>
              onPrepareTx({
                market,
                marketAction: "repay",
                useUnwrappedMarket: usingUnwrappedMarket(market),
              })
            }
          />
        </HStack>
      </Stack>
    </Stack>
  );
};

export default MobileActionSelector;
