import {
  Box,
  Button,
  Flex,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { floor, map, range, toString } from "lodash-es";
import { useRef } from "react";
import { RiQuestionLine } from "react-icons/ri";

import { UseLevXResult } from "@/hooks/useLevX";
import { ResponsiveTooltip } from "./ResponsiveTooltip";

type LeverageOption = {
  value: number;
  label: string;
};

interface LeverageSelectorProps {
  defaultValue?: number;
  levX: UseLevXResult | undefined;
  min: number;
  options?: LeverageOption[];
  onChange?: (value: string) => void;
}

export const LeverageSelector = ({
  levX,
  defaultValue = 1.5,
  min,
  options: userOptions,
  onChange,
}: LeverageSelectorProps) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const isDisabled = !levX || isNaN(levX.leverage);

  const { value, max } = (() => {
    if (isDisabled) {
      return {
        max: 5,
        value: min,
      };
    }
    return {
      max: Math.floor(levX.maxLeverage * 100) / 100,
      value: levX.leverage,
    };
  })();

  const getDefaultOptions = (): LeverageOption[] => {
    return map(
      [min, ...range(floor(min + 1), max > 5 ? max - 1 : max, 1), max],
      (value) => ({
        value,
        label: `${value.toFixed(1)}x`,
      }),
    );
  };

  const options = userOptions || getDefaultOptions();

  return (
    <Stack spacing={3} mb={6}>
      <HStack>
        <Flex flexDirection="row" gap={1} alignItems="center" ref={labelRef}>
          <Text variant="subtitle3">Leverage</Text>
          <ResponsiveTooltip
            hasArrow
            placement="top"
            label={
              <Box p={2}>
                <Text variant="body3" lineHeight="1rem">
                  Ratio based on borrow limit left
                </Text>
              </Box>
            }
            triggerRef={labelRef}
            userSelect="none"
          >
            <Box as="span">
              <RiQuestionLine size={20} color="text-secondary" />
            </Box>
          </ResponsiveTooltip>
        </Flex>
      </HStack>
      <HStack pl={2} ref={sliderRef} userSelect="none">
        <Slider
          colorScheme="primary"
          focusThumbOnChange={false}
          defaultValue={defaultValue}
          step={0.1}
          min={min}
          max={max}
          value={value}
          onChange={(value) => onChange?.(toString(value))}
          mr={6}
          isDisabled={isDisabled}
        >
          {map(options, ({ value: markValue, label }) => (
            <SliderMark
              key={markValue}
              value={markValue}
              top={0}
              zIndex={2}
              ml="-2.5"
            >
              <Stack justifyContent="center" alignItems="flex-start" pt="1">
                <Box
                  borderRadius="2px"
                  border="2px solid black"
                  transform="rotate(45deg)"
                  boxSize={3}
                  bg={value >= markValue ? "primary.500" : "ui.disabled"}
                />
                <Text
                  variant="subtitle3"
                  color="text.secondary"
                  backgroundColor="black"
                  paddingX={1}
                  marginLeft={-1}
                >
                  {label}
                </Text>
              </Stack>
            </SliderMark>
          ))}
          <SliderTrack bgColor="ui.disabled">
            <SliderFilledTrack />
          </SliderTrack>
          <ResponsiveTooltip
            hasArrow
            placement="top"
            label={`${Math.round(value * 100) / 100}x`}
            triggerRef={sliderRef}
          >
            <SliderThumb
              bgColor="black"
              borderColor="transparent"
              boxSize={5}
              zIndex={3}
              boxShadow="unset"
              _disabled={{
                bgColor: "black",
              }}
            >
              <Box
                bgColor="primary.700"
                boxSize={3}
                borderRadius="2px"
                transform="rotate(45deg)"
              />
            </SliderThumb>
          </ResponsiveTooltip>
        </Slider>
        <Button
          onClick={() => {
            onChange?.(BigNumber(max).toFixed(2, BigNumber.ROUND_FLOOR));
          }}
          variant="ghost"
          color="primary.500"
          isDisabled={isDisabled}
        >
          Max
        </Button>
      </HStack>
    </Stack>
  );
};

export default LeverageSelector;
