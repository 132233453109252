import { Pool } from "@/configs/chains";
import { Market, MarketAction, UserStats } from "@/types";
import { Erc20Token, NativeToken } from "@/types/token";
import { FactorScale, WeiPerEth } from "@/utils/constants";
import BigNumber from "bignumber.js";
import { formatEther, formatUnits, parseUnits } from "viem";
import { findWrappedMarket } from "./wrappedMarket";

export function getMarketBorrowLimit(market: Market, amount: bigint) {
  const { collateralFactor, marketPrice } = market;

  return (
    (amount * BigInt(collateralFactor) * BigInt(marketPrice)) /
    FactorScale /
    WeiPerEth
  );
}

export function getMarketLiquidationThreshold(market: Market, amount: bigint) {
  const { marketPrice, liquidationThreshold } = market;

  return (
    (amount * BigInt(liquidationThreshold) * BigInt(marketPrice)) /
    FactorScale /
    WeiPerEth
  );
}

export function getMarketBorrowBalance(market: Market, amount: bigint) {
  return (amount * BigInt(market.marketPrice)) / WeiPerEth;
}

export function getBalanceValue(balance: bigint, price: bigint) {
  return (balance * price) / WeiPerEth;
}

export function getUnderlyingAmount(balanceValue: bigint, price: bigint) {
  return (balanceValue * WeiPerEth) / price;
}

export function getUnderlyingBorrowLimit(market: Market, borrowLimit: bigint) {
  return (borrowLimit * WeiPerEth) / market.marketPrice;
}

export function getUnderlyingWithdrawLimit(
  market: Market,
  withdrawLimit: bigint,
) {
  return (
    (withdrawLimit * WeiPerEth * FactorScale) /
    BigInt(market.collateralFactor) /
    market.marketPrice
  );
}

export function getBorrowPercentage(
  borrowLimit: bigint,
  borrowBalance: bigint,
) {
  if (borrowLimit === 0n) {
    return 0;
  }

  return BigNumber(borrowBalance.toString())
    .div(borrowLimit.toString())
    .toNumber();
}

export function getLiquidationPercentage(
  liquidationThreshold: bigint,
  borrowBalance: bigint,
) {
  if (liquidationThreshold === 0n) {
    return 0;
  }

  return BigNumber(borrowBalance.toString())
    .div(liquidationThreshold.toString())
    .toNumber();
}

export function getNewBorrowStats(
  borrowStats: UserStats,
  market: Market,
  action: MarketAction,
  amount: bigint,
): UserStats {
  const {
    borrowLimit,
    borrowBalance,
    supplyBalance,
    netInterestValue,
    liquidationThreshold,
  } = borrowStats;

  let newBorrowLimit = borrowLimit;
  let newLiquidationThreshold = liquidationThreshold;
  let newSupplyBalance = supplyBalance;
  let newNetInterestValue = netInterestValue;

  if (action === "supply") {
    newBorrowLimit = borrowLimit + getMarketBorrowLimit(market, amount);
    newLiquidationThreshold =
      liquidationThreshold + getMarketLiquidationThreshold(market, amount);
    newSupplyBalance =
      supplyBalance + getBalanceValue(amount, market.marketPrice);
    newNetInterestValue =
      netInterestValue + getMarketSupplyInterest(market, amount);
  } else if (action === "withdraw") {
    newBorrowLimit = borrowLimit - getMarketBorrowLimit(market, amount);
    newLiquidationThreshold =
      liquidationThreshold - getMarketLiquidationThreshold(market, amount);
    newSupplyBalance =
      supplyBalance - getBalanceValue(amount, market.marketPrice);
    newNetInterestValue =
      netInterestValue - getMarketSupplyInterest(market, amount);
  }

  let newBorrowBalance = borrowBalance;
  if (action === "borrow") {
    newBorrowBalance = borrowBalance + getMarketBorrowBalance(market, amount);
    newNetInterestValue =
      netInterestValue - getMarketBorrowInterest(market, amount);
  } else if (action === "repay") {
    newBorrowBalance = borrowBalance - getMarketBorrowBalance(market, amount);
    newNetInterestValue =
      netInterestValue + getMarketBorrowInterest(market, amount);
  }

  const netApy = (() => {
    if (newSupplyBalance === 0n) {
      return 0;
    }

    return BigNumber(newNetInterestValue.toString())
      .div(newSupplyBalance.toString())
      .toNumber();
  })();

  return {
    borrowLimit: newBorrowLimit,
    borrowBalance: newBorrowBalance,
    supplyBalance: newSupplyBalance,
    borrowPct: getBorrowPercentage(newBorrowLimit, newBorrowBalance),
    netInterestValue,
    netApy,
    liquidationThreshold: newLiquidationThreshold,
    liquidationPct: getLiquidationPercentage(
      newLiquidationThreshold,
      newBorrowBalance,
    ),
  };
}

export function getMarketUsdPrice(market: Market): number {
  return Number(
    formatUnits(
      (market.marketPrice * parseUnits("1", market.marketDecimals)) / WeiPerEth,
      18,
    ),
  );
}

export function getMarketApy(rate: bigint): number {
  const bn = BigNumber.clone({ POW_PRECISION: 6 });
  return bn(formatEther(rate))
    .plus(1)
    .pow(31536000)
    .minus(1)
    .multipliedBy(100)
    .toNumber();
}

export function getMarketSupplyInterest(
  market: Market,
  balance: bigint,
): bigint {
  const apy = getMarketApy(market.supplyRate);
  const interest = BigNumber(balance.toString()).multipliedBy(apy).toFixed(0);
  return getBalanceValue(BigInt(interest), market.marketPrice);
}

export function getMarketBorrowInterest(
  market: Market,
  balance: bigint,
): bigint {
  const apy = getMarketApy(market.borrowRate);
  const interest = BigNumber(balance.toString()).multipliedBy(apy).toFixed(0);
  return getBalanceValue(BigInt(interest), market.marketPrice);
}

export function getMarketSupplyUsdValue(market: Market): number {
  const price = getMarketUsdPrice(market);
  const amount = BigNumber(
    formatUnits(market.totalSupply, market.marketDecimals),
  ).toNumber();
  const exchangeRate = BigNumber(
    formatUnits(market.exchangeRate, 18),
  ).toNumber();
  return price * amount * exchangeRate;
}

export function getMarketBorrowUsdValue(market: Market): number {
  const price = getMarketUsdPrice(market);
  const amount = BigNumber(
    formatUnits(market.totalBorrow, market.marketDecimals),
  ).toNumber();
  return price * amount;
}

export function getMarketReserveUsdValue(market: Market): number {
  const price = getMarketUsdPrice(market);
  const amount = BigNumber(
    formatUnits(market.totalReserves, market.marketDecimals),
  ).toNumber();
  const exchangeRate = BigNumber(
    formatUnits(market.exchangeRate, 18),
  ).toNumber();
  return price * amount * exchangeRate;
}

export function getMarketErc20Token(market: Market): Erc20Token {
  return new Erc20Token(
    81457, // TODO:
    market.market,
    market.marketDecimals,
    market.marketSymbol,
    market.marketName,
  );
}

export function getMarketUnwrappedToken(
  pool: Pool,
  market: Market,
): NativeToken {
  const wrappedMarket = findWrappedMarket(market, pool);
  const wrappedToken = getMarketErc20Token(market);
  return new NativeToken(
    81457, // TODO:
    wrappedToken,
    wrappedMarket.unwrappedDecimals,
    wrappedMarket.unwrappedSymbol,
    wrappedMarket.unwrappedSymbol,
  );
}
