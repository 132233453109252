import { Pool } from "@/configs/chains.ts";
import { AlienLens } from "@/contracts/AlienLens";
import { usePool } from "@/hooks/usePool.ts";
import { useQuery } from "@tanstack/react-query";
import { Config, simulateContract } from "@wagmi/core";
import { zipObject } from "lodash-es";
import { Address } from "viem";
import { useAccount, useConfig } from "wagmi";

async function getUserPoolMarkets(
  config: Config,
  pool: Pool,
  address: Address,
) {
  const { result } = await simulateContract(config, {
    abi: AlienLens,
    address: pool.contracts.lens,
    functionName: "getUserAllCurrentMarketsStatus",
    args: [pool.contracts.alien, address || "0x"],
  });

  return result;
}

export function useUserProtocolMarkets() {
  const { address } = useAccount();
  const { pools } = usePool();
  const config = useConfig();

  return useQuery({
    queryKey: ["useUserProtocolMarkets", pools],
    queryFn: async () => {
      if (!address) {
        throw new Error("invalid address");
      }

      const userMarketsList = await Promise.all(
        pools.map((p) => getUserPoolMarkets(config, p, address)),
      );

      // return an object with pool address as key and a list of user market as value
      return zipObject(
        pools.map((p) => p.contracts.alien),
        userMarketsList,
      );
    },
  });
}
