import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { UserStats } from "@/types";
import {
  getBalanceValue,
  getBorrowPercentage,
  getLiquidationPercentage,
  getMarketApy,
  getMarketBorrowBalance,
  getMarketBorrowLimit,
  getMarketLiquidationThreshold,
} from "@/utils/market";
import BigNumber from "bignumber.js";
import { reduce } from "lodash-es";

export function useUserStats(): UserStats {
  const { pool } = usePool();
  const { marketsByAddress } = usePoolMarkets(pool);
  const { userMarkets, userSupplyMarkets, userBorrowMarkets } =
    useUserPoolMarkets(pool);

  const borrowLimit = reduce(
    userMarkets,
    (prev, curr) => {
      const borrowLimit = getMarketBorrowLimit(
        marketsByAddress[curr.market],
        curr.supplyBalance,
      );
      return prev + borrowLimit;
    },
    0n,
  );

  const supplyBalance = reduce(
    userSupplyMarkets,
    (prev, curr) => {
      const market = marketsByAddress[curr.market];
      const supplyBalance = getBalanceValue(
        curr.supplyBalance,
        market.marketPrice,
      );
      return prev + supplyBalance;
    },
    0n,
  );

  const borrowBalance = reduce(
    userBorrowMarkets,
    (prev, curr) => {
      const borrowBalance = getMarketBorrowBalance(
        marketsByAddress[curr.market],
        curr.borrowBalance,
      );
      return prev + borrowBalance;
    },
    0n,
  );

  const liquidationThreshold = reduce(
    userSupplyMarkets,
    (prev, curr) => {
      const liquidationThreshold = getMarketLiquidationThreshold(
        marketsByAddress[curr.market],
        curr.supplyBalance,
      );
      return prev + liquidationThreshold;
    },
    0n,
  );

  const supplyInterestValue = (() => {
    return reduce(
      userSupplyMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        const apy = getMarketApy(market.supplyRate);
        const interest = BigNumber(curr.supplyBalance.toString())
          .multipliedBy(apy)
          .toFixed(0);
        return prev + getBalanceValue(BigInt(interest), market.marketPrice);
      },
      0n,
    );
  })();

  const borrowInterestValue = (() => {
    return reduce(
      userBorrowMarkets,
      (prev, curr) => {
        const market = marketsByAddress[curr.market];
        const apy = getMarketApy(market.borrowRate);
        const interest = BigNumber(curr.borrowBalance.toString())
          .multipliedBy(apy)
          .toFixed(0);
        return prev + getBalanceValue(BigInt(interest), market.marketPrice);
      },
      0n,
    );
  })();

  const netInterestValue = supplyInterestValue - borrowInterestValue;

  const netApy = (() => {
    if (supplyBalance === 0n) {
      return 0;
    }

    return BigNumber(netInterestValue.toString())
      .div(supplyBalance.toString())
      .toNumber();
  })();

  return {
    borrowLimit,
    borrowBalance,
    supplyBalance,
    borrowPct: getBorrowPercentage(borrowLimit, borrowBalance),
    netInterestValue,
    netApy,
    liquidationThreshold,
    liquidationPct: getLiquidationPercentage(
      liquidationThreshold,
      borrowBalance,
    ),
  };
}
