import { SupplyRewardApy } from "@/components/SupplyRewardApy.tsx";
import Table from "@/components/Table.tsx";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { Market } from "@/types.ts";
import { YesAddress } from "@/utils/constants.ts";
import { formatRate } from "@/utils/format.ts";
import { Stack, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { filter } from "lodash-es";
import { isAddressEqual } from "viem";
import { Asset } from "./Asset.tsx";

interface MobileMarketTableProps {
  onMarketSelect: (market: Market) => void;
  usingUnwrappedMarket: (market: Market) => boolean;
}

export const MobileMarketTable = ({
  onMarketSelect,
  usingUnwrappedMarket,
}: MobileMarketTableProps) => {
  const { pool } = usePool();
  const { markets } = usePoolMarkets(pool);
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const filteredMarkets = filter(markets, (m) => {
    if (isAddressEqual(m.market, YesAddress)) {
      const yesUserMarket = userMarketsByAddress[m.market];
      if (!yesUserMarket) {
        return false;
      }
      return (
        yesUserMarket.supplyBalance !== 0n || yesUserMarket.borrowBalance !== 0n
      );
    }
    return true;
  });

  const columnHelper = createColumnHelper<Market>();

  const columns = [
    columnHelper.display({
      header: "Asset",
      cell: ({ row: { original: market } }) => (
        <Asset
          market={market}
          userMarket={userMarketsByAddress[market.market]}
          useUnwrappedMarket={usingUnwrappedMarket(market)}
        />
      ),
    }),
    columnHelper.accessor("supplyRate", {
      header: "Supply APY",
      cell: ({ getValue, row: { original: market } }) => {
        const supplyRate = getValue();

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text
              variant="subtitle3"
              color={supplyRate === 0n ? "color.error" : "color.success"}
            >
              {formatRate(supplyRate, 2)}
            </Text>
            <SupplyRewardApy pool={pool} market={market} />
          </Stack>
        );
      },
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("borrowRate", {
      header: "Borrow APY",
      cell: ({ getValue }) => {
        const borrowRate = getValue();

        return (
          <Stack alignItems="flex-end" spacing={1}>
            <Text variant="subtitle3" color="text.primary">
              {formatRate(borrowRate, 2)}
            </Text>
            {/*<HStack spacing={1}>*/}
            {/*  <Text variant="subtitle5" color="primary.700">*/}
            {/*    ####*/}
            {/*    /!*{row.original.borrowRate}%*!/*/}
            {/*  </Text>*/}
            {/*  <TokenIcon symbol="ib" boxSize={4} />*/}
            {/*</HStack>*/}
          </Stack>
        );
      },
      meta: {
        isNumeric: true,
      },
    }),
  ];

  return (
    <Table
      columns={columns}
      data={filteredMarkets || []}
      px={4}
      onRowClick={(row) => onMarketSelect(row.original)}
    />
  );
};

export default MobileMarketTable;
