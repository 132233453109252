import MobileNavigationBar from "@/components/MobileNavigationBar";
import NavigationBar from "@/components/NavigationBar";
import useMediaQuery from "@/hooks/useMediaQuery";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import { Box } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { useEffect } from "react";

const Root = () => {
  const { isMobile } = useMediaQuery();

  const queryClient = useQueryClient();

  useEffect(() => {
    const id = setInterval(() => {
      invalidateWagmiQueries(queryClient);
    }, 15 * 1000);

    return () => {
      clearInterval(id);
    };
  });

  return (
    <Box as="main" w="full" bgColor="background.body" minH="100vh">
      {isMobile ? <MobileNavigationBar /> : <NavigationBar />}
      <Outlet />
      {/*{import.meta.env.DEV && (*/}
      {/*  <ReactQueryDevtools buttonPosition="bottom-left" />*/}
      {/*)}*/}
    </Box>
  );
};

export const Route = createRootRoute({
  component: Root,
});
