import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const filledStyle = definePartsStyle({
  field: {
    h: "3.5rem",
    color: "text.primary",
    fontWeight: 600,
    lineHeight: "1.5rem",
    bgColor: "background.input",
    borderRadius: "0.375rem",
    _hover: {
      bgColor: "background.hover",
    },
  },
});

export default defineMultiStyleConfig({ variants: { filled: filledStyle } });
