import { LeverageInputCard } from "@/features/leverage/components/LeverageInputCard.tsx";
import { LevXCloseTxSummaryCard } from "@/features/leverage/components/LevXCloseTxSummaryCard.tsx";
import { LevXOpenTxSummaryCard } from "@/features/leverage/components/LevXOpenTxSummaryCard.tsx";
import { StatsCard } from "@/features/leverage/components/StatsCard.tsx";
import { TradingViewWidget } from "@/features/leverage/components/TradingViewWidget.tsx";
import {
  isInOpenAtom,
  longMarketAtom,
  shortMarketAtom,
} from "@/features/leverage/states/states";
import useMediaQuery from "@/hooks/useMediaQuery";
import { Box, Card, Container, Flex, Stack } from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";
import { useAtomValue } from "jotai";

const Leverage = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const longMarket = useAtomValue(longMarketAtom);
  const shortMarket = useAtomValue(shortMarketAtom);

  const isInOpen = useAtomValue(isInOpenAtom);

  return (
    <Box bgColor="background.light">
      {/* Padding bottom for mobile cta */}
      <Container maxW="container.xl" pb={isMobile ? 28 : 0}>
        <Flex gap={6} flexDirection={isMobile ? "column" : "row"} py={6}>
          <Stack spacing={6} flex={"1 1 auto"}>
            <StatsCard />
            {isDesktop && longMarket && shortMarket && (
              <Card minHeight={323} padding={1}>
                <TradingViewWidget
                  longMarket={longMarket}
                  shortMarket={shortMarket}
                />
              </Card>
            )}
          </Stack>
          <Stack spacing={6} flex={"0 1 416px"}>
            <LeverageInputCard />
            {isInOpen ? <LevXOpenTxSummaryCard /> : <LevXCloseTxSummaryCard />}
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};

export const Route = createFileRoute("/leverage")({
  component: Leverage,
});
