import { TokenInput } from "@/components/TokenInput.tsx";
import { Pool } from "@/configs/chains.ts";
import { Alien } from "@/contracts/Alien.ts";
import { useApprove } from "@/hooks/useApprove.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { useWriteContract } from "@/hooks/useWriteContract.ts";
import { Market } from "@/types.ts";
import { supplyBlockList } from "@/utils/constants.ts";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { formatUnits, parseUnits } from "viem";
import { useAccount } from "wagmi";

interface SupplyButtonProps {
  market: Market;
  pool: Pool;
}

export const SupplyButton = ({ market, pool }: SupplyButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [supplyAmount, setSupplyAmount] = useState("");

  const parsedSupplyAmount = parseUnits(supplyAmount, market.marketDecimals);

  const { isApproved, isApproving, approve } = useApprove(
    market.market,
    pool.contracts.alien,
    parsedSupplyAmount,
  );

  const { userMarketsByAddress } = useUserPoolMarkets(pool);
  const userMarket = userMarketsByAddress[market.market];

  const balance = userMarket ? userMarket.balance : 0n;

  const { writeContract, isLoading: isWritingContract } = useWriteContract();

  const { address } = useAccount();

  const isBlocked = supplyBlockList.includes(market.market);

  const onSupply = () => {
    if (!address) {
      return;
    }

    writeContract({
      abi: Alien,
      address: pool.contracts.alien,
      functionName: "supply",
      args: [address, address, market.market, parsedSupplyAmount],
    });
  };

  const onCloseModal = () => {
    onClose();
    setSupplyAmount("");
  };

  const error = ((): string | null => {
    if (parsedSupplyAmount === 0n || supplyAmount === "") {
      return "Invalid amount";
    }
    if (parsedSupplyAmount > balance) {
      return "Insufficient balance";
    }
    return null;
  })();

  return (
    <>
      <Button
        variant="ghost"
        onClick={onOpen}
        width="full"
        color="primary.500"
        _disabled={{
          color: "#4C5354",
        }}
        px={0}
        isDisabled={isBlocked || market.supplyPaused}
      >
        Supply
      </Button>
      <Modal isOpen={isOpen} onClose={() => onCloseModal()} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supply {market.marketSymbol}</ModalHeader>
          <ModalBody>
            <TokenInput
              symbol={market.marketSymbol}
              token={market.market}
              inputName="amount"
              value={supplyAmount}
              onChange={(e) => {
                setSupplyAmount(e.target.value);
              }}
              showMaxButton={true}
              onMax={() => {
                setSupplyAmount(formatUnits(balance, market.marketDecimals));
              }}
            />
          </ModalBody>
          <ModalFooter>
            {isApproved ? (
              <Button
                w="full"
                variant="primary"
                onClick={() => onSupply()}
                isLoading={isWritingContract}
                isDisabled={!!error}
              >
                {error || "Supply"}
              </Button>
            ) : (
              <Button
                w="full"
                variant="primary"
                onClick={() => approve()}
                isLoading={isApproving}
              >
                Approve
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
