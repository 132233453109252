import { Pool } from "@/configs/chains.ts";
import { useAlienPrice } from "@/hooks/useAlienPrice.ts";
import { useAlienRewardsStatus } from "@/hooks/useAlienRewardsStatus.ts";
import { Market } from "@/types.ts";
import { formatPercentage } from "@/utils/format.ts";
import { getMarketUsdPrice } from "@/utils/market.ts";
import { HStack, Text, Tooltip } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { formatEther, formatUnits } from "viem";

interface SupplyAndRewardApyProps {
  market: Market;
  pool: Pool;
}

export const SupplyAndRewardApy = ({
  market,
  pool,
}: SupplyAndRewardApyProps) => {
  const { data, status: priceStatus } = useAlienPrice();

  const { data: rewardData, status: rewardStatus } = useAlienRewardsStatus();

  const { marketDecimals, totalSupply, supplyRate } = market;

  const bn = BigNumber.clone({ POW_PRECISION: 6 });
  const supplyApy = bn(formatEther(supplyRate))
    .plus(1)
    .pow(31536000)
    .minus(1)
    .toNumber();

  let rewardApy: number = 0;
  if (priceStatus === "success" && rewardStatus === "success") {
    const alienAmount =
      rewardData.amountByPool[pool.contracts.alien].amountByMarket[
        market.market
      ];
    const alienAmountNumber = Number(alienAmount) / 1e18;

    if (alienAmountNumber) {
      const totalSupplyUsdValue = BigNumber(
        formatUnits(totalSupply, marketDecimals),
      )
        .multipliedBy(getMarketUsdPrice(market))
        .toNumber();

      rewardApy =
        ((alienAmountNumber * data) / totalSupplyUsdValue) * (365 / 7);
    }
  }

  const tooltip =
    rewardApy !== 0
      ? `Supply ${formatPercentage(supplyApy, 2)} + ALIEN ${formatPercentage(rewardApy, 2)}`
      : `Supply ${formatPercentage(supplyApy, 2)}`;

  return (
    <Tooltip placement="top" label={<Text>{tooltip}</Text>}>
      <HStack spacing={1}>
        <Text variant="subtitle3">
          {formatPercentage(supplyApy + rewardApy, 2)}
        </Text>
      </HStack>
    </Tooltip>
  );
};
