import TokenIcon from "@/components/TokenIcon.tsx";
import { TokenInput } from "@/components/TokenInput.tsx";
import { VeAlien as VeAlienAbi } from "@/contracts/veAlien.ts";
import {
  stakeDurationMap,
  StakeDurationOption,
  veAlienAddress,
} from "@/features/stake/alien/config.ts";
import { getContractUnlockDate } from "@/features/stake/alien/utils.ts";
import { useApprove } from "@/hooks/useApprove.ts";
import { AlienAddress } from "@/utils/constants.ts";
import { commify } from "@/utils/format.ts";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import {
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { add as addDuration, differenceInSeconds, formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { formatUnits, parseUnits } from "viem";
import {
  useAccount,
  useBalance,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

export const CreateVeAlien = () => {
  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState<StakeDurationOption>("4y");
  const [unlockDate, setUnlockDate] = useState(
    addDuration(new Date(), stakeDurationMap[duration]),
  );

  const parsedAmount = parseUnits(amount || "0", 18);

  const { isApproved, isApproving, approve } = useApprove(
    AlienAddress,
    veAlienAddress,
    parsedAmount,
  );

  const { address } = useAccount();

  const { data: balance } = useBalance({ token: AlienAddress, address });

  const {
    data,
    writeContract: createLock,
    isPending: isTxSending,
  } = useWriteContract();

  const { isSuccess, isLoading: isTxConfirming } = useWaitForTransactionReceipt(
    { hash: data },
  );

  const onCreateLock = () => {
    createLock({
      abi: VeAlienAbi,
      address: veAlienAddress,
      functionName: "create_lock",
      args: [parsedAmount, BigInt(differenceInSeconds(unlockDate, new Date()))],
    });
  };

  const renderDurationButton = (option: StakeDurationOption, text: string) => {
    return (
      <Button
        variant={duration === option ? "primary" : "outline"}
        onClick={() => {
          setDuration(option);
          setUnlockDate(addDuration(new Date(), stakeDurationMap[option]));
        }}
        fontSize="sm"
      >
        {text}
      </Button>
    );
  };

  const error: string | null = (() => {
    if (amount === "") {
      return "Enter an amount";
    }
    if (balance && parsedAmount > balance.value) {
      return "Insufficient ALIEN";
    }
    return null;
  })();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      invalidateWagmiQueries(queryClient);
    }
  }, [isSuccess, queryClient]);

  return (
    <Card>
      <CardBody>
        <Stack spacing={4}>
          <Stack>
            <Text fontWeight="bold">Stake Amount</Text>
            <TokenInput
              symbol="ALIEN"
              token={AlienAddress}
              value={amount}
              inputName="alienAmount"
              icon={<TokenIcon symbol="alien" boxSize={6} />}
              showMaxButton={true}
              onChange={(e) => setAmount(e.target.value)}
              onMax={
                balance &&
                (() => setAmount(formatUnits(balance.value, balance.decimals)))
              }
            />
          </Stack>
          <Stack>
            <Text fontWeight="bold">Stake Duration</Text>
            <HStack flexWrap="wrap">
              {renderDurationButton("4y", "4 Years")}
              {renderDurationButton("2y", "2 Years")}
              {renderDurationButton("1y", "1 Year")}
              {renderDurationButton("6m", "6 Months")}
              {renderDurationButton("1m", "1 Month")}
            </HStack>
          </Stack>
          <Divider />
          <Stack>
            <HStack justifyContent="space-between">
              <Text sx={{ textWrap: "nowrap" }}>Stake Amount</Text>
              <Text
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {commify(amount) || "0"}
              </Text>
            </HStack>
            <HStack justifyContent="space-between">
              <Text>Stake until</Text>
              <Text>{formatDate(getContractUnlockDate(unlockDate), "PP")}</Text>
            </HStack>
          </Stack>
          {isApproved ? (
            <Button
              variant="primary"
              onClick={() => onCreateLock()}
              isLoading={isTxSending || isTxConfirming}
              isDisabled={!!error}
            >
              {error ?? "Stake"}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => approve()}
              isLoading={isApproving}
            >
              Approve ALIEN
            </Button>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};
