import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BigNumber from "bignumber.js";
import { omit } from "lodash-es";
import { Address } from "viem";
import { useAccount } from "wagmi";

type UserBlastPointsStatusData = Record<
  Address,
  {
    GOLD: {
      total: string;
      distributed: string;
    };
  }
>;

type UseUserBlastPointsStatusResult = {
  data:
    | {
        pending: number;
        distributed: number;
      }
    | undefined;
  isSuccess: boolean;
};

export const useUserBlastGoldStatus = (): UseUserBlastPointsStatusResult => {
  const { address } = useAccount();

  const { data: blastGoldUserStatus, isSuccess: isBlastGoldUserStatusSuccess } =
    useQuery({
      queryKey: ["blast-gold-user-status", address],
      queryFn: async () => {
        const { data } = await axios.get<UserBlastPointsStatusData>(
          "https://api.alien.finance/api/v1/blast-point/balances",
          {
            params: {
              account: address,
            },
          },
        );
        return omit(data, [
          "WETH",
          "USDB",
          "BLAST",
          "GOLD",
        ]) as UserBlastPointsStatusData;
      },
      enabled: !!address,
    });

  if (!isBlastGoldUserStatusSuccess) {
    return {
      data: undefined,
      isSuccess: false,
    };
  }

  let distributed = 0;
  let pending = 0;

  for (const poolAddress in blastGoldUserStatus) {
    const status = blastGoldUserStatus[poolAddress as Address];
    const goldStatus = status["GOLD"];
    distributed += BigNumber(goldStatus.distributed).dividedBy(1e12).toNumber();
    pending += BigNumber(goldStatus.total)
      .minus(goldStatus.distributed)
      .dividedBy(1e12)
      .toNumber();
  }

  return {
    data: {
      distributed,
      pending,
    },
    isSuccess: true,
  };
};
