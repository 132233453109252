import { useLevXOpenAmounts } from "@/features/leverage/hooks/useLevXAmounts";
import { usePendingBorrowLimit } from "@/features/leverage/hooks/usePendingBorrowLimit";
import {
  addingCollateralAtom,
  slippageAtom,
} from "@/features/leverage/states/states";
import { formatUSDValue } from "@/features/leverage/utils/formatUSDValue";
import { usePool } from "@/hooks/usePool";
import { getBalanceValue, getMarketBorrowLimit } from "@/utils/market";
import { findWrappedMarket } from "@/utils/wrappedMarket";
import { Text } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { formatUnits } from "viem";
import { TxSummaryCard, TxSummaryItem } from "./TxSummaryCard";

export const LevXOpenTxSummaryCard = () => {
  const { pool } = usePool();
  const addingCollateral = useAtomValue(addingCollateralAtom);
  const {
    currentBorrowLimit: borrowLimitBefore,
    newBorrowLimit,
    borrowBalance,
  } = usePendingBorrowLimit();
  const {
    longMarket,
    longAmount,
    shortMarket,
    longValue,
    shortValue,
    swapFee,
  } = useLevXOpenAmounts();
  const slippage = useAtomValue(slippageAtom);

  const supplyValue =
    (addingCollateral &&
      getBalanceValue(
        addingCollateral.amount,
        addingCollateral.market.marketPrice,
      )) ||
    0n;

  const longBorrowLimit =
    longMarket && longAmount !== undefined
      ? getMarketBorrowLimit(longMarket, longAmount)
      : undefined;

  const borrowLimitAfter =
    longBorrowLimit != undefined ? newBorrowLimit + longBorrowLimit : undefined;

  const borrowLimitUsedBefore =
    borrowLimitBefore > 0n
      ? (borrowBalance * 10000n) / borrowLimitBefore
      : undefined;

  const borrowLimitUsed =
    shortValue !== undefined ? shortValue + borrowBalance : undefined;

  const borrowLimitUsedAfter =
    borrowLimitUsed !== undefined &&
    borrowLimitAfter !== undefined &&
    borrowLimitAfter > 0n
      ? (borrowLimitUsed * 10000n) / borrowLimitAfter
      : undefined;

  const longValueStr = formatUSDValue(longValue) || "--";
  const shortValueStr = formatUSDValue(shortValue) || "--";
  const supplyValueStr = formatUSDValue(supplyValue) || "--";
  const slippageStr = `${slippage * 100}%`;
  const swapFeeStr =
    swapFee !== undefined ? `${swapFee.multipliedBy(100).toFixed(2)}%` : "--";
  const borrowLimitBeforeStr = formatUSDValue(borrowLimitBefore);
  const borrowLimitAfterStr = formatUSDValue(borrowLimitAfter) || "--";
  const borrowLimitUsedBeforeStr =
    borrowLimitUsedBefore !== undefined
      ? `${formatUnits(borrowLimitUsedBefore, 2)}%`
      : "--";
  const borrowLimitUsedAfterStr =
    borrowLimitUsedAfter !== undefined
      ? `${formatUnits(borrowLimitUsedAfter, 2)}%`
      : "--";

  return (
    <TxSummaryCard>
      {addingCollateral && supplyValue > 0n && (
        <TxSummaryItem
          name={
            addingCollateral.useUnwrappedMarket
              ? findWrappedMarket(addingCollateral.market, pool).unwrappedSymbol
              : addingCollateral.market.marketSymbol
          }
          badgeType="Supply"
        >
          {supplyValueStr}
        </TxSummaryItem>
      )}

      {longMarket && (
        <TxSummaryItem name={longMarket.marketSymbol} badgeType="Long">
          {longValueStr}
        </TxSummaryItem>
      )}

      {shortMarket && (
        <TxSummaryItem name={shortMarket.marketSymbol} badgeType="Short">
          {shortValueStr}
        </TxSummaryItem>
      )}
      <TxSummaryItem name="Slippage">{slippageStr}</TxSummaryItem>
      <TxSummaryItem name="Swap Fee">{swapFeeStr}</TxSummaryItem>
      <TxSummaryItem name="Borrow Limit">
        <Text>{borrowLimitBeforeStr}</Text>
        <Text textColor="white">{borrowLimitAfterStr}</Text>
      </TxSummaryItem>
      <TxSummaryItem name="Borrow Limit Used">
        <Text>{borrowLimitUsedBeforeStr}</Text>
        <Text textColor="white">{borrowLimitUsedAfterStr}</Text>
      </TxSummaryItem>
    </TxSummaryCard>
  );
};
