import { Market } from "@/types";
import { atom, PrimitiveAtom } from "jotai";

export type OpenExactField = {
  side: "long" | "short";
  value: string;
};

export type CloseExactField = {
  side: "lesser" | "long" | "short";
  value: string;
};

export type AddingCollateral = {
  market: Market;
  amount: bigint;
  useUnwrappedMarket: boolean;
};

export type StatTab = "history" | "position";

export const statTabAtom = atom<StatTab>("history");

export const openExactFieldAtom = atom<OpenExactField>({
  side: "long",
  value: "",
});

export const closeExactFieldAtom = atom<CloseExactField>({
  side: "long",
  value: "",
});

/** Switch atom values when setting same market to the other */
const createBoundedMarketAtom = (
  marketAAtom: PrimitiveAtom<Market | undefined>,
  marketBAtom: PrimitiveAtom<Market | undefined>,
) => {
  return atom<Market | undefined, [Market | undefined], Market | undefined>(
    (get) => get(marketAAtom),
    (get, set, update) => {
      const marketA = get(marketAAtom);
      const marketB = get(marketBAtom);

      set(marketAAtom, update);

      const isInOpen = get(isInOpenAtom);

      // When closing position, we don't want to switch markets, since market options are limited to the supplied
      // market or borrowed market.
      if (isInOpen && update?.market === marketB?.market) {
        set(marketBAtom, marketA);
      }

      if (!isInOpen && update?.market === marketB?.market) {
        set(marketBAtom, undefined);
      }

      return update;
    },
  );
};

const _longMarketAtom = atom<Market | undefined>(undefined);
const _shortMarketAtom = atom<Market | undefined>(undefined);

export const longMarketAtom = createBoundedMarketAtom(
  _longMarketAtom,
  _shortMarketAtom,
);
export const shortMarketAtom = createBoundedMarketAtom(
  _shortMarketAtom,
  _longMarketAtom,
);

export const isInOpenAtom = atom(true);

export const addingCollateralAtom = atom<AddingCollateral | undefined>(
  undefined,
);

export const slippageAtom = atom(0.005);
