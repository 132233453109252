import { ActionButtonProps } from "@/features/lending/components/buttons/index.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { supplyBlockList } from "@/utils/constants.ts";
import { getUserUnwrappedMarket } from "@/utils/wrappedMarket.ts";
import { Button, Icon, IconButton } from "@chakra-ui/react";
import { useContext } from "react";
import { RiAddLine } from "react-icons/ri";
import { useAccount } from "wagmi";

const SupplyButton = ({
  market,
  userMarket,
  useUnwrappedMarket,
  onClick,
  isMobile = false,
}: ActionButtonProps) => {
  const { isDisconnected } = useAccount();

  const { hasMarketTx } = useContext(TxBuilderContext);

  const hasBalance = () => {
    if (!userMarket) {
      return false;
    }

    if (!useUnwrappedMarket) {
      return userMarket.balance > 0n;
    } else {
      const unwrappedMarket = getUserUnwrappedMarket(userMarket);
      return unwrappedMarket.balance > 0n;
    }
  };

  const isPaused = market.supplyPaused;

  const isBlocked = supplyBlockList.includes(market.market);

  const isDisabled =
    isDisconnected ||
    !hasBalance() ||
    hasMarketTx(market) ||
    isBlocked ||
    isPaused;

  return isMobile ? (
    <Button
      w="full"
      variant="primary"
      leftIcon={<Icon as={RiAddLine} />}
      onClick={() => onClick()}
      isDisabled={isDisabled}
    >
      Supply
    </Button>
  ) : (
    <IconButton
      aria-label="plus"
      variant="actionIconButton"
      onClick={() => onClick()}
      isDisabled={isDisabled}
    >
      <RiAddLine />
    </IconButton>
  );
};

export default SupplyButton;
