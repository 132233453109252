import MarketOverview from "@/features/markets/components/MarketOverview";
import StatsBanner from "@/features/markets/components/StatsBanner";
import { Box, Card, Container, Stack } from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";

const Markets = () => {
  return (
    <Box bgColor="background.light" pb={6}>
      <Container maxW="container.xl">
        <Stack spacing={6} pt={8}>
          <StatsBanner />
          <Card py={6}>
            <MarketOverview />
          </Card>
        </Stack>
      </Container>
    </Box>
  );
};

export const Route = createFileRoute("/markets")({
  component: Markets,
});
