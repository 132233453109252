import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  variants: {
    headline1: {
      fontWeight: 700,
      fontSize: "4xl",
      lineHeight: "2.75rem",
    },
    headline2: {
      fontWeight: 700,
      fontSize: "3xl",
      lineHeight: "2.5rem",
    },
    headline3: {
      fontWeight: 700,
      fontSize: "2xl",
      lineHeight: "2.125rem",
    },
    headline4: {
      fontWeight: 700,
      fontSize: "lg",
      lineHeight: "1.75rem",
    },
  },
});
