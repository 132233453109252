import { Duration } from "date-fns";

export const veAlienAddress = "0x9A676e781A523b5d0C0e43731313A708CB607508";

export type StakeDurationOption = "1w" | "1m" | "6m" | "1y" | "2y" | "4y";

export const stakeDurationMap: Record<StakeDurationOption, Duration> = {
  "1w": { days: 7 },
  "1m": { months: 1 },
  "6m": { months: 6 },
  "1y": { years: 1 },
  "2y": { years: 2 },
  "4y": { years: 4 },
};
