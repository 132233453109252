import { EnableExtensionButton } from "@/components/EnableExtensionButton.tsx";
import { useApproveExtension } from "@/hooks/extension/useApproveExtension.ts";
import { useSendTxs } from "@/hooks/extension/useSendTxs.ts";
import { usePool } from "@/hooks/usePool.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { invalidateWagmiQueries } from "@/utils/queryClient.ts";
import { Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useWaitForTransactionReceipt } from "wagmi";

const SubmitTxsButton = () => {
  const { pool } = usePool();
  const { removeAllTx, isReady } = useContext(TxBuilderContext);

  const { isExtensionAllowed } = useApproveExtension(
    pool.contracts.extension.txBuilder,
  );

  const { data: txResult, writeContract: sendTxs, isPending } = useSendTxs();

  const { isLoading: isConfirming, isSuccess } = useWaitForTransactionReceipt({
    hash: txResult,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      removeAllTx();

      invalidateWagmiQueries(queryClient);
    }
  }, [isSuccess, queryClient, removeAllTx]);

  return (
    <>
      {isExtensionAllowed === false ? (
        <EnableExtensionButton extension={pool.contracts.extension.txBuilder} />
      ) : (
        <Button
          variant="primary"
          w="full"
          onClick={() => sendTxs()}
          isDisabled={!isReady || !sendTxs}
          isLoading={isConfirming || isPending}
        >
          Submit Transaction
        </Button>
      )}
    </>
  );
};

export default SubmitTxsButton;
