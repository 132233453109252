import { tryParseUnits } from "@/features/leverage/utils/tryParseUnits";
import {
  DecreasePositionSubAction,
  newUniswapV3ExactInputAction,
  newUniswapV3ExactOutputAction,
} from "@/hooks/uniswapExtension/useSendUniswapExtensionActions.ts";
import { usePool } from "@/hooks/usePool.ts";
import { useUserPoolMarkets } from "@/hooks/useUserPoolMarkets.ts";
import { ExtensionAction, Market } from "@/types.ts";
import { MaxUint256 } from "@/utils/constants";
import { getBalanceValue } from "@/utils/market.ts";
import { addMinutes, getUnixTime } from "date-fns";
import { min } from "lodash-es";
import { useAccount } from "wagmi";
import { useSwapQuote } from "./useSwapQuote";

export const useLesserSide = ({
  exactSide,
  exactAmount: exactAmountStr,
  longMarket,
  shortMarket,
}: {
  exactSide: "long" | "short" | "lesser";
  exactAmount: string;
  longMarket: Market | undefined;
  shortMarket: Market | undefined;
}): {
  exactSide: "long" | "short" | undefined;
  exactAmount: bigint | undefined;
} => {
  const { isDisconnected } = useAccount();
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const userLongMarket = userMarketsByAddress[longMarket?.market || "0x"];
  const userShortMarket = userMarketsByAddress[shortMarket?.market || "0x"];

  if (
    !longMarket ||
    !shortMarket ||
    isDisconnected ||
    !userLongMarket ||
    !userShortMarket
  ) {
    return {
      exactSide: undefined,
      exactAmount: undefined,
    };
  }

  if (exactSide !== "lesser") {
    return {
      exactSide,
      exactAmount: tryParseUnits(
        exactAmountStr,
        (exactSide === "long" ? longMarket : shortMarket).marketDecimals,
      ),
    };
  }

  // We compare user's supply and borrow value and market cash value and keep the smaller one as `minValue`.
  // This is the maximum value that a user can close.
  const userSupplyValue = getBalanceValue(
    userLongMarket.supplyBalance,
    longMarket.marketPrice,
  );
  const userBorrowValue = getBalanceValue(
    userShortMarket.borrowBalance,
    shortMarket.marketPrice,
  );
  const longMarketCashValue = getBalanceValue(
    longMarket.totalCash,
    longMarket.marketPrice,
  );

  const minValue = min([
    userSupplyValue,
    userBorrowValue,
    longMarketCashValue,
  ]) as bigint;

  const lesserSide = minValue === userBorrowValue ? "short" : "long";

  if (lesserSide === "long") {
    return {
      exactSide: "long",
      exactAmount: userLongMarket.supplyBalance,
    };
  } else {
    return {
      exactSide: "short",
      exactAmount: userShortMarket.borrowBalance,
    };
  }
};

type UseLevXCloseParams = {
  closeAll: boolean;
  exactSide: "long" | "short" | undefined;
  exactAmount: bigint | undefined;
  longMarket: Market | undefined;
  shortMarket: Market | undefined;
  slippage: number; // for a slippage tolerance of 5%, pass in 0.05
};

type UseLevXCloseResult =
  | {
      longAmount: bigint | undefined;
      shortAmount: bigint | undefined;
      getExtensionActions: (() => ExtensionAction[]) | undefined;
      error: string | null;
    }
  | undefined;

export const useLevXClose = ({
  closeAll,
  exactSide,
  exactAmount,
  longMarket,
  shortMarket,
  slippage,
}: UseLevXCloseParams): UseLevXCloseResult => {
  const { pool } = usePool();
  const { userMarketsByAddress } = useUserPoolMarkets(pool);

  const { amount: estAmount, encodedPath } = useSwapQuote({
    exactSide:
      exactSide === undefined ? undefined : exactSide === "long" ? "in" : "out",
    exactAmount: exactAmount,
    tokenIn: longMarket?.market,
    tokenInDecimals: longMarket?.marketDecimals,
    tokenOut: shortMarket?.market,
    tokenOutDecimals: shortMarket?.marketDecimals,
  });

  const userLongMarket = longMarket && userMarketsByAddress[longMarket.market];
  const userShortMarket =
    shortMarket && userMarketsByAddress[shortMarket.market];

  if (
    !encodedPath ||
    !longMarket ||
    !shortMarket ||
    !longMarket ||
    !shortMarket ||
    !userLongMarket ||
    !userShortMarket
  ) {
    return;
  }

  const getExtensionActions = (() => {
    if (
      exactAmount === undefined ||
      exactAmount <= 0n ||
      estAmount === undefined ||
      estAmount <= 0n
    ) {
      return;
    }

    return () => {
      const actions: ExtensionAction[] = [];
      const slippageAmount =
        (estAmount * BigInt(Math.floor(slippage * 1e6))) / BigInt(1e6);
      const deadline = getUnixTime(addMinutes(new Date(), 3));
      if (exactSide === "long") {
        actions.push(
          newUniswapV3ExactInputAction({
            inAsset: longMarket.market,
            inAmount: closeAll ? MaxUint256 : exactAmount,
            outAsset: shortMarket.market,
            minOutAmount: estAmount - slippageAmount,
            path: encodedPath,
            subAction: DecreasePositionSubAction,
            deadline,
          }),
        );
      } else if (exactSide === "short") {
        actions.push(
          newUniswapV3ExactOutputAction({
            inAsset: longMarket.market,
            outAsset: shortMarket.market,
            outAmount: closeAll ? MaxUint256 : exactAmount,
            maxInAmount: estAmount + slippageAmount,
            path: encodedPath,
            subAction: DecreasePositionSubAction,
            deadline,
          }),
        );
      }
      return actions;
    };
  })();

  const error = (() => {
    if (exactAmount !== undefined) {
      if (exactAmount < 0n) {
        return "Invalid input";
      }
      if (exactSide === "long") {
        if (exactAmount > userLongMarket.supplyBalance) {
          return "Amount exceeds supplied amount";
        }

        if (exactAmount > longMarket.totalCash) {
          return "Amount exceeds market cash";
        }
      } else if (exactSide === "short") {
        if (exactAmount > userShortMarket.borrowBalance) {
          return "Amount exceeds borrowed amount";
        }
      }
    }

    return null;
  })();

  return {
    longAmount: exactSide === "long" ? exactAmount : estAmount,
    shortAmount: exactSide === "short" ? exactAmount : estAmount,
    getExtensionActions,
    error,
  };
};
