import { Address } from "viem";

abstract class BaseToken {
  abstract readonly isNative: boolean;

  readonly chainId: number;
  readonly decimals: number;
  readonly symbol?: string;
  readonly name?: string;

  protected constructor(
    chainId: number,
    decimals: number,
    symbol?: string,
    name?: string,
  ) {
    this.chainId = chainId;
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
  }
}

export class NativeToken extends BaseToken {
  public readonly isNative = true;
  public readonly wrapped: Erc20Token;

  constructor(
    chainId: number,
    wrapped: Erc20Token,
    decimals: number,
    symbol?: string,
    name?: string,
  ) {
    super(chainId, decimals, symbol, name);
    this.wrapped = wrapped;
  }
}

export class Erc20Token extends BaseToken {
  public readonly isNative = false;
  public readonly address: Address;

  constructor(
    chainId: number,
    address: Address,
    decimals: number,
    symbol?: string,
    name?: string,
  ) {
    super(chainId, decimals, symbol, name);
    this.address = address;
  }
}

export type Token = NativeToken | Erc20Token;
