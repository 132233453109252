import Table from "@/components/Table";
import { TokenView } from "@/features/leverage/components/TokenView";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePool } from "@/hooks/usePool";
import { Market } from "@/types";
import { formatRate } from "@/utils/format";
import { underlyingPriceToUsd } from "@/utils/marketStatus";
import { findWrappedMarket } from "@/utils/wrappedMarket";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import BigNumber from "bignumber.js";
import { formatUnits } from "viem/utils";

export interface MarketSelectorOption {
  market: Market;
  isUnwrapped: boolean;
}

export type MarketSelectorCallback = (
  market: Market,
  isUnwrapped: boolean,
) => void;

export const MarketSelectorModal = ({
  title,
  showSupplyAPY,
  showBorrowAPY,
  options,
  isOpen,
  onClose,
  onSelect,
}: {
  title: string;
  showSupplyAPY?: boolean;
  showBorrowAPY?: boolean;
  options: MarketSelectorOption[];
  isOpen: boolean;
  onClose: () => void;
  onSelect: MarketSelectorCallback;
}) => {
  const { isMobile } = useMediaQuery();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isMobile} size="xl">
      <ModalOverlay backgroundColor="background.modal" />
      <ModalContent pb={6} mx={6}>
        <ModalCloseButton top={6} right={6} h={6} />
        <MarketSelectorModalContent
          title={title}
          showSupplyAPY={showSupplyAPY}
          showBorrowAPY={showBorrowAPY}
          options={options}
          onClose={onClose}
          onSelect={onSelect}
        />
      </ModalContent>
    </Modal>
  );
};

const MarketSelectorModalContent = ({
  title,
  showSupplyAPY,
  showBorrowAPY,
  options,
  onClose,
  onSelect,
}: {
  title: string;
  showSupplyAPY?: boolean;
  showBorrowAPY?: boolean;
  options: MarketSelectorOption[];
  onClose: () => void;
  onSelect: MarketSelectorCallback;
}) => {
  const { pool } = usePool();
  const { isDesktop } = useMediaQuery();

  const columnHelper = createColumnHelper<MarketSelectorOption>();

  const columns = [
    columnHelper.accessor("market.marketSymbol", {
      header: () => <Text>Token</Text>,
      cell: ({
        row: {
          original: { market, isUnwrapped },
        },
      }) => {
        if (isUnwrapped) {
          const wrappedMarket = findWrappedMarket(market, pool);
          return <TokenView symbol={wrappedMarket.unwrappedSymbol} />;
        }
        return <TokenView symbol={market.marketSymbol} />;
      },
    }),
    columnHelper.accessor("market.marketPrice", {
      header: () => <Text>Price</Text>,
      cell: ({
        row: {
          original: {
            market: { marketPrice, marketDecimals },
          },
        },
      }) => (
        <Text>
          {`$${BigNumber(
            underlyingPriceToUsd(marketPrice, marketDecimals),
          ).toFormat(2)}`}
        </Text>
      ),
    }),
    showSupplyAPY &&
      columnHelper.accessor("market.supplyRate", {
        header: "Supply APY",
        cell: ({ getValue }) => (
          <Text color="color.long">{formatRate(getValue(), 2)}</Text>
        ),
      }),
    showBorrowAPY &&
      columnHelper.accessor("market.borrowRate", {
        header: "Borrow APY",
        cell: ({ getValue }) => (
          <Text color="color.long">{formatRate(getValue(), 2)}</Text>
        ),
      }),
    isDesktop &&
      columnHelper.accessor("market.totalCash", {
        header: "Liquidity",
        cell: ({
          row: {
            original: {
              market: { totalCash, marketDecimals },
            },
          },
        }) => (
          <Text color="text.primary">
            {BigNumber(formatUnits(totalCash, marketDecimals)).toFormat(2)}
          </Text>
        ),
      }),
  ].filter(Boolean) as ColumnDef<MarketSelectorOption, string>[];

  return (
    <>
      <ModalHeader py={6}>
        <Text fontWeight={600} fontSize={18} lineHeight={24 / 18}>
          {title}
        </Text>
      </ModalHeader>
      <ModalBody py={0}>
        <Table
          columns={columns}
          data={options}
          thProps={{
            textColor: "color.label",
            px: 2,
            py: 1,
            textTransform: "none",
          }}
          tdProps={{
            px: 2,
            py: 3,
          }}
          onRowClick={({ original: { market, isUnwrapped } }) => {
            onSelect(market, isUnwrapped);
            onClose();
          }}
        />
      </ModalBody>
    </>
  );
};
