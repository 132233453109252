import { ActionButtonProps } from "@/features/lending/components/buttons/index.ts";
import { TxBuilderContext } from "@/providers/TxBuilder.tsx";
import { Button, Icon, IconButton } from "@chakra-ui/react";
import { useContext } from "react";
import { RiSubtractLine } from "react-icons/ri";
import { useAccount } from "wagmi";

const RepayButton = ({
  market,
  userMarket,
  onClick,
  isMobile,
}: ActionButtonProps) => {
  const { isDisconnected } = useAccount();

  const { hasMarketTx } = useContext(TxBuilderContext);

  const hasBalance = userMarket && userMarket.borrowBalance > 0;

  return isMobile ? (
    <Button
      w="full"
      variant="primary"
      leftIcon={<Icon as={RiSubtractLine} />}
      onClick={() => onClick()}
    >
      Repay
    </Button>
  ) : (
    <IconButton
      aria-label="minus"
      variant="actionIconButton"
      onClick={() => onClick()}
      isDisabled={isDisconnected || !hasBalance || hasMarketTx(market)}
    >
      <RiSubtractLine />
    </IconButton>
  );
};

export default RepayButton;
